import { Box } from "@chakra-ui/react"
import { StarEmpty, StarFilled } from "../../../icons/generated";
import { RatingsSizeVariants } from "../rating.types";

const sizeVariants: { [key in RatingsSizeVariants]: number } = {
  sm: 12,
  md: 24,
  lg: 32,
  xl: 40,
};

type StarProps = {
  ratingToShow: number,
  size: RatingsSizeVariants,
  starOrder: number
}

const Star = ({ratingToShow, size, starOrder}: StarProps) => {

  return (
    <Box position="relative" >
      <StarEmpty  size={sizeVariants[size]} />
      <Box
        position="absolute"
        top={0}
        w={
          ratingToShow - starOrder > 0
          ? '100%'
          : `${ratingToShow - starOrder + 1 > 0 ? (ratingToShow - starOrder + 1) * 100 : 0}%`
        }
        overflow="hidden">
        <StarFilled size={sizeVariants[size]} />
      </Box>
    </Box>
)}

export default Star
