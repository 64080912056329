import { InputGroup, Input, Button, InputLeftElement, Flex, IconButton } from '@chakra-ui/react';
import { KeyboardEvent, forwardRef, useState } from 'react';

import { Icon } from '../../icon/icon';
import { IconName } from '../../icon/icon.types';
import { SearchInputProps } from './search-input.types';

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    {
      searchTerm,
      onChange,
      placeholder,
      submitLabel,
      clearLabel,
      clearButtonHandler,
      searchTermLimit,
      searchLength = 2,
      name,
    },
    searchInputRef,
  ) => {
    const showSubmitButton = (searchTerm?.length || 0) >= searchLength;
    const showClearButton = (searchTerm?.length || 0) >= 1;

    return (
      <InputGroup>
        <InputLeftElement pointerEvents="none" display={{ base: 'none', sm: 'inherit' }}>
          <Icon icon={IconName.search} />
        </InputLeftElement>
        <Flex w="100%">
          <Input
            autoComplete="off"
            fontSize={{ base: 'lg', md: 'xl' }}
            border="none"
            type="Search"
            focusBorderColor="none"
            placeholder={placeholder}
            pl={{ base: '0', sm: '3xl' }}
            pr="md"
            flex={1}
            name={name}
            value={searchTerm}
            onChange={onChange}
            maxLength={searchTermLimit}
            ref={searchInputRef}
            sx={{
              '&::-webkit-search-cancel-button': {
                display: 'none',
              },
            }}
          />
          <Flex gap="space.8">
            {showClearButton && (
              <Button
                variant={{ base: 'text', sm: 'secondary' }}
                size="sm"
                textTransform={{ base: 'lowercase', sm: 'unset' }}
                pr={{ base: '0', sm: 'xl' }}
                pl={{ base: '0', sm: 'xl' }}
                display="inline"
                onClick={clearButtonHandler}>
                {clearLabel}
              </Button>
            )}
            {showSubmitButton && (
              <>
                <Button
                  variant="primary"
                  type="submit"
                  size="sm"
                  px={{ base: '0', sm: 'xl' }}
                  display={{ base: 'none', sm: 'inline' }}>
                  {submitLabel}
                </Button>

                <IconButton
                  variant="primary"
                  type="submit"
                  size="sm"
                  aria-label="search"
                  w="48px"
                  display={{ base: 'inline-flex', sm: 'none' }}
                  icon={<Icon icon={IconName.search} transform="scale(0.8)" />}
                />
              </>
            )}
          </Flex>
        </Flex>
      </InputGroup>
    );
  },
);
