import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import type { PartsStyleFunction, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { modalAnatomy as parts } from '@chakra-ui/anatomy';

const baseStyleDialog: SystemStyleFunction = props => {
  return {
    // Note that the colors are flipped in comparison with the default theme
    bg: mode('darkGrey', 'white')(props),
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = props => ({
  overlay: {
    bg: 'blackAlpha.800',
  },
  dialog: baseStyleDialog(props),
  header: {
    fontSize: ['1.25rem', null, '1.5rem'],
    fontWeight: 400,
    pt: '2xl', // Should be equal to the footer bottom padding
    px: '3xl',
  },
  closeButton: {
    top: 5,
    insetEnd: 5,
  },
  body: {
    px: '3xl',
  },
  footer: {
    px: '3xl',
    pt: 'xl',
    pb: '2xl',
  },
});

export const modal: ComponentMultiStyleConfig = {
  parts: ['header', 'overlay', 'dialogContainer', 'dialog', 'closeButton', 'body', 'footer'],
  baseStyle,
  variants: {
    media: {
      dialog: {
        my: 'auto',
        height: '100%',
      },
      closeButton: {
        bg: 'rgba(104, 104, 104, 0.6)',
      },
    },
    review: {
      header: {
        fontSize: ['1.75rem', null, '2rem'],
        fontWeight: 400,
        size: '2xl',
        span: {
          color: 'lightPurple',
        },
      },
      dialog: {
        background: 'darkGrey',
      },
    },
  },
  sizes: {
    '7xl': {
      overlay: {},
      dialog: {
        my: 'auto',
        maxWidth: '1240px',
        maxHeight: '100%',
        bg: 'transparent',
      },
      body: {
        p: 0,
        maxHeight: '100%',
      },
      dialogContainer: {
        height: '100%',
        px: ['sm', null, null, '5xl'],
        py: 'sm',
      },
    },
  },
};
