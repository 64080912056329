import {
  chakra,
  ComponentWithAs,
  forwardRef,
  HTMLChakraProps,
  ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { IconProps } from '../icon/icon.types';
import { Icon } from '../icon/icon';
export interface TextLinkProps extends HTMLChakraProps<'a'>, ThemingProps<'TextLink'> {
  rightIcon?: IconProps;
  leftIcon?: IconProps;
}

export const TextLink: ComponentWithAs<'a', TextLinkProps> = forwardRef<TextLinkProps, 'a'>(
  (props, ref) => {
    const { children, variant, size, rightIcon, leftIcon, ...rest } = props;
    const styles = useStyleConfig('TextLink', { variant, size });

    return (
      <chakra.a display="inline-flex" alignItems="center" __css={styles} {...rest} ref={ref}>
        {leftIcon && <Icon display="inline-block" mr="sm" {...leftIcon} />}
        {children}
        {rightIcon && <Icon display="inline-block" ml="sm" {...rightIcon} />}
      </chakra.a>
    );
  },
);
