import { background, Box, Container, Flex, Heading, Text } from '@chakra-ui/react';

import { TitleSectionProps } from './title-section.types';

export const TitleSection = ({
  title,
  content,
  description,
  actionSlot,
  children,
  headingProps,
  headingSize = 'h1',
  headingVariant = 'glowing',
  ...props
}: TitleSectionProps) => (
  <Box as="section" {...props}>
    <Container mb={{ base: 6, md: 8 }}>
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent={{ md: 'space-between' }}
        alignItems={{ md: 'center' }}
        gap={{ base: 2, md: 0 }}>
        <Flex flexDirection="column" gap={{ base: 2, md: 4 }} grow="1">
          {title && (
            <Heading variant={headingVariant} size={headingSize} {...headingProps}>
              {title}
            </Heading>
          )}
          {content && <>{content}</>}
          {description && <Text color="desiredDawn">{description}</Text>}
        </Flex>
        {actionSlot && <Box>{actionSlot}</Box>}
      </Flex>
    </Container>
    {children}
  </Box>
);
