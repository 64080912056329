import { ComponentStyleConfig } from '@chakra-ui/react';
import { gradients } from '../colors';

export const progress: ComponentStyleConfig = {
  defaultProps: {
    variant: 'primary',
  },
  variants: {
    legacy: {
      track: {
        bg: 'borderLight',
      },
      filledTrack: {
        bg: gradients.lightPurpleFill,
      },
    },
    primary: {
      track: {
        bg: 'rgba(217, 217, 217, 0.1)', // TODO: Use color token.
        height: '6px', // TODO: Use updated spacings tokens.
        borderRadius: '8px',
        overflow: 'visible',
      },
      filledTrack: {
        bg: gradients.lightPurpleFill,
        borderRadius: '8px',
      },
    },
  },
  sizes: {
    sm: {
      track: { h: '0.1875rem' },
    },
  },
};
