import { Box, Link, List, ListItem, Tab, Text, useTabs, useTheme } from '@chakra-ui/react';
import { IndexItemProps, IndexListProps } from './index-list.types';
import NextLink from 'next/link';
import { isExternalUrl } from '../../utils';

export const IndexItem = ({ url, children, isActive = false, ...props }: IndexItemProps) => {
  const afterStyles = {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '1px',
    opacity: 0,
    transition: 'opacity 0.2s ease',
    background: 'lightPurple',
    pointerEvents: 'none',
  };

  const baseStyles = {
    position: 'relative',
    pb: 2,
  };

  const inactiveStyles = {
    ...baseStyles,
    color: 'desiredDawn',
    transition: 'color 0.2s ease',
    whiteSpace: { base: 'nowrap', md: 'normal' },
    _hover: { color: 'white' },
    _after: afterStyles,
  };

  const activeStyles = {
    ...baseStyles,
    color: 'white',
    _after: { ...afterStyles, opacity: '1' },
  };

  const text = (
    <Text as="span" size="sm" aria-current={isActive ? 'location' : undefined}>
      {children}
    </Text>
  );

  if (url) {
    return (
      <ListItem {...props}>
        <NextLink href={url} passHref legacyBehavior>
          <Link
            variant="none"
            isExternal={isExternalUrl(url)}
            sx={isActive ? activeStyles : inactiveStyles}>
            {text}
          </Link>
        </NextLink>
      </ListItem>
    );
  }

  return (
    <ListItem {...props}>
      <Tab px={0} py={0} _selected={activeStyles} sx={inactiveStyles}>
        {text}
      </Tab>
    </ListItem>
  );
};

export const IndexList = ({ children, ...props }: IndexListProps) => (
  <Box
    sx={{
      WebkitOverflowScrolling: 'auto',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',

      '&::-webkit-scrollbar': {
        display: ' none',
        width: ' 0 !important',
        height: ' 0 !important',
      },
    }}
    overflowX={{ base: 'scroll', md: 'visible' }}>
    <List
      display="flex"
      flexDirection={{ md: 'column' }}
      spacing={0}
      gap={{ base: 4, md: 6 }}
      mb={6}
      {...props}>
      {children}
    </List>
  </Box>
);
