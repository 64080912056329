import { Box, Flex, keyframes } from '@chakra-ui/react';

export const PulseLoader = () => {
  const scale = keyframes` 0% { transform: scale(0) } 50% { transform: scale(1) } 100% { transform: scale(0) }`;
  const itemProps = {
    w: 6.6,
    h: 6.6,
    backgroundColor: 'lightPurple',
    borderRadius: '100%',
    transform: 'scale(0)',
  };

  return (
    <Flex gap={0.5}>
      <Box {...itemProps} animation={`1.3s ${scale} infinite linear`} />
      <Box {...itemProps} animation={`1.3s ${scale} 0.3s infinite linear`} />
      <Box {...itemProps} animation={`1.3s ${scale} 0.6s infinite linear`} />
    </Flex>
  );
};
