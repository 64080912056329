export const BACKEND_URLS = {
  LOGOUT: `${process.env.NEXT_PUBLIC_BACKEND_BASE}/auth/logout`,
  LOGIN: `${process.env.NEXT_PUBLIC_BACKEND_BASE}/auth/login`,
  REGISTER: `${process.env.NEXT_PUBLIC_BACKEND_BASE}/auth/register`,
  TWITTER_LOGIN: `${process.env.NEXT_PUBLIC_BACKEND_BASE}/auth/twitter/login`,
  DISCORD_LOGIN: `${process.env.NEXT_PUBLIC_BACKEND_BASE}/auth/discord/login`,
  STEAM_LOGIN: `${process.env.NEXT_PUBLIC_BACKEND_BASE}/auth/steam/login`,
  TWITCH_LOGIN: `${process.env.NEXT_PUBLIC_BACKEND_BASE}/auth/twitch/login`,
  TELEGRAM_LOGIN: `${process.env.NEXT_PUBLIC_BACKEND_BASE}/auth/telegram/login`,
};

export const FRONTEND_URL = process.env.SITE_URL;

export const SUPPORT_URL = 'https://support.onbeam.com/hc/en-us/categories/14560234721053-Beam-Hub';

export const DISCORD_URL = 'https://discord.gg/beamgamers';

export const MERIT_TERMS_AND_CONDITIONS = 'https://meritcircle.io/terms-and-conditions';

export enum PageUrls {
  DASHBOARD = '/',
  SEARCH = '/search',
  GAMES = '/games',
  GAMES_ALL = '/games/all',
  ACADEMY = '/academy',
  ACADEMY_CATEGORY = '/academy/category',
  REWARDS = '/rewards',
  ADVENT = '/advent',
  REWARDS_SERVERS = '/rewards/',
  PROFILE = '/profile',
  ONBOARDING = '/onboarding',
  VERIFY_EMAIL = '/verify-email',
  GAMING_GUIDES = '/gaming-guides',
  PRIVACY_POLICY = '/privacy-policy',
}

export const OG_IMAGE_URL = `${FRONTEND_URL}/images/common/beam-hub-og-placeholder.jpg`;

export const URLS = {
  DASHBOARD: `${FRONTEND_URL}${PageUrls.DASHBOARD}`,
  SEARCH: `${FRONTEND_URL}${PageUrls.SEARCH}`,
  GAMES: `${FRONTEND_URL}${PageUrls.GAMES}`,
  GAMES_ALL: `${FRONTEND_URL}${PageUrls.GAMES_ALL}`,
  ACADEMY: `${FRONTEND_URL}${PageUrls.ACADEMY}`,
  ACADEMY_CATEGORY: `${FRONTEND_URL}${PageUrls.ACADEMY_CATEGORY}`,
  REWARDS: `${FRONTEND_URL}${PageUrls.REWARDS}`,
  ADVENT: `${FRONTEND_URL}/${PageUrls.ADVENT}`,

  PROFILE: `${FRONTEND_URL}${PageUrls.PROFILE}`,
  ONBOARDING: `${FRONTEND_URL}${PageUrls.ONBOARDING}`,
  VERIFY_EMAIL: `${FRONTEND_URL}${PageUrls.VERIFY_EMAIL}`,
  GAMING_GUIDES: `${FRONTEND_URL}${PageUrls.GAMING_GUIDES}`,
  PRIVACY_POLICY: `${FRONTEND_URL}${PageUrls.PRIVACY_POLICY}`,
};

export const PROTECTED_ROUTES: string[] = [
  PageUrls.PROFILE,
  PageUrls.ONBOARDING,
  PageUrls.VERIFY_EMAIL,
  PageUrls.REWARDS_SERVERS,
];
