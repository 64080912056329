import { REDIRECT_PATHS } from '@/config/redirect-paths';
import { Button, Heading, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { Seo } from '../utils/seo/seo-component';
import { IsolatedPageLayout } from './isolated-page-layout';

export function NotFoundPage() {
  const { t } = useTranslation('common');

  return (
    <IsolatedPageLayout>
      <Seo title={t('not-found-page.title')} description={t('not-found-page.text')} noindex />

      <Heading as="h1" size="xl" mt={['xl', null, '4xl']}>
        {t('not-found-page.title')}
      </Heading>
      <Text mt="lg">{t('not-found-page.text')}</Text>

      <Button mt="xl" as="a" href={REDIRECT_PATHS.DASHBOARD}>
        {t('not-found-page.button-text')}
      </Button>
    </IsolatedPageLayout>
  );
}
