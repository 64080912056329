import { Box, FormControl, FormErrorMessage, FormLabel, Image, Input } from '@chakra-ui/react';
import { ChangeEventHandler, useRef, useState } from 'react';
import { ImageInputProps } from './image-input.types';

export const ImageInput = (props: ImageInputProps) => {
  const { label, error, onChange, name, ...boxProps } = props;

  const fileInputField = useRef(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileSelected: ChangeEventHandler<HTMLInputElement> = e => {
    const { files: fileList } = e.target;

    if (!fileList?.length) return;
    const file = fileList.item(0);
    if (!file) return;
    setSelectedFile(file);
    onChange(file);
  };

  return (
    <FormControl
      isInvalid={!!error}
      position="relative"
      overflow="hidden"
      bg="gray.800"
      borderRadius="lg"
      border="2px solid"
      borderColor={!error ? 'transparent' : 'red.500'}
      sx={{
        aspectRatio: '1',
        //  White overlay shown if image is selected
        '::before': {
          display: selectedFile && !error ? 'block' : 'none',
          content: '""',
          position: 'absolute',
          zIndex: 4,
          top: 0,
          left: 0,
          borderRadius: '100%',
          width: '100%',
          height: '100%',
          boxShadow: '0 0 0 200rem rgba(255, 255, 255, 0.4)',
        },
      }}
      {...boxProps}>
      {!error && (
        <FormLabel
          display="block"
          color="gray.500"
          fontSize="sm"
          position="absolute"
          zIndex={2}
          w="100%"
          px="4"
          top="50%"
          transform="translateY(-50%)">
          {label}
        </FormLabel>
      )}

      {selectedFile && (
        <>
          <Image
            position="absolute"
            zIndex={3}
            top={0}
            right={0}
            bottom={0}
            left={0}
            borderRadius="lg"
            boxSize="100%"
            objectFit="cover"
            opacity={error ? 0.3 : 1}
            // Don't show image if error occured
            display={error ? 'none' : 'block'}
            src={URL.createObjectURL(selectedFile)}
            alt=""
          />
        </>
      )}

      {error && (
        <Box position="absolute" top={0} right={0} bottom={0} left={0} zIndex={5} w="100%">
          <FormErrorMessage
            data-testid="error-message"
            fontSize="xs"
            position="absolute"
            top="50%"
            left="50%"
            textAlign="center"
            transform={'translate(-50%, -50%)'}>
            {error}
          </FormErrorMessage>
        </Box>
      )}

      <Input
        data-testid="file-upload"
        type="file"
        name={name || 'file'}
        value=""
        accept=".jpg,.png,.jpeg,.gif"
        multiple={false}
        ref={fileInputField}
        onChange={handleFileSelected}
        display="block"
        position="absolute"
        zIndex={6}
        w="100%"
        h="100%"
        top={0}
        right={0}
        bottom={0}
        left={0}
        opacity={0}
        cursor="pointer"
        _focus={{ outline: 'none' }}
      />
    </FormControl>
  );
};
