import { BACKEND_URLS } from '@/config/urls';
import { Button, Heading, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { Seo } from '../utils/seo/seo-component';
import { IsolatedPageLayout } from './isolated-page-layout';

export function ErrorPage() {
  const { t } = useTranslation('common');

  return (
    <IsolatedPageLayout withGameTeasers>
      <Seo title={t('error-page.title')} description={t('error-page.text')} noindex />

      <Heading as="h1" size="xl" mt={['xl', null, '4xl']}>
        {t('error-page.title')}
      </Heading>

      <Text mt="lg">{t('error-page.text')}</Text>

      <Button mt="xl" as="a" href={BACKEND_URLS.LOGOUT}>
        {t('actions.logout')}
      </Button>
    </IsolatedPageLayout>
  );
}
