import {
  Avatar,
  Badge,
  Box,
  BoxProps,
  Heading,
  ImageProps,
  LinkBox,
  LinkOverlay,
  Text,
  TextProps,
  useTheme,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { getPlaceholderUrl } from '../../utils';
import { LazyImage } from '../lazy-image/lazy-image';
import { CardProps, CardVariants } from './card.types';

export const Card = ({
  variant = 'default',
  href,
  title,
  summary,
  categories,
  platforms,
  tokens,
  logo,
  thumb,
  placeholderUrl,
  label,
}: CardProps) => {
  const { gradients } = useTheme();

  const imagePropVariantMap: { [key in CardVariants]: ImageProps } = {
    ['default']: {
      h: ['12rem', '15rem'],
    },
    ['highlighted']: {
      minH: ['23rem', null],
      maxH: '28rem',
    },
  };

  const boxVariantMap: { [key in CardVariants]: BoxProps } = {
    ['default']: {
      mt: 'lg',
    },
    ['highlighted']: {
      mt: ['lg', null, 'xl'],
    },
  };

  const summaryVariantMap: { [key in CardVariants]: TextProps } = {
    ['default']: {
      mt: 'sm',
    },
    ['highlighted']: {
      mt: ['sm', null, 'lg'],
    },
  };

  const hasLink = !!href;
  const headingProps = hasLink && { as: LinkOverlay, _hover: { color: 'gray.200' } };
  const heading = (
    <Heading size="md" {...headingProps}>
      {title}
    </Heading>
  );

  return (
    <LinkBox
      _hover={
        hasLink
          ? {
              p: {
                color: 'white',
              },
            }
          : undefined
      }>
      <LazyImage
        alt=""
        src={thumb}
        placeholderUrl={placeholderUrl || getPlaceholderUrl(thumb)}
        objectFit="cover"
        borderRadius="lg"
        w="100%"
        {...imagePropVariantMap[variant]}
      />

      <Box display="flex" alignItems="center" {...boxVariantMap[variant]}>
        {logo && (
          <Avatar
            size="md"
            src={logo}
            backgroundColor="white"
            mr="md"
            sx={{
              img: {
                objectFit: 'contain',
              },
            }}
          />
        )}

        {hasLink ? (
          <NextLink href={href} passHref legacyBehavior>
            {heading}
          </NextLink>
        ) : (
          <>{heading}</>
        )}

        {label && (
          <Text
            as="span"
            fontSize="xs"
            fontWeight="bold"
            bgGradient={gradients.orangeFill}
            bgClip="text"
            ml="sm">
            {label}
          </Text>
        )}
      </Box>

      {/* Note that the array values can also be 'null' instead of an empty array, when its values are not set */}
      {(categories || platforms || tokens) && (
        <Wrap {...boxVariantMap[variant]} spacing="sm">
          {(categories ?? []).map(category => (
            <WrapItem key={category}>
              <Badge mb="sm" _notLast={{ mr: 'sm' }}>
                {category}
              </Badge>
            </WrapItem>
          ))}
          {(platforms ?? []).map(platform => (
            <WrapItem key={platform}>
              <Badge mb="sm" _notLast={{ mr: 'sm' }}>
                {platform}
              </Badge>
            </WrapItem>
          ))}
          {(tokens ?? []).map(token => (
            <WrapItem key={token}>
              <Badge mb="sm" _notLast={{ mr: 'sm' }}>
                ${token}
              </Badge>
            </WrapItem>
          ))}
        </Wrap>
      )}

      {summary && (
        <Text variant="muted" {...summaryVariantMap[variant]} transition="color .2s ease">
          {summary}
        </Text>
      )}
    </LinkBox>
  );
};
