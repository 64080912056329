import { PropsWithChildren, useState } from 'react';
import { ReviewsPaginationContext } from './reviews-pagination.context';

const ReviewsPaginationProvider = ({ children }: PropsWithChildren) => {
  const [page, setPage] = useState(0);
  const [sortOption, setSortOption] = useState('voteDiff-DESC');

  return (
    <ReviewsPaginationContext.Provider value={{ page, sortOption, setPage, setSortOption }}>
      {children}
    </ReviewsPaginationContext.Provider>
  );
};

export default ReviewsPaginationProvider;
