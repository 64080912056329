import { ComponentStyleConfig } from '@chakra-ui/react';

export const radio: ComponentStyleConfig = {
  baseStyle: {
    control: {
      _checked: {
        bg: 'lightPurple',
        borderColor: 'lightPurple',
      },
    },
    label: {
      color: 'muted',
      transition: 'color .2s ease',
      _checked: {
        color: 'white',
      },
    },
  },
};
