import { Box, Flex, Link, ListItem, Text, chakra } from '@chakra-ui/react';
import NextLink from 'next/link';
import { rem } from 'polished';
import CheckboxInputIcon from '../../icons/generated/CheckboxInput';
import CheckboxInputCheckedIcon from '../../icons/generated/CheckboxInputChecked';
import { gradients } from '../../theme';
import { isExternalUrl } from '../../utils';
import { Icon } from '../icon/icon';
import { IconName } from '../icon/icon.types';
import { PulseLoader } from '../loaders/pulse-loader/pulse-loader';
import { ChecklistItemProps } from './checklist.types';

export const ChecklistItem = ({
  label,
  href,
  onClick,
  isExternal,
  isChecked = false,
  isLocked = false,
  isLoading = false,
}: ChecklistItemProps) => {
  const contentSlot = (
    <Flex justify="space-between" align="center" padding={7} gap={6}>
      <Flex gap={6}>
        {!isLocked && isLoading && (
          <Flex direction="column" justify="center">
            <PulseLoader />
          </Flex>
        )}
        {!isLocked && !isLoading && <Checkbox isChecked={isChecked} />}
        {isLocked && <Icon icon={IconName.lock} position="relative" top={1} flexShrink={0} />}
        <Text opacity={isLocked || isChecked ? 0.6 : 1}>{label}</Text>
      </Flex>
      {(href || onClick) && !isLocked && !isChecked && !isLoading && (
        <Flex
          w={10}
          h={10}
          border="1px solid rgba(242, 178, 76, 0.1)"
          borderRadius="full"
          transition="border-color 0.2s ease"
          alignItems="center"
          justifyContent="center"
          _groupHover={{ borderColor: 'lightPurple' }}>
          <Icon icon={IconName.arrowRightFill} />
        </Flex>
      )}
    </Flex>
  );

  return (
    <ListItem
      position="relative"
      onClick={onClick}
      _before={{
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: isLocked || isChecked ? 'rgba(0, 0, 0, 0.08)' : 'rgba(255, 255, 255, 0.3)',
        mixBlendMode: 'soft-light',
        borderRadius: 4,
      }}
      data-group>
      {!!href && !isLocked && !isChecked && !isLoading ? (
        <NextLink href={href} passHref legacyBehavior>
          <Link
            href={href}
            isExternal={isExternal ?? isExternalUrl(href)}
            position="relative"
            display="block"
            textDecoration="none">
            {contentSlot}
          </Link>
        </NextLink>
      ) : (
        <>{contentSlot}</>
      )}
    </ListItem>
  );
};

type CheckboxProps = {
  isChecked?: boolean;
  value?: string;
  label?: string;
  onChange?: (value: string) => void;
};

export const Checkbox = ({ isChecked, label, value, onChange }: CheckboxProps) => (
  <Flex gap="space.24">
    <chakra.label
      cursor="pointer"
      display="flex"
      gap="space.8"
      alignItems="center"
      w="100%"
      lineHeight={1}
      fontSize={rem(12)}
      color="desiredDawn"
      transition="0.2s ease-in"
      _hover={{
        color: 'white',
      }}
      data-test-id={`${label}-checkbox-button`}>
      <chakra.input
        type="checkbox"
        clipPath="inset(50%)"
        height="1px"
        width="1px"
        overflow="hidden"
        whiteSpace="nowrap"
        position="absolute"
        sx={{
          clip: 'rect(0 0 0 0)',
        }}
        name="test"
        value={value}
        onChange={e => onChange && onChange(e.currentTarget.value)}
      />
      {isChecked ? <CheckboxInputCheckedIcon /> : <CheckboxInputIcon />}
      <chakra.span mt="1px">{label}</chakra.span>
    </chakra.label>
  </Flex>
);
