import { forwardRef } from '@chakra-ui/react';
import {
  ArrowRight,
  ArrowDown,
  ArrowRightFill,
  Close,
  Discord,
  ExclamationMark,
  Menu,
  MeritCircleIcon,
  MeritCircleLogo,
  Play,
  Search,
  AppStore,
  GooglePlay,
  ToastIcon,
  CheckMark,
  ChevronRight,
  Lock,
  Youtube,
  Telegram,
  Twitter,
  Web,
  Link,
  ChevronLeft,
  StarEmpty,
  StarFilled,
  Gradients,
  BeamLogo,
  BeamLogoPlain,
  AppIconSquare,
  BeamLogoPlainSmall,
} from '../../icons/generated';
import { IconProps, IconName } from './icon.types';

export const Icon = forwardRef<IconProps, 'svg'>(({ icon, ...props }, ref) => {
  const icons = {
    [IconName.arrowRight]: ArrowRight,
    [IconName.arrowRightFill]: ArrowRightFill,
    [IconName.arrowDown]: ArrowDown,
    [IconName.close]: Close,
    [IconName.discord]: Discord,
    [IconName.exclamationMark]: ExclamationMark,
    [IconName.menu]: Menu,
    [IconName.meritCircleIcon]: MeritCircleIcon,
    [IconName.meritCircleLogo]: MeritCircleLogo,
    [IconName.play]: Play,
    [IconName.search]: Search,
    [IconName.appStore]: AppStore,
    [IconName.googlePlay]: GooglePlay,
    [IconName.toastIcon]: ToastIcon,
    [IconName.checkMark]: CheckMark,
    [IconName.chevronRight]: ChevronRight,
    [IconName.chevronLeft]: ChevronLeft,
    [IconName.lock]: Lock,
    [IconName.telegram]: Telegram,
    [IconName.twitter]: Twitter,
    [IconName.web]: Web,
    [IconName.youtube]: Youtube,
    [IconName.link]: Link,
    [IconName.starEmpty]: StarEmpty,
    [IconName.starFilled]: StarFilled,
    [IconName.gradients]: Gradients,
    [IconName.beamLogo]: BeamLogo,
    [IconName.beamLogoPlain]: BeamLogoPlain,
    [IconName.beamLogoPlainSmall]: BeamLogoPlainSmall,
    [IconName.beamAppIcon]: AppIconSquare,
  };

  const IconComponent = icons[icon];
  return <IconComponent {...props} ref={ref} />;
});
