import { Box, Flex, Text, TextProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { LoaderProps } from './loader.types';
import { Loading as LoadingIcon } from '../../../icons/generated';

export const Loader = ({
  text = 'Loading',
  orientation = 'row',
  shouldAnimate = true,
  textProps,
}: LoaderProps) => (
  <Box display="inline-block">
    <Flex gap={3} flexDirection={orientation} alignItems="center" justifyContent="center">
      <motion.div
        animate={shouldAnimate && { rotate: 360 }}
        transition={{ duration: 1.25, loop: Infinity }}>
        <LoadingIcon />
      </motion.div>
      <Text fontSize="xl" lineHeight={1} letterSpacing="tight" {...textProps}>
        {text}
      </Text>
    </Flex>
  </Box>
);
