import { ComponentStyleConfig } from '@chakra-ui/react';

export const tabs: ComponentStyleConfig = {
  baseStyle: {
    tabpanel: {
      padding: 0,
    },
  },
  defaultProps: {
    variant: 'unstyled',
  },
};
