import { VStack, HStack, Box, keyframes } from '@chakra-ui/react';
import { LazyImage } from '@meritcircle/ui/components';

const maxMoveDistance = '10%';
const moveDuration = '41s';

const getMoveAnimation = (bias: 'left' | 'right') => `0% {
  transform: translateX(0);
}
25% {
  transform: translateX(${bias === 'left' ? `${maxMoveDistance}` : `-${maxMoveDistance}`});
}
50% {
  transform: translateX(0);
}
75% {
  transform: translateX(${bias === 'left' ? `-${maxMoveDistance}` : `${maxMoveDistance}`});
}
100% {
  transform: translateX(0);
}`;

const moveLeft = keyframes`${getMoveAnimation('left')}`;
const moveRight = keyframes`${getMoveAnimation('right')}`;

const gamesRow1 = [
  {
    url: '/images/games/teasers/1.jpeg',
    placeholderUrl: '/images/games/teasers/1-placeholder.png',
    alt: 'Game screenshot',
  },
  {
    url: '/images/games/teasers/2.jpeg',
    placeholderUrl: '/images/games/teasers/2-placeholder.png',
    alt: 'Game screenshot',
  },
  {
    url: '/images/games/teasers/3.jpeg',
    placeholderUrl: '/images/games/teasers/3-placeholder.png',
    alt: 'Game screenshot',
  },
  {
    url: '/images/games/teasers/4.jpeg',
    placeholderUrl: '/images/games/teasers/4-placeholder.png',
    alt: 'Game screenshot',
  },
  {
    url: '/images/games/teasers/5.jpeg',
    placeholderUrl: '/images/games/teasers/5-placeholder.png',
    alt: 'Game screenshot',
  },
];

const gamesRow2 = [
  {
    url: '/images/games/teasers/6.jpeg',
    placeholderUrl: '/images/games/teasers/6-placeholder.png',
    alt: 'Game screenshot',
  },
  {
    url: '/images/games/teasers/7.jpeg',
    placeholderUrl: '/images/games/teasers/7-placeholder.png',
    alt: 'Game screenshot',
  },
  {
    url: '/images/games/teasers/8.jpeg',
    placeholderUrl: '/images/games/teasers/8-placeholder.png',
    alt: 'Game screenshot',
  },
  {
    url: '/images/games/teasers/9.jpeg',
    placeholderUrl: '/images/games/teasers/9-placeholder.png',
    alt: 'Game screenshot',
  },
];

export const GameTeasers = () => (
  <VStack
    spacing="10px"
    whiteSpace="nowrap"
    transform="rotate(-45deg)"
    marginRight="-75vw"
    sx={{ img: { borderRadius: 8 } }}
  >
    <HStack spacing="10px" animation={`${moveLeft} infinite ${moveDuration} linear`}>
      {gamesRow1.map(game => (
        <Box key={game.url} minWidth="500px" minHeight="255px" position="relative">
          <LazyImage
            src={game.url}
            placeholderUrl={game.placeholderUrl}
            alt={game.alt}
            width={500}
            height={255}
          />
        </Box>
      ))}
    </HStack>
    <HStack spacing="10px" animation={`${moveRight} infinite ${moveDuration} linear`}>
      {gamesRow2.map(game => (
        <Box key={game.url} minWidth="500px" minHeight="255px" position="relative">
          <LazyImage
            src={game.url}
            placeholderUrl={game.placeholderUrl}
            alt={game.alt}
            width={500}
            height={255}
          />
        </Box>
      ))}
    </HStack>
  </VStack>
);
