import { PropsWithChildren, useCallback, useReducer } from 'react';
import { defaultToastsContext, ToastsContext } from './toasts.context';
import { ActionTypes, ToastsContextState, AddToastAction, RemoveToastAction } from './toasts.types';

const reducer = (
  state: ToastsContextState,
  { type, payload }: AddToastAction | RemoveToastAction,
) => {
  let toasts = [...state.toasts];

  if (type === ActionTypes.ADD_TOAST) {
    toasts = [...toasts, { value: payload, id: `${new Date().getTime()}-${toasts.length}` }];
  }

  if (type === ActionTypes.REMOVE_TOAST) toasts = toasts.filter(toast => toast.id !== payload);

  return {
    ...state,
    toasts,
  };
};

const ToastsProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, defaultToastsContext);

  const addToast = useCallback((payload: AddToastAction['payload']) => {
    dispatch({
      type: ActionTypes.ADD_TOAST,
      payload,
    });
  }, []);

  const removeToast = useCallback((payload: RemoveToastAction['payload']) => {
    dispatch({
      type: ActionTypes.REMOVE_TOAST,
      payload,
    });
  }, []);

  return (
    <ToastsContext.Provider value={{ ...state, addToast, removeToast }}>
      {children}
    </ToastsContext.Provider>
  );
};

export default ToastsProvider;
