import { Box, useMediaQuery, useTheme } from '@chakra-ui/react';
import { IconName } from '../icon/icon.types';
import { Icon } from '../icon/icon';
import { LogoProps } from './logo.types';
import BeamLogoRainbowIcon from '../../icons/generated/BeamLogoRainbow';
import BeamLogoRainbowSmallIcon from '../../icons/generated/BeamLogoRainbowSmall';

export const Logo = ({ variant = 'primary', showRainbow = true, ...props }: LogoProps) => {
  const { breakpoints } = useTheme();
  const [isSmOrLarger] = useMediaQuery(`(min-width: ${breakpoints.sm})`);

  if (variant === 'primary') {
    return (
      <Box position="relative">
        <Box
          position="absolute"
          top="50%"
          right="100%"
          sx={{
            transform: 'translate(0, -50%)',
          }}>
          {isSmOrLarger ? (
            <BeamLogoRainbowIcon opacity={showRainbow ? 1 : 0} transition="opacity .5s ease" />
          ) : (
            <BeamLogoRainbowSmallIcon />
          )}
        </Box>
        {isSmOrLarger ? (
          <Icon icon={IconName.beamLogoPlain} {...props} />
        ) : (
          <Icon icon={IconName.beamLogoPlainSmall} {...props} />
        )}
      </Box>
    );
  }

  return <Icon icon={IconName.beamLogo} {...props} />;
};
