import { Box, Flex, Link } from '@chakra-ui/react';
import NextLink from 'next/link';
import { isExternalUrl } from '../../utils';
import { GlassBox } from '../glass-box/glass-box';
import { Icon } from '../icon/icon';
import { IconName } from '../icon/icon.types';
import { LinkBoxProps } from './link-box.types';
import { gradients } from '../../theme';

export const LinkBox = ({
  children,
  leading,
  href,
  contentProps,
  verticalAlignArrow = 'flex-start',
  ...props
}: LinkBoxProps) => (
  <NextLink href={href} passHref legacyBehavior>
    <Link variant="none" isExternal={isExternalUrl(href)}>
      <GlassBox
        py={0}
        px={0}
        _hover={{ background: 'rgba(208, 208, 208, 0.08)' }}
        transition="background 0.2s ease"
        data-group
        {...props}>
        {leading && leading}
        <Flex
          flex="1"
          py={8}
          px={6}
          justifyContent="space-between"
          gap={6}
          data-testid="gaming-guide-tile"
          {...contentProps}>
          <Box maxWidth="25rem">{children}</Box>
          <Box alignSelf={verticalAlignArrow}>
            <Flex
              w={10}
              h={10}
              borderColor="rgba(232, 187, 251, 0.10)"
              borderWidth={1}
              borderRadius="full"
              transition="border-color 0.2s ease"
              bg={`linear-gradient(0deg, rgba(34, 34, 34, 0.75) 0%, rgba(34, 34, 34, 1)), ${gradients.darkRadial}`}
              backgroundBlendMode="normal"
              boxShadow="0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset"
              filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
              alignItems="center"
              justifyContent="center"
              _hover={{
                borderColor: 'rgba(232, 187, 251, 0.70)',
              }}
              _groupHover={{ borderColor: 'rgba(232, 187, 251, 0.70)' }}>
              <Icon icon={IconName.arrowRightFill} color="lightPurple" />
            </Flex>
          </Box>
        </Flex>
      </GlassBox>
    </Link>
  </NextLink>
);

// TODO figure out the gradient, doesnt seem to be working
// and hover, accent color border, etc.
