import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserTaskQueryVariables = Types.Exact<{
  rules?: Types.InputMaybe<Types.TaskRulesInput>;
}>;


export type UserTaskQuery = { __typename: 'Query', getTask: { __typename: 'Task', points: number } };

export type TasksMutationVariables = Types.Exact<{
  filters: Types.GetTaskQueryFilters;
}>;


export type TasksMutation = { __typename: 'Mutation', performTask: Array<{ __typename: 'Task', slug: string, points: number, rules: { __typename: 'TaskRules', timePeriod?: Types.TaskRuleTimePeriod | null, action?: Types.TaskRuleAction | null } }> };

export type TasksFormMutationVariables = Types.Exact<{
  payload: Types.TaskInputFormDto;
}>;


export type TasksFormMutation = { __typename: 'Mutation', performFormTask: { __typename: 'Task', slug: string, points: number, rules: { __typename: 'TaskRules', timePeriod?: Types.TaskRuleTimePeriod | null, action?: Types.TaskRuleAction | null } } };


export const UserTaskDocument = gql`
    query UserTask($rules: TaskRulesInput) {
  getTask(filters: {rules: $rules}) {
    points
  }
}
    `;

export function useUserTaskQuery(options?: Omit<Urql.UseQueryArgs<UserTaskQueryVariables>, 'query'>) {
  return Urql.useQuery<UserTaskQuery>({ query: UserTaskDocument, ...options });
};
export const TasksDocument = gql`
    mutation Tasks($filters: GetTaskQueryFilters!) {
  performTask(filters: $filters) {
    slug
    points
    rules {
      timePeriod
      action
    }
  }
}
    `;

export function useTasksMutation() {
  return Urql.useMutation<TasksMutation, TasksMutationVariables>(TasksDocument);
};
export const TasksFormDocument = gql`
    mutation TasksForm($payload: TaskInputFormDTO!) {
  performFormTask(payload: $payload) {
    slug
    points
    rules {
      timePeriod
      action
    }
  }
}
    `;

export function useTasksFormMutation() {
  return Urql.useMutation<TasksFormMutation, TasksFormMutationVariables>(TasksFormDocument);
};