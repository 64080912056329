import { Box, Collapse, useId } from '@chakra-ui/react';
import { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import { TextLink } from '../text-link/text-link';
import { ReadMoreProps } from './read-more.types';

/**
 * Gets text (as string) from React Children
 **/
const getNodeText = (node: ReactElement): string | undefined => {
  if (['string', 'number'].includes(typeof node)) return `${node}`;
  if (node instanceof Array) return node.map(getNodeText).join('');
  if (typeof node === 'object' && node) return getNodeText(node.props.children);
};

// Currently the component assumes a fixed line height of 24px to
// calculate the height.
const lineHeight = 1.5; // 1.5rem / 24px.

/**
 * Wraps the children in a 'Read more' box with appropriate button when the total number of lines
 * exceeds the provided `maxLines` prop.
 * @note Resizing the screen after initial page render will not recalculate the component.
 * @param children
 * @returns
 */
export const ReadMore = ({
  maxLines = 0,
  readMoreText = 'Read more',
  readLessText = 'Read less',
  children,
}: PropsWithChildren<ReadMoreProps>) => {
  const collapsibleId = useId('read-more-collapsible');
  const [collapsed, setCollapsed] = useState(true);
  const [shouldCollapse, setShouldCollapse] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const hasMoreContent = shouldCollapse && collapsed;
  const visibleHeight = hasMoreContent ? `${maxLines * lineHeight}rem` : undefined;

  // Get's text length by rendering all text with applied font size
  // into a context and extracting the width via context.measureText.
  // This is then divided by the wrapper element width to get
  // and approximation of the number of lines.
  useEffect(() => {
    const wrapperWidth = ref?.current?.getBoundingClientRect?.()?.width;

    if (!wrapperWidth) {
      return;
    }

    const text = getNodeText(children as ReactElement);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx || !text) return;
    const styles = getComputedStyle(ref.current);
    ctx.font = styles.font;
    const textWidth = ctx.measureText(text).width;
    const rowsOfText = Math.ceil(textWidth / wrapperWidth);

    setShouldCollapse(rowsOfText > maxLines);
  }, [children, maxLines]);

  return (
    <>
      <Box
        position="relative"
        ref={ref}
        _after={{
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '3rem',
          transition: 'opacity .2s ease',
          opacity: hasMoreContent && maxLines > 0 ? 1 : 0,
        }}>
        <Collapse
          startingHeight={visibleHeight}
          in={!hasMoreContent}
          id={collapsibleId}
          aria-hidden={collapsed}>
          {children}
        </Collapse>
      </Box>

      {shouldCollapse && (
        <TextLink
          as="button"
          mt="md"
          size="xs"
          background="transparent"
          color="lightPurple"
          onClick={() => setCollapsed(!collapsed)}
          data-disclosure
          aria-controls={collapsibleId}
          aria-expanded={!collapsed}>
          {collapsed ? readMoreText : readLessText}
        </TextLink>
      )}
    </>
  );
};
