export enum FeatureFlags {
  AUTH = 'AUTH',
}

export const getFeatureFlag = (key: FeatureFlags): string => {
  const featureFlags = {
    [FeatureFlags.AUTH]: 'OPTIONAL',
  };

  return featureFlags[key];
};

export const mapFeatureFlags = (
  key: FeatureFlags,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lookup: { [key: string]: any },
  fallback?: string,
) => {
  const flag = getFeatureFlag(key);

  // trigger fallback if no feature flag provided, also pick first feature if no fallback provided
  const action = lookup?.[flag] || lookup?.[fallback] || lookup?.[0];

  if (typeof action === 'function') {
    return action();
  } else {
    return action;
  }
};
