import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      ref={svgRef}
      {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5 18.106l5.054-5.65L5 6.806l1.739-1.35L12 11.338l5.261-5.882L19 6.806l-5.054 5.65L19 18.106l-1.739 1.35L12 13.574l-5.261 5.882L5 18.106z"
        clipRule="evenodd"
      />
    </svg>
  );
});
const CloseIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default CloseIcon;
