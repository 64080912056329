import { URLS } from '@/config/urls';
import { BadgeProps } from '@chakra-ui/react';
import { differenceInDays } from 'date-fns';
import { convertToTimeZone } from './date';
import sortBy from 'lodash/sortBy';
import { GamesNavigationQuery } from '@cms/graphql/gamesOverviewPage.generated';
import { GameFiltersQuery } from '@cms/graphql/componentGameFilters.generated';

export type FilterData = {
  [key in string]: {
    title: string;
    options: {
      value: string;
      label: string;
    }[];
  };
};

export type GameFiltersType = {
  searchTerm: string;
  categories: string[];
  platforms: string[];
  status: string[];
  freeToPlay: boolean | undefined;
  blockchains: string[];
  meritCircleGame: boolean | undefined;
};

export enum FilterCategories {
  CATEGORIES = 'categories',
  PLATFORMS = 'platforms',
  STATUS = 'status',
  FREE_TO_PLAY = 'freeToPlay',
  BLOCKCHAINS = 'blockchains',
  MERIT_CIRCLE_GAME = 'meritCircleGame',
}

export enum GamesPaths {
  OVERVIEW = '',
  ALL = 'all',
  EARLY_ACCESS = 'early-access',
  LIVE_GAMES = 'live-games',
  UPCOMING = 'upcoming',
  FREE_GAMES = 'free',
}

export enum GamePages {
  OVERVIEW = 'Overview',
  ALL = 'All',
  EARLY_ACCESS = 'Early-access',
  LIVE_GAMES = 'Live Games',
  UPCOMING = 'Upcoming',
  FREE_GAMES = 'Free Games',
}

export type GamesNavigation = {
  page: string;
  path: string;
  label: string;
}[];

export const gamePageData = {
  [GamePages.OVERVIEW]: {
    path: GamesPaths.OVERVIEW,
    filters: {},
  },
  [GamePages.ALL]: {
    path: GamesPaths.ALL,
    filters: {},
  },
  [GamePages.EARLY_ACCESS]: {
    path: GamesPaths.EARLY_ACCESS,
    filters: {
      status: ['Early-Access'],
    },
  },
  [GamePages.LIVE_GAMES]: {
    path: GamesPaths.LIVE_GAMES,
    filters: {
      status: ['Live'],
    },
  },
  [GamePages.UPCOMING]: {
    path: GamesPaths.UPCOMING,
    filters: {
      status: ['Upcoming'],
    },
  },
  [GamePages.FREE_GAMES]: {
    path: GamesPaths.FREE_GAMES,
    filters: {
      freeToPlay: true,
    },
  },
};

/**
 * Returns a full url to an games page
 * @param slug
 * @returns
 */
export const getGamesUrl = (slug: string) => {
  return `${URLS.GAMES_ALL}/${slug}`;
};

export const getBadgeVariant = (name: string): BadgeProps['variant'] => {
  switch (name.toLowerCase()) {
    case 'upcoming':
      return 'accentBlue';
    case 'new':
      return 'arcticBlue';
    case 'popular':
      return 'lightPurple';
    default:
      return 'default';
  }
};

export const isGameNew = (publishDate: Date, timeZone = 'Europe/Amsterdam'): boolean => {
  const startDate = convertToTimeZone(new Date(), timeZone);
  const endDate = convertToTimeZone(publishDate, timeZone);
  const daysOld = 30;

  return differenceInDays(startDate, endDate) < daysOld;
};

export const getGamePageFromSlug = (slug: string) => {
  const pageData = Object.entries(gamePageData).find(([_, value]) => value.path === slug);

  return pageData && pageData[0];
};

export const getDefaultFilters = (page: keyof typeof gamePageData) => {
  return gamePageData[page].filters;
};

export const getNavigation = (
  pageData: GamesNavigationQuery['pageGamesOverviewCollection']['items'],
) => {
  const sortedPageData = sortBy(pageData, ['pageNavigationOrder']);

  const filteredPageData = sortedPageData
    .filter(pageData => !(pageData.pageType in GamePages))
    .map(pageData => ({
      page: pageData.pageType as GamePages,
      label: pageData.pageNavigationLabel,
    }));

  return filteredPageData.reduce(
    (acc, cur) => [
      ...acc,
      {
        page: cur.page,
        path: gamePageData[cur.page]?.path,
        label: cur?.label,
      },
    ],
    [] as GamesNavigation,
  );
};

export const getGameFilters = (
  filtersData: GameFiltersQuery['componentGameFiltersCollection']['items'],
) => {
  return filtersData.reduce((acc, filter) => {
    const options = filter.filterValues.map(filterValue => {
      const [label, value] = filterValue.split('::');
      return {
        label: label.trim(),
        value: value?.trim() ?? label.trim(),
      };
    });
    return {
      ...acc,
      [filter.filterType]: {
        title: filter.title,
        options,
      },
    };
  }, {} as FilterData);
};

export const getQuery = (filters: GameFiltersType, page = 1) => {
  let query: Partial<GameFiltersType & { page: number }> = {};
  const { searchTerm, platforms, categories, status, freeToPlay, blockchains, meritCircleGame } =
    filters;
  query = searchTerm ? { ...query, searchTerm } : query;
  query = categories && categories.length > 0 ? { ...query, categories } : query;
  query = platforms && platforms.length > 0 ? { ...query, platforms } : query;
  query = status && status.length > 0 ? { ...query, status } : query;
  query = typeof freeToPlay === 'boolean' ? { ...query, freeToPlay } : query;
  query = blockchains && blockchains.length > 0 ? { ...query, blockchains } : query;
  query = typeof meritCircleGame === 'boolean' ? { ...query, meritCircleGame } : query;
  query = page && page !== 1 ? { ...query, page } : query;

  return query;
};
