import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={6}
      fill="none"
      viewBox="0 0 28 6"
      ref={svgRef}
      {...props}>
      <path
        fill="url(#paint0_linear_17241_3347)"
        d="M14.482 1.727h14.986v.847H15.495l-1.013-.847z"
      />
      <path fill="url(#paint1_linear_17241_3347)" d="M15.685 4.268h13.783v.847H15.685v-.847z" />
      <path fill="url(#paint2_linear_17241_3347)" d="M15.736 2.574H29.47v.847H15.736v-.847z" />
      <path fill="url(#paint3_linear_17241_3347)" d="M15.736 2.574H29.47v.847H15.736v-.847z" />
      <path fill="url(#paint4_linear_17241_3347)" d="M15.525 3.421H29.47v.847H15.525v-.847z" />
      <path
        fill="url(#paint5_linear_17241_3347)"
        d="M.344 5.117h.707V4.72c.191.219.464.502 1.084.502 1.177 0 1.6-1.103 1.6-1.759 0-.867-.638-1.734-1.618-1.734-.359 0-.736.135-1.002.407V.75H.344v4.367zm.724-1.682c0-.478.365-.985.957-.985.487 0 .939.383.939 1.02 0 .638-.423 1.033-.928 1.033-.452 0-.968-.342-.968-1.068z"
      />
      <path
        fill="url(#paint6_linear_17241_3347)"
        d="M7.156 3.83c.023-.106.046-.242.046-.395 0-.826-.609-1.705-1.664-1.705-1.02 0-1.692.832-1.692 1.758 0 .956.719 1.735 1.698 1.735.76 0 1.368-.549 1.554-1.139h-.812c-.145.272-.4.42-.742.42-.55 0-.852-.461-.887-.674h2.499zM4.634 3.2c.145-.608.597-.75.91-.75.412 0 .783.225.87.75h-1.78z"
      />
      <path
        fill="url(#paint7_linear_17241_3347)"
        d="M10.695 1.842h-.707v.413h-.012c-.023-.047-.411-.525-1.066-.525-.893 0-1.612.725-1.612 1.729 0 1.091.737 1.764 1.566 1.764.405 0 .88-.218 1.124-.513v.407h.707V1.842zm-.73 1.628c0 .608-.406 1.033-.94 1.033-.55 0-.956-.454-.956-1.009 0-.49.325-1.044.957-1.044.458 0 .939.342.939 1.02z"
      />
      <path
        fill="url(#paint8_linear_17241_3347)"
        d="M11.049 5.116h.77V3.481c0-.348 0-1.032.615-1.032.476 0 .644.401.644.85v1.817h.77V3.481c0-.206-.005-.53.116-.725a.625.625 0 01.534-.307c.608 0 .608.66.608.85v1.817h.771V3.204c0-.484-.116-.797-.243-.968-.116-.165-.43-.507-1.06-.507-.227 0-.702.023-1.038.513a1.078 1.078 0 00-.928-.513c-.556 0-.777.283-.84.39h-.012V1.84h-.707v3.275z"
      />
      <defs>
        <linearGradient
          id="paint0_linear_17241_3347"
          x1={22.03}
          x2={22.03}
          y1={2.17}
          y2={3.12}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BBDBFF" />
          <stop offset={0.33} stopColor="#139EDD" />
          <stop offset={0.6} stopColor="#B9F7EA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17241_3347"
          x1={21.98}
          x2={21.98}
          y1={4.71}
          y2={5.66}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BBFFCA" />
          <stop offset={0.33} stopColor="#48DD13" />
          <stop offset={0.6} stopColor="#008805" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_17241_3347"
          x1={22}
          x2={22}
          y1={2.68}
          y2={3.46}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF6A9A" />
          <stop offset={0.53} stopColor="#F54" />
          <stop offset={0.96} stopColor="#E63E33" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_17241_3347"
          x1={21.86}
          x2={21.86}
          y1={3.02}
          y2={3.96}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF6B6B" />
          <stop offset={0.33} stopColor="#FE1414" />
          <stop offset={0.6} stopColor="#8E0900" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_17241_3347"
          x1={21.99}
          x2={21.99}
          y1={3.87}
          y2={4.81}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F1E869" />
          <stop offset={0.33} stopColor="#FEA514" />
          <stop offset={0.6} stopColor="#FF4539" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_17241_3347"
          x1={5.52}
          x2={5.52}
          y1={0.75}
          y2={5.22}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FAFBFD" />
          <stop offset={1} stopColor="#EDEDED" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_17241_3347"
          x1={5.52}
          x2={5.52}
          y1={0.75}
          y2={5.22}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FAFBFD" />
          <stop offset={1} stopColor="#EDEDED" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_17241_3347"
          x1={5.52}
          x2={5.52}
          y1={0.75}
          y2={5.22}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FAFBFD" />
          <stop offset={1} stopColor="#EDEDED" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_17241_3347"
          x1={13.46}
          x2={13.46}
          y1={1.73}
          y2={5.12}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FAFBFD" />
          <stop offset={1} stopColor="#EDEDED" />
        </linearGradient>
      </defs>
    </svg>
  );
});
const AppIconSquareIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default AppIconSquareIcon;
