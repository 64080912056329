import { ChakraTheme } from '@chakra-ui/react';
import { rem } from 'polished';

// This creates an  "n"=nPx  spacing token system (where "space.16" means 16px):
//
//   "space.4"  = 4px
//   "space.8"  = 8px
//   "space.16" = 16px
//     (etc.)
//
// Several advantages over  "lg"=nPx  and "x"=yPx  approaches:
//
// • Easy to standardize non-standard values.
//     When the design-team starts using a non-standard value (this happened
//     with ←28px→ ), then we don’t have to invent a value between "xl" and
//     "2xl" (1.5xl?). Neither do we have to shift all "2xl" to "3xl" everywhere
//     in our code to make space. Also we don’t have to argue with the design-
//     team and impose our OCD onto them.
//
// • Easier to read/use
//     When implementing a design with 16px spacing, you don’t have to look up
//     whether that means "md" or "lg". Neither do you have to reverse-calculate
//     to deduce that 24px corresponds to "spacing-6".
//
// You are encouraged to use the "n"=nPx spacing values as much as possible, as
// they will ensure consistent spacing throughout the application

// ADD PIXEL-VALUES HERE:
const spacingValues = [
  2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 24, 28, 30, 32, 34, 36, 40, 48, 56, 64, 80, 88, 96, 104, 112,
  120, 128, 132,
];

const spacingMap: ChakraTheme['space'] = spacingValues.reduce(
  (map, px) => ({ ...map, [`space.${px}`]: rem(px), [`space.-${px}`]: rem(-px) }),
  {},
);

/**
 * Next to the default numeric spacing provided by Chakra, we run a custom 'named' spacing table, consisting
 * of proportional steps of 5px. You are encouraged to use these spacing values as much as possible, as
 * they will ensure consistent spacing throughout the application
 */
export const space: ChakraTheme['space'] = {
  ...spacingMap,

  // Here for backward-compatability:
  xs: '0.3125rem', // 5px
  sm: '0.625rem', // 10px
  md: '0.9375rem', // 15px
  lg: '1.25rem', // 20px
  xl: '1.875rem', // 30px
  '2xl': '2.5rem', // 40px
  '3xl': '3.125rem', // 50px
  '4xl': '3.75rem', // 60px
  '5xl': '5rem', // 80px
  '6xl': '6.25rem', // 100px
  '7xl': '7.5rem', // 120px
};

export const sizes: ChakraTheme['sizes'] = {
  container: {
    xs: '20rem',
    sm: '40rem',
    md: '49.625rem',
    lg: '64rem',
    xl: '80rem',
    '2xl': '90rem',
    '3xl': '120rem',
  },
};
