import { ComponentStyleConfig } from '@chakra-ui/react';

export const container: ComponentStyleConfig = {
  baseStyle: {
    width: '100%',
    maxWidth: [
      'calc(var(--chakra-sizes-container-3xl) + var(--chakra-space-lg) * 2)',
      null,
      null,
      'calc(var(--chakra-sizes-container-3xl) + var(--chakra-space-5xl) * 2)',
    ],
    px: ['lg', null, null, '5xl'],
  },
};
