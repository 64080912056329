import { Box, List, ListItem, ListProps, Text } from '@chakra-ui/react';
import { TimelineItem } from './timeline-item';
import { TimelineProps, TimelineGroupProps } from './timeline.types';
import { format } from 'date-fns';
import { InView } from 'react-intersection-observer';
import { effects, colors } from '../../theme';
import { Loader } from '../loaders/loader/loader';

const isToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
};

const isYesterday = (date: Date): boolean => {
  const today = new Date();
  const yesterday = new Date(new Date().setDate(today.getDate() - 1));

  return (
    date.getDate() == yesterday.getDate() &&
    date.getMonth() == yesterday.getMonth() &&
    date.getFullYear() == yesterday.getFullYear()
  );
};

const getLocaleDate = (date: string, todayLabel?: string, yesterdayLabel?: string): string => {
  const dateTime = new Date(date);
  const parsedDate = format(dateTime, 'dd MMMM');
  if (isToday(dateTime)) return todayLabel || parsedDate;
  if (isYesterday(dateTime)) return yesterdayLabel || parsedDate;
  return parsedDate;
};

const TimelineGroup = ({
  date,
  items,
  todayLabel,
  yesterdayLabel,
  index,
  isActive,
  onlyHighlightActive,
}: TimelineGroupProps) => (
  <>
    <Text
      as="time"
      color="white"
      mb="md"
      left="xl"
      display="inline-block"
      position="relative"
      zIndex={1}
      dateTime={date}>
      {getLocaleDate(date, todayLabel, yesterdayLabel)}
      {(!onlyHighlightActive && index === 0) || isActive ? (
        <Box
          position="absolute"
          w="17px"
          h="17px"
          bgColor="lightPurple"
          boxShadow={effects.textGlow}
          marginLeft="10.5"
          left="-xl"
          top="50%"
          transform="translate(-50%, -50%)"
          borderRadius="100%"
          display="block"
        />
      ) : (
        <Box
          position="absolute"
          w="12px"
          h="12px"
          bgColor="white"
          border="2px solid black"
          marginLeft="0.3"
          left="-5"
          top="50%"
          transform="translate(-50%, -50%)"
          borderRadius="100%"
          display="block"
        />
      )}
    </Text>
    <List spacing="lg">
      {items.map(({ onInView, ...item }, index) => {
        const timelineItem = <TimelineItem {...item} />;
        if (onInView) {
          return (
            <ListItem key={`timeline-item-key-${index}`}>
              <InView triggerOnce onChange={inView => inView && onInView()} threshold={0.5}>
                {timelineItem}
              </InView>
            </ListItem>
          );
        }
        return <ListItem key={`timeline-item-key-${index}`}>{timelineItem}</ListItem>;
      })}
    </List>
  </>
);

export const Timeline = ({
  groups,
  todayLabel,
  yesterdayLabel,
  ...listProps
}: TimelineProps & ListProps) => (
  <>
    {!groups.length ? (
      <Loader orientation="column" />
    ) : (
      <List spacing="xl" flex="1" {...listProps}>
        {groups.map(({ date, items, isActive, onlyHighlightActive }, index) => (
          <ListItem
            key={`timeline-group-key-${index}`}
            id={`timeline-group-day-${new Date(date).getDate()}`}
            position="relative"
            {...(onlyHighlightActive && !isActive && { opacity: 0.4 })}>
            <TimelineGroup
              date={date}
              items={items}
              todayLabel={todayLabel}
              yesterdayLabel={yesterdayLabel}
              index={index}
              isActive={isActive}
              onlyHighlightActive={onlyHighlightActive}
            />
            {index !== groups.length - 1 && (
              <Box
                position="absolute"
                borderLeft="1px dashed"
                borderColor="white"
                opacity={0.6}
                h="calc(100% + var(--chakra-space-xl))"
                left="sm"
                bottom="-36px"
                display="block"
              />
            )}
          </ListItem>
        ))}
      </List>
    )}
  </>
);
