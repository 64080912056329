import { ChakraTheme } from '@chakra-ui/react';

export const fonts: ChakraTheme['fonts'] = {
  heading: `"Suisse Int", "Helvetica Neue", Arial, sans-serif`,
  body: `"Suisse Int", "Helvetica Neue", Arial, sans-serif`,
  mono: `"Courier New", Courier, monospace`,
};

export const fontSizes: ChakraTheme['fontSizes'] = {
  xs: '0.75rem', // 12px
  sm: '0.875rem', // 14px
  md: '1rem', // 16px
  lg: '1.25rem', // 20px
  xl: '1.5rem', // 24px
  '2xl': '2rem', // 32px
  '3xl': '2.5rem', // 40px
  '4xl': '3rem', // 48px
  '5xl': '3.75rem', // 60px
  '6xl': '5rem', // 80px
};

export const fontWeights: ChakraTheme['fontWeights'] = {
  normal: 450,
  bold: 700,
  black: 900,
};
