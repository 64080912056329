import { textStyles } from './text-styles/index';
import { extendTheme } from '@chakra-ui/react';
import { colors, gradients } from './colors';
import { components } from './components';
import { globalStyle } from './global-style';
import { shadows } from './shadows';
import { sizes, space } from './sizes';
import { fonts, fontSizes, fontWeights } from './typography';

export const theme = extendTheme({
  components,
  colors,
  fonts,
  gradients,
  fontSizes,
  fontWeights,
  sizes,
  space,
  shadows,
  textStyles,
  styles: { global: globalStyle },
});

export type CustomTheme = typeof theme;
