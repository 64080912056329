import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      ref={svgRef}
      {...props}>
      <rect width={18} height={18} x={3} y={3} fill="url(#paint0_linear_16046_98636)" rx={9} />
      <rect
        width={23}
        height={23}
        x={0.5}
        y={0.5}
        stroke="url(#paint1_linear_16046_98636)"
        rx={11.5}
      />
      <defs>
        <linearGradient
          id="paint0_linear_16046_98636"
          x1={8.18}
          x2={18.94}
          y1={3.49}
          y2={5.87}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9BFFB" />
          <stop offset={1} stopColor="#E8BBFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16046_98636"
          x1={6.9}
          x2={21.25}
          y1={0.66}
          y2={3.83}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9BFFB" />
          <stop offset={1} stopColor="#E8BBFB" />
        </linearGradient>
      </defs>
    </svg>
  );
});
const RadioInputCheckedIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default RadioInputCheckedIcon;
