import { Box, Grid } from '@chakra-ui/react';
import { colors, gradients } from '../../theme';
import { VerticalProgressBarProps } from './vertical-progress-bar.types';

export const VerticalProgressBar = ({
  percentage,
  isActive = false,
  ...props
}: VerticalProgressBarProps) => (
  <Grid position="relative" w={4} h="100%" {...props}>
    <Box
      w="1px"
      h="100%"
      gridArea="1/1"
      justifySelf="center"
      background={`repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 0px, rgba(255, 255, 255, 0.6) 2px, ${colors.transparent} 2px, ${colors.transparent} 4px)`}
    />
    <Box
      gridArea="1/1"
      justifySelf="center"
      w="7px"
      h={`calc(${percentage}% + 7px)`}
      background={gradients.lightPurpleFill}
      borderRadius="7px"
    />
    {isActive || percentage > 0 ? (
      <Box gridArea="1/1" w={4} h={4} borderRadius="100%" background={gradients.lightPurpleFill} />
    ) : (
      <Box gridArea="1/1" justifySelf="center" w={2} h={2} borderRadius="100%" background="white" />
    )}
  </Grid>
);
