export const LastUpdated = ({ lastUpdate }: { lastUpdate: string }) => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: `
        {
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://google.com/article"
          },
          "dateModified": "${lastUpdate}"
        }`,
    }}
  />
);
