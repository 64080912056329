import { URLS } from '@/config/urls';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Container, Flex, IconButton } from '@chakra-ui/react';
import { SearchInput } from '@meritcircle/ui/components';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { ChangeEvent, FormEvent, forwardRef } from 'react';
import { MIN_CHAR_TO_START_SEARCH } from './search-bar.types';

const SEARCH_TERM_LIMIT = 20;

type SearchBarInputProps = {
  searchTerm: string;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  resetSearch: () => void;
  isBarOpen: boolean;
  onBarClose: () => void;
};

export const SearchBarInput = forwardRef<HTMLInputElement, SearchBarInputProps>(
  ({ searchTerm, handleInputChange, resetSearch, isBarOpen, onBarClose }, ref) => {
    const { t } = useTranslation('common');
    const router = useRouter();

    const handleSearchSubmit = (event: FormEvent) => {
      event.preventDefault();
      if (searchTerm && searchTerm.length >= MIN_CHAR_TO_START_SEARCH) {
        router.push(
          {
            pathname: URLS.SEARCH,
            query: { search: searchTerm },
          },
          undefined,
          { shallow: true },
        );
      }
    };

    return (
      <Container
        display="flex"
        h="100%"
        _before={{
          content: '""',
          position: 'absolute',
          top: '0',
          left: '50%',
          transform: 'translateX(-50%)',
          height: '100%',
          background: 'black',
        }}
        background="black"
        flexDirection={{ base: 'column', sm: 'row' }}
        alignItems={{ base: 'stretch', sm: 'center' }}
        position="relative">
        <Box
          flex={1}
          as="form"
          action={URLS.SEARCH}
          method="GET"
          pr="md"
          onSubmit={handleSearchSubmit}
          py={{ base: 'space.16', sm: 'unset' }}>
          <SearchInput
            placeholder={t('actions.search')}
            submitLabel={t('actions.search')}
            clearLabel={t('actions.clear')}
            name="search"
            clearButtonHandler={resetSearch}
            onChange={handleInputChange}
            searchTerm={searchTerm}
            searchTermLimit={SEARCH_TERM_LIMIT}
            ref={ref}
          />
        </Box>
        <Flex
          zIndex={1}
          order={{ base: '-1', sm: '1' }}
          py={{ base: 'space.16', md: 'unset' }}
          justifyContent="flex-end"
          borderBottom={{
            base: isBarOpen ? `1px solid var(--chakra-colors-border)` : 'none',
            sm: 'none',
          }}>
          <IconButton
            color="white"
            aria-label={`${t('actions.close')} ${t('actions.search')}`}
            onClick={onBarClose}
            variant="link"
            icon={<CloseIcon />}
          />
        </Flex>
      </Container>
    );
  },
);
