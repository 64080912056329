interface Toast {
  id: string;
  value: string;
}

export interface ToastsContextState {
  toasts: Toast[];
  addToast: (payload: AddToastAction['payload']) => void;
  removeToast: (payload: RemoveToastAction['payload']) => void;
}

export enum ActionTypes {
  ADD_TOAST = 'ADD_TOAST',
  REMOVE_TOAST = 'REMOVE_TOAST',
}

export interface AddToastAction {
  type: ActionTypes.ADD_TOAST;
  payload: Toast['value'];
}

export interface RemoveToastAction {
  type: ActionTypes.REMOVE_TOAST;
  payload: Toast['id'];
}
