import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Icon,
  useBreakpointValue,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { BreadcrumbsProps } from './breadcrumbs.types';
import { ConditionalWrapper } from '../../utils/conditional-wrapper';
import { IconName } from '../icon/icon.types';
import { ChevronRight } from '../../icons/generated';

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs = [],
  ...breadcrumbProps
}) => {
  return !!breadcrumbs.length ? (
    <Breadcrumb spacing={'.8rem'} separator={<ChevronRight />} {...breadcrumbProps}>
      {breadcrumbs.map(({ title, href }, i) => (
        <BreadcrumbItem
          key={i}
          isCurrentPage={i === breadcrumbs.length - 1}
          sx={{ '[aria-current]': { color: 'normal' } }}>
          <ConditionalWrapper
            condition={i === breadcrumbs.length - 1}
            trueWrapper={children => (
              <Box
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                maxW={{ base: '12ch', sm: '50vw', md: 'unset' }}>
                <NextLink href={href} passHref legacyBehavior>
                  {children}
                </NextLink>
              </Box>
            )}>
            <BreadcrumbLink
              href={href}
              color={i === breadcrumbs.length - 1 ? 'desiredDawn' : 'white'}
              whiteSpace="nowrap"
              pointerEvents={i === breadcrumbs.length - 1 ? 'none' : undefined}
              isCurrentPage={i === breadcrumbs.length - 1}>
              {title}
            </BreadcrumbLink>
          </ConditionalWrapper>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  ) : null;
};
