import { ComponentStyleConfig } from '@chakra-ui/react';
import { effects } from '../colors';

export const heading: ComponentStyleConfig = {
  baseStyle: {
    color: 'white',
    fontWeight: 'normal',
    letterSpacing: '-0.01em',
    lineHeight: 1.1,
    fontFamily: `"Suisse Int", "Helvetica Neue", Arial, sans-serif`, // TODO: provide better backups
  },
  sizes: {
    // [70px, 120px]
    display: {
      fontFamily: '"ITC Garamond Std Book Condensed", "Helvetica Neue", Arial, sans-serif', // TODO: provide better backups
      fontSize: ['4.375rem', '5rem', '5rem', '6rem', '7.5rem'],
      color: 'lightPurple',
      lineHeight: 0.87,
      letterSpacing: ['-2.6px', '-0.02em', '-0.02em', '-0.02em'],
    },
    // legacy name for display
    '2xl': {
      fontFamily: '"ITC Garamond Std Book Condensed", "Helvetica Neue", Arial, sans-serif',
      fontSize: ['4.375rem', '5rem', '5rem', '6rem', '7.5rem'],
      color: 'lightPurple',
      lineHeight: 0.95,

      letterSpacing: ['-2.6px', '-0.02em', '-0.02em', '-0.02em', '-0.02em'],
    },
    // [56px, 65px]
    h1: {
      fontFamily: '"ITC Garamond Std Book Condensed", "Helvetica Neue", Arial, sans-serif', // TODO: provide better backups
      fontSize: ['3.5rem', null, '4.0625rem'],
      lineHeight: 0.95,
    },
    // legacy name for h1
    xl: {
      fontFamily: '"ITC Garamond Std Book Condensed", "Helvetica Neue", Arial, sans-serif', // TODO: provide better backups
      fontSize: ['3.5rem', null, '4.0625rem'],
      lineHeight: 0.95,
    },
    // [34px, 40px]
    h2: {
      fontSize: ['2.125rem', null, '2.5rem'],
    },
    // legacy name for h2
    lg: {
      fontSize: ['2.125rem', null, '2.5rem'],
    },
    // [28px, 32px]
    h3: {
      fontSize: ['1.75rem', null, '2rem'],
    },
    // legacy name for h3
    md: {
      fontSize: ['1.75rem', null, '2rem'],
      lineHeight: 1.2,
    },
    // [20px, 24px]
    h4: {
      fontSize: ['1.25rem', null, '1.5rem'],
      lineHeight: 1.2,
    },
    // [16px, 18px]
    h5: {
      fontSize: ['1rem', null, '1.125rem'],
      lineHeight: 1.3,
    },
    sm: {
      fontSize: ['1rem', null, '1.125rem'],
      lineHeight: 1.3,
    },
    // [16px, 18px]
    h6: {
      fontSize: '1rem',
      lineHeight: 1.3,
    },
    // legacy name for h6
    xs: {
      fontSize: '1rem',
      lineHeight: 1.3,
    },
  },
  variants: {
    glowing: {
      textShadow: effects.textGlow,
      color: 'lightPurple',
    },
  },
};
