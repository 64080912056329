import { ThemeComponents } from '@chakra-ui/react';
import { badge } from './badge';
import { avatar } from './avatar';
import { accordion } from './accordion';
import { button } from './button';
import { container } from './container';
import { heading } from './heading';
import { form } from './form';
import { select } from './select';
import { modal } from './modal';
import { menuTheme } from './menu';
import { progress } from './progress';
import { link } from './link';
import { text } from './text';
import { drawer } from './drawer';
import { checkbox } from './checkbox';
import { radio } from './radio';
import { table } from './table';
import { textLink } from './text-link';
import { tabs } from './tabs';

export const components: ThemeComponents = {
  Accordion: accordion,
  Avatar: avatar,
  Badge: badge,
  Button: button,
  Container: container,
  Drawer: drawer,
  Form: form,
  Checkbox: checkbox,
  Radio: radio,
  Select: select,
  Heading: heading,
  Link: link,
  Modal: modal,
  Menu: menuTheme,
  Progress: progress,
  Text: text,
  Table: table,
  TextLink: textLink,
  Tabs: tabs,
};
