/**
 * This page template is created to 'lock' people in place,
 * it provides no options to navigate away from this page (that's for example
 * also why the logo doesn't link to anything).
 *
 * Used for example on the 'verify email' page.
 */
import { BACKEND_URLS } from '@/config/urls';
import { Box, chakra, Container, Flex, Link } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { PropsWithChildren } from 'react';
import { GameTeasers } from '../features/layout/game-teasers';
import NextLink from 'next/link';
import { Logo } from '@meritcircle/ui/components';
import { Toasts } from '../features/layout/toasts';
import { useCurrentUser } from '@/utils/hooks/useCurrentUser';

type Props = {
  withGameTeasers?: boolean;
};

export const IsolatedPageLayout: React.FC<PropsWithChildren<Props>> = ({
  children,
  withGameTeasers = false,
}) => {
  const { t } = useTranslation('common');
  const currentUser = useCurrentUser();

  return (
    <Flex flexDir="column" bgColor="gray.900" minHeight="100vh" overflow="hidden">
      <Flex flexDir="column" flexGrow="1" justifyContent="center" width="100%" minHeight="100%">
        <chakra.header
          py="6"
          borderBottom="1px solid"
          borderBottomColor={['transparent', 'border']}>
          <Container
            maxWidth="2xl"
            py="0"
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <NextLink href="/" passHref legacyBehavior>
              <Link>
                <Logo variant="secondary" />
              </Link>
            </NextLink>
            {currentUser.isLoggedIn && (
              <Link color="muted" href={BACKEND_URLS.LOGOUT}>
                {t('actions.logout')}
              </Link>
            )}
          </Container>
        </chakra.header>
        <Toasts />
        <Flex
          as="main"
          flexDir="column"
          flexGrow="1"
          height={['100%', 'auto']}
          position="relative"
          zIndex={2}>
          <Container maxWidth="2xl" pb={['xl', null, '4xl']}>
            {children}
          </Container>
        </Flex>
      </Flex>
      {withGameTeasers && (
        <Box
          position="fixed"
          left="0"
          width="100%"
          bottom="0"
          display={['none', 'block']}
          zIndex={1}>
          <GameTeasers />
        </Box>
      )}
    </Flex>
  );
};
