import { Box, BoxProps, Flex, Text, Tooltip } from '@chakra-ui/react';
import { rem } from 'polished';
import { useState } from 'react';
import { RatingProps, RatingsSizeVariants } from './rating.types';
import range from 'lodash/range';
import Star from './components/star';

const sizeVariants: { [key in RatingsSizeVariants]: number } = {
  sm: 12,
  md: 16,
  lg: 24,
  xl: 40,
};

export const Rating = ({
  rating,
  handleStarClick,
  tooltipLabels = [],
  interactive = false,
  size = 'sm',
  showOnlyStars = false,
  maxStarsNumber = 5,
  ...rest
}: RatingProps & BoxProps) => {
  const RenderStars = () => {
    const [starRating, setStarRating] = useState<number>(0);

    const ratingToShow = starRating || rating;

    return (
      <Flex minH={rem(14)} alignItems="center">
        {range(1, maxStarsNumber + 1).map(starNum => (
          <Box
          key={starNum}
            pr={size === 'sm' ? 1 : 2}
            _last={{ pr: '0' }}
            cursor={interactive ? 'pointer' : 'auto'}
            onMouseEnter={interactive ? () => setStarRating(starNum) : undefined}
            onMouseLeave={interactive ? () => setStarRating(0) : undefined}
            onClick={interactive && handleStarClick ? () => handleStarClick(starNum) : undefined}>
            {tooltipLabels[starNum - 1] ?
            <Tooltip label={tooltipLabels[starNum - 1]}>
              <Box>
                <Star ratingToShow={ratingToShow} size={size} starOrder={starNum} />
              </Box>
            </Tooltip>
            : <Star ratingToShow={ratingToShow} size={size} starOrder={starNum} />}
          </Box>
        ))}
      </Flex>
    );
  };

  return (
    <Flex alignItems="center" {...rest} gap="1">
      {!showOnlyStars && (
        <Text size="sm" lineHeight={1.1} mt='3px'>
          {rating.toFixed(1)}
        </Text>
      )}
      <RenderStars />
    </Flex>
  );
};
