import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      fill="none"
      viewBox="0 0 48 48"
      ref={svgRef}
      {...props}>
      <path
        fill="url(#paint0_linear_10070_94053)"
        fillRule="evenodd"
        d="M33.6 38.11c7.873 0 14.271-6.27 14.398-14.055L48 23.82c0 13.155-10.745 23.82-24 23.82S0 36.973 0 23.818h19.2c0 7.893 6.447 14.292 14.4 14.292z"
        clipRule="evenodd"
      />
      <path
        fill="url(#paint1_linear_10070_94053)"
        fillRule="evenodd"
        d="M14.4 38.11C6.527 38.11.13 31.84.002 24.056L0 23.82c0 13.155 10.745 23.82 24 23.82s24-10.665 24-23.82H28.8c0 7.893-6.447 14.292-14.4 14.292z"
        clipRule="evenodd"
      />
      <path
        fill="url(#paint2_linear_10070_94053)"
        fillRule="evenodd"
        d="M14.4 9.528C6.527 9.528.13 15.798.002 23.583L0 23.819C0 10.664 10.745 0 24 0s24 10.664 24 23.82H28.8c0-7.894-6.447-14.292-14.4-14.292z"
        clipRule="evenodd"
      />
      <path
        fill="url(#paint3_linear_10070_94053)"
        fillRule="evenodd"
        d="M33.6 9.528c7.873 0 14.271 6.27 14.398 14.055l.002.236C48 10.664 37.255 0 24 0S0 10.664 0 23.82h19.2c0-7.894 6.447-14.292 14.4-14.292z"
        clipRule="evenodd"
      />
      <path fill="#EF9146" d="M19.013 23.51H.312v.619h18.7v-.619z" />
      <path fill="#E57622" d="M47.688 23.51H28.986v.619h18.702v-.619z" />
    </svg>
  );
});
const ToastIconIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default ToastIconIcon;
