import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={8}
      height={14}
      viewBox="0 0 8 14"
      ref={svgRef}
      {...props}>
      <path fill="currentColor" d="M2.946 7L8 1.35 6.261 0 0 7l6.261 7L8 12.65 2.946 7z" />
    </svg>
  );
});
const ChevronLeftIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default ChevronLeftIcon;
