import { useState, useRef, useEffect } from 'react';

/**
 * Observes scroll position of multiple elements queried by given selectors, and returns the id of the
 * element which is currently in view.
 * If one of the elements queried by the selectors is not found in the document, this hook will error
 *
 * @example
 * const headings = ['foo', 'bar', 'baz']
 * const activeId = useScrollSpy(
 * headings.map(({ id }) => `[id="${id}"]`),
 *  {
 *    rootMargin: '0% 0% -20% 0%',
 *  },
 * );
 * @param selectors is an array of querySelector string
 * @param options IntersectionObserverInit
 * @returns
 */
export function useScrollSpy(selectors: string[], options?: IntersectionObserverInit) {
  const [activeId, setActiveId] = useState<string>();
  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    const elements = selectors.map(selector => document.querySelector(selector));

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.getAttribute('id'));
        }
      });
    }, options);

    elements.filter(Boolean).forEach(el => observer.current.observe(el));

    return () => observer.current.disconnect();
  }, [options, selectors]);

  return activeId;
}
