import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      ref={svgRef}
      {...props}>
      <path
        fill="currentColor"
        d="M17.9 21H6.8V8.2L13.75 1l.975.775c.1.083.175.2.225.35.05.15.075.333.075.55v.25L13.9 8.2h7.475c.4 0 .75.15 1.05.45.3.3.45.65.45 1.05v2.05c0 .117.012.238.037.363a.6.6 0 01-.037.362l-3.15 7.25c-.15.35-.396.65-.738.9A1.81 1.81 0 0117.9 21zm-9.6-1.5h9.925l3.15-7.475V9.7H12.05l1.325-6.225L8.3 8.825V19.5zm0-10.675V19.5 8.825zM6.8 8.2v1.5H3.475v9.8H6.8V21H1.975V8.2H6.8z"
      />
    </svg>
  );
});
const ThumbsUpIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default ThumbsUpIcon;
