import { BoxProps, Flex } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

export const CarouselIndicator = forwardRef(
  (props: BoxProps, ref: React.ForwardedRef<HTMLDivElement>) => (
    <Flex
      ref={ref}
      display="inline-flex"
      py="xs"
      width="auto !important"
      sx={{
        [`.indicator__bullet`]: {
          display: 'flex',
          borderRadius: '2.5px',
          bg: 'white',
          opacity: 0.5,
          height: '5px',
          width: '5px',
          transition: 'width 300ms ease-in-out, opacity 300ms ease-in-out',
          cursor: 'pointer',
          ':not(:last-of-type)': {
            marginRight: 'xs',
          },
        },
        [`.indicator__bullet--active`]: {
          width: '12px',
          opacity: 1,
          ':only-child': {
            display: 'none',
          },
        },
      }}
      {...props}
    />
  ),
);
