import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetReviewsQueryVariables = Types.Exact<{
  getReviewsInput: Types.GetReviewsByGameInput;
}>;


export type GetReviewsQuery = { __typename: 'Query', getReviews: { __typename: 'ReviewsOverview', count: number, reviews?: Array<{ __typename: 'Review', id: string, text: string, rating: number, upVotes?: number | null, downVotes?: number | null, userVote?: Types.VoteType | null, createdAt: string, user: { __typename: 'User', id: string, username?: string | null, level: number, profilePictureUrl?: string | null }, replies?: Array<{ __typename: 'ReviewReply', id: string, text: string, createdAt: string, user: { __typename: 'User', id: string, username?: string | null, level: number, profilePictureUrl?: string | null } }> | null }> | null } };

export type GetReviewsSummaryQueryVariables = Types.Exact<{
  gameSlug: Types.Scalars['String'];
}>;


export type GetReviewsSummaryQuery = { __typename: 'Query', getReviewsSummary: { __typename: 'ReviewsByGameSummary', count: number, averageRating: number, userReviewed: boolean, summary?: Array<{ __typename: 'ReviewSummary', rating?: number | null, count?: number | null }> | null } };

export type CreateReviewMutationVariables = Types.Exact<{
  createReviewInput: Types.CreateReviewInput;
}>;


export type CreateReviewMutation = { __typename: 'Mutation', createReview: { __typename: 'Review', id: string, text: string, rating: number, upVotes?: number | null, downVotes?: number | null, userVote?: Types.VoteType | null, createdAt: string, user: { __typename: 'User', id: string, username?: string | null, level: number, profilePictureUrl?: string | null }, replies?: Array<{ __typename: 'ReviewReply', id: string }> | null } };

export type DeleteReviewMutationVariables = Types.Exact<{
  reviewId: Types.Scalars['String'];
  gameSlug: Types.Scalars['String'];
}>;


export type DeleteReviewMutation = { __typename: 'Mutation', deleteReview: { __typename: 'Review', id: string, text: string, rating: number, upVotes?: number | null, downVotes?: number | null, userVote?: Types.VoteType | null, createdAt: string, user: { __typename: 'User', username?: string | null, level: number, profilePictureUrl?: string | null } } };

export type UpVoteReviewMutationVariables = Types.Exact<{
  voteOnReviewInput: Types.VoteOnReviewInput;
}>;


export type UpVoteReviewMutation = { __typename: 'Mutation', voteOnReview: { __typename: 'Review', id: string, text: string, rating: number, upVotes?: number | null, downVotes?: number | null, userVote?: Types.VoteType | null, createdAt: string, user: { __typename: 'User', id: string, username?: string | null, level: number, profilePictureUrl?: string | null }, replies?: Array<{ __typename: 'ReviewReply', id: string }> | null } };


export const GetReviewsDocument = gql`
    query GetReviews($getReviewsInput: GetReviewsByGameInput!) {
  getReviews(getReviewsInput: $getReviewsInput) {
    __typename
    count
    reviews {
      __typename
      id
      text
      rating
      user {
        __typename
        id
        username
        level
        profilePictureUrl
      }
      replies {
        __typename
        id
        text
        createdAt
        user {
          id
          username
          level
          profilePictureUrl
        }
      }
      upVotes
      downVotes
      userVote
      createdAt
    }
  }
}
    `;

export function useGetReviewsQuery(options: Omit<Urql.UseQueryArgs<GetReviewsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetReviewsQuery>({ query: GetReviewsDocument, ...options });
};
export const GetReviewsSummaryDocument = gql`
    query GetReviewsSummary($gameSlug: String!) {
  getReviewsSummary(gameSlug: $gameSlug) {
    __typename
    count
    averageRating
    summary {
      rating
      count
    }
    userReviewed
  }
}
    `;

export function useGetReviewsSummaryQuery(options: Omit<Urql.UseQueryArgs<GetReviewsSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<GetReviewsSummaryQuery>({ query: GetReviewsSummaryDocument, ...options });
};
export const CreateReviewDocument = gql`
    mutation CreateReview($createReviewInput: CreateReviewInput!) {
  createReview(createReviewInput: $createReviewInput) {
    id
    text
    rating
    user {
      id
      username
      level
      profilePictureUrl
    }
    replies {
      id
    }
    upVotes
    downVotes
    userVote
    createdAt
  }
}
    `;

export function useCreateReviewMutation() {
  return Urql.useMutation<CreateReviewMutation, CreateReviewMutationVariables>(CreateReviewDocument);
};
export const DeleteReviewDocument = gql`
    mutation DeleteReview($reviewId: String!, $gameSlug: String!) {
  deleteReview(reviewId: $reviewId, gameSlug: $gameSlug) {
    id
    text
    rating
    user {
      username
      level
      profilePictureUrl
    }
    upVotes
    downVotes
    userVote
    createdAt
  }
}
    `;

export function useDeleteReviewMutation() {
  return Urql.useMutation<DeleteReviewMutation, DeleteReviewMutationVariables>(DeleteReviewDocument);
};
export const UpVoteReviewDocument = gql`
    mutation UpVoteReview($voteOnReviewInput: VoteOnReviewInput!) {
  voteOnReview(voteOnReviewInput: $voteOnReviewInput) {
    __typename
    id
    text
    rating
    user {
      __typename
      id
      username
      level
      profilePictureUrl
    }
    replies {
      id
    }
    upVotes
    downVotes
    userVote
    createdAt
  }
}
    `;

export function useUpVoteReviewMutation() {
  return Urql.useMutation<UpVoteReviewMutation, UpVoteReviewMutationVariables>(UpVoteReviewDocument);
};