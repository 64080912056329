import { URLS } from '@/config/urls';
import { IndexHeading } from '@/types/academy';
import { PageAcademyArticleContentItem, ComponentTitle } from '@cms/graphql/types.generated';
import { deletedEntries } from './contentful';
import { slugify } from './slugify';

/**
 * Extracts headings from article elements and adds an id used for anchoring
 * @param elements
 * @returns
 */
export const getIndexHeadings = (elements: PageAcademyArticleContentItem[]): IndexHeading[] =>
  elements
    // Make sure any deleted contentblock is not included in the index.
    // Otherwise it would be null, and elemement.__typename would break.
    .filter(deletedEntries)
    .filter<Extract<ComponentTitle, PageAcademyArticleContentItem>>(
      (element): element is ComponentTitle => element?.__typename === 'ComponentTitle',
    )
    .map(element => {
      const content = element.content.json.content[0].content[0];

      return {
        name: content.value,
        id: slugify(content.value),
      };
    });

/**
 * Returns a full url to an article
 * @param slug
 * @returns
 */
export const getArticleUrl = (slug: string) => `${URLS.ACADEMY}/${slug}`;

/**
 * Returns a full url to a category
 */
export const getCategoryUrl = (slug: string) => `${URLS.ACADEMY_CATEGORY}/${slug}`;
