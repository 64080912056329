import { Box, Button, Flex, IconButton, useBreakpointValue } from '@chakra-ui/react';
import range from 'lodash/range';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { ConditionalWrapper } from '../../utils/conditional-wrapper';
import { Icon } from '../icon/icon';
import { IconName } from '../icon/icon.types';
import { PaginationProps } from './pagination.types';

export const Pagination = ({
  total,
  current,
  handlePrevPage,
  handleNextPage,
  handlePageClick,
  ariaLabelNext,
  ariaLabelPrev,
}: PaginationProps) => {
  const { basePath, query } = useRouter();
  const buttonCount = useBreakpointValue({ base: 3, sm: 5 }) || 5;

  const isBeginning = current === 1;
  const isEnd = current === total;

  const displayedPages = useMemo(() => {
    const array = [...range(total)].map(value => value + 1);

    if (current < buttonCount) {
      return array.slice(0, buttonCount);
    } else {
      if (current + Math.floor(buttonCount / 2) >= total) {
        return array.slice(total - buttonCount, total);
      } else {
        return array.slice(
          current - Math.ceil(buttonCount / 2),
          current + Math.floor(buttonCount / 2),
        );
      }
    }
  }, [current, total, buttonCount]);

  if (total === 1) {
    return null;
  }

  return (
    <Flex justify="center" w="100%">
      <ConditionalWrapper
        condition={!isBeginning}
        trueWrapper={children =>
          handlePrevPage ? (
            <Box cursor="pointer" onClick={handlePrevPage}>
              {children}
            </Box>
          ) : (
            <Link
              href={{
                pathname: basePath,
                query: {
                  ...query,
                  page: current - 1,
                },
              }}
              passHref>
              {children}
            </Link>
          )
        }>
        <IconButton
          size="rounded"
          as={isBeginning ? 'button' : 'a'}
          disabled={isBeginning}
          bg="transparent"
          _disabled={{
            bg: 'transparent',
            opacity: 0.2,
            cursor: 'not-allowed',
          }}
          aria-label={ariaLabelPrev || 'Previous'}
          icon={<Icon ml="-10px" mr="-8px" icon={IconName.chevronLeft} h="1em" />}
          borderRadius="full"
          mr="xs"
          data-testid="previous-page-button"
        />
      </ConditionalWrapper>

      {displayedPages.map(pageNumber => (
        <ConditionalWrapper
          key={pageNumber}
          condition={current !== pageNumber}
          trueWrapper={children =>
            handlePageClick ? (
              <Box cursor="pointer" onClick={() => handlePageClick(pageNumber)}>
                {children}
              </Box>
            ) : (
              <Link
                href={{
                  pathname: basePath,
                  query: {
                    ...query,
                    page: pageNumber,
                  },
                }}
                passHref>
                {children}
              </Link>
            )
          }>
          <Button
            as={current === pageNumber ? 'span' : 'a'}
            variant={current === pageNumber ? 'glass' : 'secondary'} // TODO: glass button
            color={current === pageNumber ? undefined : 'gray.400'}
            size="rounded"
            mr="xs"
            _notLast={{ mr: !isEnd ? 'xs' : undefined }}>
            <Box as="span" marginX="-10px" whiteSpace="nowrap">
              {pageNumber}
            </Box>
          </Button>
        </ConditionalWrapper>
      ))}

      <ConditionalWrapper
        condition={!isEnd}
        trueWrapper={children =>
          handleNextPage ? (
            <Box cursor="pointer" onClick={handleNextPage}>
              {children}
            </Box>
          ) : (
            <Link
              href={{
                pathname: basePath,
                query: {
                  ...query,
                  page: current + 1,
                },
              }}
              passHref>
              {children}
            </Link>
          )
        }>
        <IconButton
          size="rounded"
          as={isEnd ? 'button' : 'a'}
          disabled={isEnd}
          bg="transparent"
          _disabled={{
            bg: 'transparent',
            opacity: 0.2,
            cursor: 'not-allowed',
          }}
          aria-label={ariaLabelNext || 'Next'}
          icon={<Icon ml="-8px" mr="-10px" icon={IconName.chevronRight} h="1em" />}
          borderRadius="full"
          data-testid="next-page-button"
        />
      </ConditionalWrapper>
    </Flex>
  );
};
