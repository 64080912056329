import { ComponentMultiStyleConfig, ComponentStyleConfig } from '@chakra-ui/react';

export const accordion: ComponentMultiStyleConfig = {
  parts: ['container', 'button', 'panel'],
  baseStyle: {
    container: {
      borderColor: 'borderLight',
    },

    button: {
      px: 'sm',
      py: 'lg',
      color: 'muted',
      '&[aria-expanded=true]': {
        color: 'white',
      },
    },

    panel: {
      px: 'sm',
      pt: 0,
      pb: 'lg',

      a: {
        textDecoration: 'underline',
      },
    },
  },
  variants: {
    'plus-minus': {
      container: {
        border: 'none',
      },
      button: {
        display: 'flex',
        justifyContent: 'space-between',
        px: 0,
        py: 0,
        fontSize: '1em',
        color: 'currentColor',
        textAlign: 'left',
        '&::after': {
          content: '"+"',
          color: 'desiredDawn',
        },
        '&[aria-expanded=true]': {
          '&::after': {
            content: '"-"',
            color: 'desiredDawn',
          },
        },
      },
      panel: {
        px: 0,
        pt: 'sm',
        pb: 0,
      },
    },
  },
};
