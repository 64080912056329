import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      fill="none"
      viewBox="0 0 32 32"
      ref={svgRef}
      {...props}>
      <path
        fill="url(#paint0_linear_13734_90670)"
        fillRule="evenodd"
        d="M13.423 1.77L10.34 9.262l-7.79.74c-1.118.107-2.067.896-2.412 2.008-.347 1.11-.026 2.331.819 3.1l5.885 5.372-1.732 7.95c-.248 1.14.178 2.325 1.086 3.01a2.71 2.71 0 003.083.146L16 27.414l6.72 4.174a2.71 2.71 0 003.084-.147c.908-.685 1.334-1.87 1.086-3.01l-1.732-7.95 5.885-5.37c.845-.77 1.166-1.99.82-3.1-.346-1.112-1.295-1.902-2.412-2.008l-7.79-.74-3.084-7.493C18.135.697 17.122 0 16 0c-1.122 0-2.135.697-2.577 1.77z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13734_90670"
          x1={9.21}
          x2={28.33}
          y1={0.88}
          y2={5.11}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9BFFB" />
          <stop offset={1} stopColor="#E8BBFB" />
        </linearGradient>
      </defs>
    </svg>
  );
});
const StarFilledIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default StarFilledIcon;
