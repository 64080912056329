import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      ref={svgRef}
      {...props}>
      <path
        fill="currentColor"
        d="M6.05 3h11.1v12.8L10.2 23l-.975-.775a.751.751 0 01-.225-.35 1.754 1.754 0 01-.075-.55v-.25L10.05 15.8H2.575c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-2.05c0-.117-.012-.238-.037-.363a.6.6 0 01.037-.362l3.15-7.25c.15-.35.396-.65.738-.9A1.81 1.81 0 016.05 3zm9.6 1.5H5.725l-3.15 7.475V14.3H11.9l-1.325 6.225 5.075-5.35V4.5zm0 10.675V4.5v10.675zm1.5.625v-1.5h3.325V4.5H17.15V3h4.825v12.8H17.15z"
      />
    </svg>
  );
});
const ThumbsDownIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default ThumbsDownIcon;
