import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { SearchItemSmall } from '@/components/features/search/search-item-small/search-item-small';
import { URLS } from '@/config/urls';
import { Box, Container, Flex, Text } from '@chakra-ui/react';
import { IconName, Loader, TextLink } from '@meritcircle/ui/components';
import { MAX_SEARCH_ITEMS, MIN_CHAR_TO_START_SEARCH } from './search-bar.types';
import { PageSearchData } from '@/components/features/search/search.types';

type SearchBarDropdownProps = {
  searchTerm: string;
  searchResults: PageSearchData[];
  isFetching: boolean;
  hasError: boolean;
  onClose: () => void;
};

export const SearchBarDropdown = ({
  searchTerm,
  searchResults,
  isFetching,
  hasError,
  onClose,
}: SearchBarDropdownProps) => {
  const { t } = useTranslation('common');

  const shouldRenderError = hasError && !isFetching;

  const shouldRenderLoading = isFetching;

  const shouldRenderNoResults =
    !hasError &&
    !isFetching &&
    !searchResults?.length &&
    searchTerm.length >= MIN_CHAR_TO_START_SEARCH;

  return (
    <Flex
      flexDir="column"
      position="absolute"
      background="black"
      top="100%"
      left="0"
      width="100%"
      gap="space.16"
      zIndex={3}
      height={hasError || isFetching || shouldRenderNoResults ? rem(476) : 'unset'}>
      <Container height="100%">
        {searchTerm && searchTerm.length >= MIN_CHAR_TO_START_SEARCH && (
          <Flex
            direction="column"
            gap="space.16"
            px={{ base: 0, md: 'space.8' }}
            pb={{ base: 'space.24', md: 'space.48' }}
            pt="space.8"
            height="100%">
            {shouldRenderError && (
              <Text color="white" fontSize={{ base: 'md', sm: rem(18) }}>
                {`${t('errors.generic')}`}
              </Text>
            )}
            {shouldRenderLoading && (
              <Flex justifyContent="center" alignItems="center" h="100%">
                <Loader
                  orientation="column"
                  text={t('search.loading')}
                  textProps={{ fontSize: 'lg', fontWeight: 400 }}
                />
              </Flex>
            )}
            {shouldRenderNoResults && (
              <Flex flexDirection="column" gap="space.4">
                <Text color="white" fontSize={{ base: 'md', sm: rem(18) }}>
                  {`${t('search.no-results')} "‘${searchTerm}’"`}
                </Text>
                <Text fontSize={{ base: 'xs', sm: 'sm' }} color="meltingSnow">
                  {t('search.no-results-subtext')}
                </Text>
              </Flex>
            )}
            {!isFetching &&
              searchResults?.slice(0, MAX_SEARCH_ITEMS).map(searchItem => (
                <Box onClick={onClose} key={searchItem.title}>
                  <SearchItemSmall {...searchItem} />
                </Box>
              ))}
            {!isFetching && searchResults?.length > MAX_SEARCH_ITEMS ? (
              <TextLink
                mt="space.16"
                href={`${URLS.SEARCH}?search=${searchTerm}`}
                rightIcon={{ icon: IconName.arrowRightFill }}>
                {t('search.link-to-search-page')}
              </TextLink>
            ) : null}
          </Flex>
        )}
      </Container>
    </Flex>
  );
};
