import { LoadingStates, useLazyImageSrc } from '../../utils/hooks/use-lazy-image';
import { Box, Image } from '@chakra-ui/react';
import { LazyImageProps } from './lazy-image.types';

export const LazyImage = ({ src, placeholderUrl = '', ...props }: LazyImageProps) => {
  const [ref, imageSrc, loadingState] = useLazyImageSrc({ url: src, placeholderUrl });

  // fallback if image is loading to prevent alt text showing before image loads
  if (loadingState === LoadingStates.loading) {
    return <Box />;
  }

  return <Image {...props} src={imageSrc} objectPosition={'80% 50%'} ref={ref} />;
};
