import { Box, Flex } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { Footer } from '../features/layout/footer';
import { Header } from '../features/layout/header/header';
import { Toasts } from '../features/layout/toasts';
import { PageTracking, PageTrackingProps } from '../utils/page-tracking/page-tracking';

interface Props extends PageTrackingProps {
  disableTracking?: boolean;
}

export const BaseLayout = ({
  children,
  disableTracking = false,
  userTrackingData = {},
  pageTrackingData = {},
  gameTrackingData = {},
}: PropsWithChildren<Props>) => (
  <PageTracking
    disable={disableTracking}
    userTrackingData={userTrackingData}
    pageTrackingData={pageTrackingData}
    gameTrackingData={gameTrackingData}>
    <Flex flexDirection="column" minHeight="100%">
      <Header />
      <Toasts />
      <Box as="main" flex="1 0 auto" display="block" position="relative">
        {children}
      </Box>
      <Footer />
    </Flex>
  </PageTracking>
);
