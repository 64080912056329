import { Button } from '@chakra-ui/react';
import { MouseEvent, useEffect, useState } from 'react';
import { Loader } from '../loaders/loader/loader';
import { LoadMoreProps } from './load-more.types';

export const LoadMore = ({
  label = 'Load more',
  loadingText = 'Loading',
  fetching = false,
  onClick,
}: LoadMoreProps) => {
  const [isFetching, setIsFetching] = useState(fetching);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
  };

  useEffect(() => {
    setIsFetching(fetching);
  }, [fetching]);

  return isFetching ? (
    <Loader text={loadingText} />
  ) : (
    <Button variant="outline" onClick={handleClick}>
      {label}
    </Button>
  );
};
