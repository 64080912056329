import { Group } from '@visx/group';
import { BarGroup } from '@visx/shape';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend';
import { Flex, Divider, Text, ResponsiveValue, Box } from '@chakra-ui/react';
import useMeasure from 'react-use-measure';
import { Fragment } from 'react';
import { useToken } from '@chakra-ui/react';
import { BarChartData, BarChartProps } from './bar-chart.types';

const margin = { top: 80, right: 0, bottom: 17, left: 45 };
const chartMinWidth = '560px';
const colorRange = ['#F27A3B', '#B22966', '#F8E19B', '#247BA0', '#93A2E6'];
const legendGlyphSize = 15;
const lineColor = '#3F3F3F';

export const BarChart = ({
  data,
  xAxisKey = 'date',
  xAxisFormat = label => label,
  yAxisFormat = label => label,
  legendFormat = label => label.text,
  totalValue,
  totalRange,
  height,
}: BarChartProps) => {
  const [ref, bounds] = useMeasure();
  const [sm] = useToken('breakpoints', ['sm']);
  const getXAxis = (group: BarChartData) => group[xAxisKey];
  const parsedXAxis = data.map(getXAxis);
  const legendKeys = Object.keys(data[0]).filter(key => key !== xAxisKey);
  const highestValue = Math.max(
    ...data.map(d => Math.max(...legendKeys.map(key => Number(d[key])))),
  );

  const xMax = Math.max(0, bounds.width - margin.left - margin.right);
  const yMax = Math.max(0, bounds.height - margin.bottom);

  const xAxisScale = scaleBand<string>({
    domain: parsedXAxis,
    paddingInner: 0.3,
  });
  const yAxisScale = scaleLinear<number>({
    domain: [0, highestValue + highestValue * 0.3], // 0.3 adds a space of 30% above the highest value.
    nice: 0.5,
  });
  const barScale = scaleBand<string>({
    domain: legendKeys,
    padding: 0.1,
  });
  const legendScale = scaleOrdinal({
    domain: legendKeys,
    range: colorRange,
  });

  xAxisScale.rangeRound([0, xMax]);
  barScale.rangeRound([0, xAxisScale.bandwidth()]);
  yAxisScale.range([yMax - margin.bottom, 0]);

  return (
    <Flex flex={1} flexDir={{ base: 'column', lg: 'row' }}>
      <Flex
        pb={{ base: 5, lg: 0 }}
        px={{ base: 5, lg: 0 }}
        mx={{ base: -5, lg: 0 }}
        flexBasis={{ base: 'auto', lg: '75%' }}
        overflowX="auto"
        overflowY="hidden">
        <Flex minWidth={{ base: chartMinWidth, lg: 0 }} height={height} flex="1" ref={ref}>
          <svg width="100%" height={bounds.height}>
            <Group top={0} left={margin.left}>
              <BarGroup
                data={data}
                keys={legendKeys}
                height={yMax - margin.bottom}
                x0={getXAxis}
                x0Scale={xAxisScale}
                x1Scale={barScale}
                yScale={yAxisScale}
                color={legendScale}>
                {barGroups => {
                  if (!barGroups.length) return;
                  const firstGroup = barGroups[0];
                  const lastGroup = barGroups.slice(-1)[0];
                  const lastBar = lastGroup.bars.slice(-1)[0];

                  return (
                    <>
                      <GridRows
                        scale={yAxisScale}
                        left={firstGroup.x0}
                        width={lastGroup.x0 + lastBar.x + lastBar.width - firstGroup.x0}
                        height={Math.max(0, yMax - margin.bottom)}
                        stroke={lineColor}
                      />
                      {barGroups.map(barGroup => (
                        <Group
                          key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                          left={barGroup.x0}>
                          {barGroup.bars.map(bar => {
                            const pathWidthPoint = bar.width * 0.5;
                            const pathHeightPoint = Math.max(0, bar.height - pathWidthPoint);
                            const pathYOffset = Math.min(0, bar.height - pathWidthPoint);

                            // This draws an svg path. It's a rectangle with 2 bezier curves on top to make sure the rounding is only at the top of the bar.
                            return (
                              <Group top={bar.y + pathYOffset} left={bar.x} key={bar.index}>
                                <path
                                  d={`m ${pathWidthPoint} 0 q ${pathWidthPoint} 0 ${pathWidthPoint} ${pathWidthPoint} l 0 ${pathHeightPoint} l -${bar.width} 0 l 0 -${pathHeightPoint} q 0 -${pathWidthPoint} ${pathWidthPoint} -${pathWidthPoint}`}
                                  fill={bar.color}
                                />
                              </Group>
                            );
                          })}
                        </Group>
                      ))}
                    </>
                  );
                }}
              </BarGroup>
              <AxisLeft
                top={5}
                left={0}
                scale={yAxisScale}
                hideAxisLine
                tickFormat={yAxisFormat}
                tickStroke={'transparent'}
                tickLabelProps={() => ({
                  fill: 'white',
                  fontSize: 12,
                  textAnchor: 'end',
                })}
              />
              <AxisBottom
                top={yMax - margin.bottom * 0.5}
                tickFormat={xAxisFormat}
                scale={xAxisScale}
                hideAxisLine
                tickStroke={'transparent'}
                tickLabelProps={() => ({
                  fill: 'white',
                  fontSize: 12,
                  textAnchor: 'middle',
                })}
              />
            </Group>
          </svg>
        </Flex>
      </Flex>
      <Flex width="50px" display={{ base: 'none', lg: 'block' }} />
      <Flex flexGrow="1" direction="column">
        <ChartLegend legendKeys={legendKeys} legendFormat={legendFormat} />
        <Box>
          <Text fontSize={{ base: 'xl', lg: '3xl' }}>{totalValue}</Text>
          <Text fontSize="xs">{totalRange}</Text>
        </Box>
      </Flex>
    </Flex>
  );
};
interface ChartLegendProps {
  legendKeys: string[];
  legendFormat: BarChartProps['legendFormat'];
}

const ChartLegend = ({ legendKeys, legendFormat = label => label.text }: ChartLegendProps) => {
  const legendScale = scaleOrdinal({
    domain: legendKeys,
    range: colorRange,
  });

  return (
    <LegendOrdinal scale={legendScale}>
      {labels => (
        <Flex flexDir="column" flexGrow="1">
          {labels.map((label, index) => (
            <Fragment key={`legend-quantile-${index}`}>
              <LegendItem margin="17px 0">
                <svg width={legendGlyphSize} height={legendGlyphSize}>
                  <circle
                    fill={label.value}
                    cx={legendGlyphSize / 2}
                    cy={legendGlyphSize / 2}
                    r={legendGlyphSize / 2}
                  />
                </svg>
                <LegendLabel align="left" margin="0 0 0 10px">
                  <Text fontWeight="bold">{legendFormat(label)}</Text>
                </LegendLabel>
              </LegendItem>
              <Divider />
            </Fragment>
          ))}
        </Flex>
      )}
    </LegendOrdinal>
  );
};
