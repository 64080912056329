import { Flex, LinkOverlay, Text } from '@chakra-ui/react';
import { LazyImage } from '@meritcircle/ui/components';
import NextLink from 'next/link';
import { rem } from 'polished';
import { PageSearchData } from '../search.types';

export const SearchItemSmall = ({
  title,
  image,
  fullPath,
  translationNamespace,
}: PageSearchData) => (
  <Flex
    key={`${title}${translationNamespace}`}
    alignItems="center"
    gap="space.16"
    position="relative">
    <Flex w={rem(60)} minHeight={rem(60)}>
      {image?.url ? (
        <LazyImage
          src={image.url}
          alt={image.title}
          alignSelf="stretch"
          objectFit="cover"
          rounded={rem(4)}
        />
      ) : null}
    </Flex>

    <Flex flexDirection="column" gap="space.4">
      <NextLink href={fullPath} passHref legacyBehavior>
        <LinkOverlay>
          <Text color="white" fontSize={{ base: 'md', sm: 'lg' }}>
            {title}
          </Text>
        </LinkOverlay>
      </NextLink>
      <Text
        variant="muted"
        size={{ base: 'xs', sm: 'sm' }}
        sx={{
          '&:first-letter': {
            textTransform: 'uppercase',
          },
        }}>
        {translationNamespace}
      </Text>
    </Flex>
  </Flex>
);
