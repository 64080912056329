import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { menuAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  menuAnatomy.keys,
);

const baseStyle = definePartsStyle({
  button: {
    _active: {
      outline: '0',
    },
    _hover: {
      bg: 'none',
    },
    _focus: {
      outline: '0',
      boxShadow: 'none',
    },
  },
});

export const menuTheme = defineMultiStyleConfig({ baseStyle });
