import { Image as ChakraImage } from '@chakra-ui/react';
import { getPlaceholderUrl, useLazyImageSrc } from '../../utils';
import { ImageProps, SourceSize } from './image.types';

enum mapBreakpointToSize {
  base = 480,
  sm = 768,
  md = 992,
  lg = 1536,
  '2xl' = 1920,
}

const parseMedia = (breakpoint: keyof SourceSize): string =>
  `(max-width: ${mapBreakpointToSize[breakpoint]}px)`;

const parseSourceSet = (src: string, parameters: string): string => `${src}?${parameters}`;

export const Image = ({ sourceSizes, src, loading = 'lazy', ...props }: ImageProps) => {
  const [ref, imageSrc] = useLazyImageSrc({ url: src, placeholderUrl: getPlaceholderUrl(src) });
  const imageTag = <ChakraImage src={imageSrc} loading={loading} {...props} ref={ref} />;

  return !sourceSizes ? (
    <>{imageTag}</>
  ) : (
    <picture>
      {Object.entries(sourceSizes).map(([breakpoint, parameters], index) => (
        <source
          media={parseMedia(breakpoint as keyof SourceSize)}
          srcSet={parseSourceSet(src, parameters)}
          key={`${src}-${index}`}
        />
      ))}
      {imageTag}
    </picture>
  );
};
