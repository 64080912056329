import { ScholarshipStatus } from '@/client/graphql/types.generated';
import { GtmEvent, pushDataLayer } from '@/utils/gtm';
import { encodeSha256 } from '@/utils/sha256';
import { PropsWithChildren, useEffect } from 'react';
import { useCurrentUser } from '@/utils/hooks/useCurrentUser';
import { useMetaData } from '@/utils/hooks/useMetaData';

export type PageTrackingProps = {
  disable?: boolean;
  userTrackingData?: Record<string, unknown>;
  pageTrackingData?: Record<string, unknown>;
  gameTrackingData?: Record<string, unknown>;
};

export const PageTracking = ({
  children,
  userTrackingData = {},
  pageTrackingData = {},
  gameTrackingData = {},
  disable = false,
}: PropsWithChildren<PageTrackingProps>) => {
  const currentUserData = useCurrentUser();
  const metaData = useMetaData();

  useEffect(() => {
    if (currentUserData.fetchingUserData || disable) {
      return;
    }

    const trackData = async () => {
      const id = currentUserData.id ? await encodeSha256(currentUserData.id) : undefined;
      const email = currentUserData.emailAddress
        ? await encodeSha256(currentUserData.emailAddress)
        : undefined;
      const { market, location } = metaData;

      const appliedScholarships =
        currentUserData.scholarships
          ?.filter(scholarship => scholarship.status === ScholarshipStatus.Applied)
          .map(({ game }) => game.id) || [];
      const approvedScholarships =
        currentUserData.scholarships
          ?.filter(scholarship => scholarship.status === ScholarshipStatus.Approved)
          .map(({ game }) => game.id) || [];

      pushDataLayer({
        event: GtmEvent.init,
        user: {
          id,
          email,
          loggedIn: !!currentUserData.id ? '1' : '0',
          ...userTrackingData,
        },
        page: {
          type: location.route,
          language: market.language,
          country: market.country,
          ...pageTrackingData,
        },
        game: {
          requestedScholarships: appliedScholarships,
          gamesToPlay: approvedScholarships,
          rewardLevel: currentUserData?.level,
          xpTotal: currentUserData?.xp,
          ...gameTrackingData,
        },
      });
    };

    trackData();
  }, [disable, metaData, currentUserData, userTrackingData, pageTrackingData, gameTrackingData]);

  return <>{children}</>;
};
