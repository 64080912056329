import { GraphCacheConfig } from '@/client/graphql/types.generated';
import { updates } from './graphql-update-handlers';

export const cacheExchangeConfig: GraphCacheConfig = {
  // schema: schema as IntrospectionData,
  keys: {
    // Query results without ids
    ReviewsByGameSummary: () => null,
    ReviewsOverview: () => null,
    ReviewSummary: () => null,
    ReviewsRepliesOverview: () => null,
    AllGames: () => null,
    TaskRules: () => null,
    LinkedTaskComponent: () => null,
    TaskFormField: () => null,
    GameSys: () => null,
    Media: () => null,
    GameTagItems: () => null,

    // The game.systemRequirement prop has no unique id, so we use the platform as its id instead, which is unique.
    SystemRequirement: result => result.platform,
  },
  resolvers: {},
  updates: updates,
};
