import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={25}
      fill="none"
      viewBox="0 0 20 25"
      ref={svgRef}
      {...props}>
      <path fill="currentColor" d="M20 12.5L.5 24.191V.81L20 12.5z" />
    </svg>
  );
});
const PlayIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default PlayIcon;
