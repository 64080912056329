import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      viewBox="0 0 18 18"
      ref={svgRef}
      {...props}>
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
        d="M.9 8.959C.9 13.445 4.536 17.1 9 17.1c4.464 0 8.1-3.656 8.1-8.141C17.1 4.555 13.463.9 9 .9 4.537.9.9 4.472.9 8.959zm1.506 0c0-3.638 2.976-6.628 6.594-6.628 3.618 0 6.675 3.072 6.675 6.71 0 3.636-3.056 6.628-6.675 6.628-3.616 0-6.594-3.07-6.594-6.71z"
      />
      <path
        fill="currentColor"
        stroke="#fff"
        strokeWidth={0.2}
        d="M8.084 5.677c0 .269.09.5.253.665A.914.914 0 009 6.597c.268 0 .499-.09.663-.255a.923.923 0 00.253-.665.93.93 0 00-.253-.666A.914.914 0 009 4.756a.857.857 0 00-.357.07.948.948 0 00-.285.206.952.952 0 00-.205.286.867.867 0 00-.07.359z"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
        d="M8.248 12.815c0 .473.379.757.753.757a.754.754 0 00.52-.235.76.76 0 00.233-.522V8.713c0-.225-.068-.417-.203-.553-.135-.136-.326-.204-.55-.204a.746.746 0 00-.753.757v4.102z"
      />
    </svg>
  );
});
const InfoIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default InfoIcon;
