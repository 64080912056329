import { PROTECTED_ROUTES } from '@/config/urls';
import { NextRequest } from 'next/server';
import { IncomingMessage } from 'http';
import { isBrowser } from './isBrowser';

export const getIsomorphicUrl = (req: Request | NextRequest | IncomingMessage) => {
  if (!req?.url) return isBrowser ? window.location : new URL(process.env.SITE_URL);

  let url = req?.url;
  if (!url?.startsWith('http')) {
    url = `${process.env.SITE_URL}${url}`;
  }
  return new URL(url);
};

export const isRouteProtected = (pathname?: string) =>
  PROTECTED_ROUTES.some(route => pathname.startsWith(route));
