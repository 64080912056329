import { Box, theme, chakra, Text } from '@chakra-ui/react';
import { rem } from 'polished';
import { useRef, useState } from 'react';
import { useOutsideClick } from '@chakra-ui/react';
import { ArrowDown, BarsFiler } from '../../icons/generated';
import { SelectProps } from './select.types';

export const Select = ({
  sortLabel,
  selectedOption,
  handleOptionSelect,
  options,
  error = '',
  variant = 'primary',
  placeholder = '',
}: SelectProps) => {
  const selectContainerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  useOutsideClick({
    ref: selectContainerRef,
    handler: () => setIsOpen(false),
  });

  const handleSelect = (value: string) => {
    handleOptionSelect(value);
    setIsOpen(false);
  };

  const isPrimary = variant === 'primary';

  return (
    <Box
      ref={selectContainerRef}
      minW={rem(260)}
      position="relative"
      cursor="pointer"
      bg={isPrimary ? 'mediumGrey' : 'darkGrey'}
      borderTopRadius="lg"
      borderBottomRadius={isOpen ? 'none' : 'lg'}
      border={error && !selectedOption && !isOpen ? `1px solid ${theme.colors.red[500]}` : ''}
      zIndex={10}>
      <Box
        position="relative"
        px={isPrimary ? 'space.20' : 'space.16'}
        py={isPrimary ? 'space.28' : 'space.16'}
        display="flex"
        alignItems="center"
        cursor="pointer"
        onClick={() => setIsOpen(!isOpen)}
        color="desiredDawn">
        <Box>
          {sortLabel && !isPrimary && `${sortLabel}: `}
          <chakra.span
            fontSize={isPrimary ? rem(12) : rem(16)}
            color={isPrimary && !selectedOption ? 'muted' : 'white'}>
            {options.find(option => option.value === selectedOption)?.label || placeholder}
          </chakra.span>
        </Box>
        <Box position="absolute" right="space.16">
          {isPrimary ? (
            <ArrowDown
              color="white"
              sx={{
                transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
                transition: '0.2s ease-in',
              }}
            />
          ) : (
            <BarsFiler />
          )}
        </Box>
      </Box>
      {error && !selectedOption && !isOpen && (
        <Text
          position="absolute"
          left="0"
          fontSize="xs"
          color={theme.colors.red[500]}
          top="space.4"
          px={isPrimary ? 'space.20' : 'space.16'}>
          {error}
        </Text>
      )}
      <Box
        display={isOpen ? 'block' : 'none'}
        borderBottomRadius="lg"
        position="absolute"
        w="100%"
        top="100%"
        bg={isPrimary ? 'mediumGrey' : 'darkGrey'}>
        {options
          .filter(option => option.value !== selectedOption)
          .map(option => (
            <Box
              key={option.value}
              borderTop={`1px solid ${isPrimary ? '#3A3A3A' : '#2D2C30'} `}
              position="relative"
              px={isPrimary ? 'space.20' : 'space.16'}
              py={isPrimary ? 'space.28' : 'space.16'}
              display="flex"
              alignItems="center"
              cursor="pointer"
              onClick={() => handleSelect(option.value)}
              color="desiredDawn"
              transition="0.2s ease-in"
              _hover={{
                color: 'white',
              }}>
              <chakra.span fontSize={isPrimary ? rem(12) : rem(16)}>{option.label}</chakra.span>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
