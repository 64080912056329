import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={173}
      height={40}
      fill="none"
      viewBox="0 0 173 40"
      ref={svgRef}
      {...props}>
      <g clipPath="url(#clip0_17281_66819)">
        <path
          fill="#F1F1F5"
          d="M0 31.274h3.702v-2.092c1.002 1.155 2.428 2.654 5.675 2.654 6.16 0 8.376-5.84 8.376-9.306 0-4.59-3.338-9.18-8.467-9.18-1.881 0-3.854.718-5.25 2.154V8.166H0v23.108zm3.793-8.9c0-2.53 1.912-5.215 5.007-5.215 2.55 0 4.917 2.03 4.917 5.402 0 3.373-2.216 5.465-4.856 5.465-2.367 0-5.068-1.81-5.068-5.652zm31.471 2.123c.12-.56.241-1.275.241-2.084 0-4.354-3.16-8.987-8.637-8.987-5.296 0-8.787 4.385-8.787 9.267 0 5.038 3.732 9.143 8.817 9.143 3.943 0 7.102-2.892 8.065-6.002H30.75c-.752 1.43-2.076 2.208-3.852 2.208-2.858 0-4.423-2.426-4.604-3.545h12.97zm-13.09-3.328c.752-3.203 3.1-3.95 4.724-3.95 2.137 0 4.063 1.182 4.515 3.95h-9.24zm31.741-7.152H50.22v2.176h-.06c-.122-.248-2.151-2.767-5.575-2.767-4.665 0-8.422 3.825-8.422 9.112 0 5.753 3.848 9.298 8.18 9.298 2.12 0 4.605-1.15 5.877-2.706v2.146h3.696v-17.26zM50.098 22.6c0 3.203-2.12 5.442-4.908 5.442-2.878 0-4.998-2.395-4.998-5.318 0-2.581 1.696-5.504 4.998-5.504 2.394 0 4.908 1.803 4.908 5.38zm5.462 8.578h3.99v-8.567c0-1.824 0-5.412 3.178-5.412 2.46 0 3.33 2.103 3.33 4.453v9.526h3.99v-8.567c0-1.082-.031-2.783.6-3.804a3.228 3.228 0 012.759-1.608c3.149 0 3.149 3.464 3.149 4.453v9.526h3.99v-10.02c0-2.536-.6-4.176-1.26-5.073-.6-.866-2.22-2.66-5.49-2.66-1.17 0-3.629.124-5.369 2.691-1.56-2.474-3.899-2.69-4.799-2.69-2.88 0-4.019 1.484-4.349 2.04h-.06v-1.453h-3.66v17.165z"
        />
        <g filter="url(#filter0_ddi_17281_66819)">
          <path
            fill="#E8BBFB"
            fillRule="evenodd"
            d="M82.702 9.604l4.315-1.438v10.729l16.477-5.15c3.242-1.012 6.536 1.41 6.536 4.806v12.627h-4.315V17.832a.72.72 0 00-.934-.687l-17.764 5.552v8.481h-4.315V9.604z"
            clipRule="evenodd"
          />
        </g>
        <g filter="url(#filter1_dd_17281_66819)">
          <path
            fill="#E8BBFB"
            fillRule="evenodd"
            d="M141.672 23.987V9.604l4.315-1.438v10.729l16.478-5.15c3.241-1.012 6.535 1.41 6.535 4.806v7.593a5.034 5.034 0 01-5.034 5.034h-23.732l1.438-3.595h22.294a.72.72 0 00.719-.72v-9.031a.719.719 0 00-.933-.687l-17.411 5.441-4.669 1.4z"
            clipRule="evenodd"
          />
        </g>
        <g filter="url(#filter2_dd_17281_66819)">
          <path
            fill="#E8BBFB"
            fillRule="evenodd"
            d="M134.481 27.583a.72.72 0 00.719-.72V11.761l4.315 1.439v12.944a5.034 5.034 0 01-5.034 5.034h-17.26a5.034 5.034 0 01-5.034-5.034V13.2l4.315-1.439v15.102c0 .398.322.72.719.72h17.26z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_ddi_17281_66819"
          width={56.09}
          height={51.78}
          x={68.32}
          y={-6.22}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1.8} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.207357 0 0 0 0 0.462705 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_17281_66819" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={7.19} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.270588 0 0 0 0 0.223529 0 0 0 0.4 0" />
          <feBlend in2="effect1_dropShadow_17281_66819" result="effect2_dropShadow_17281_66819" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_17281_66819" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1.44} />
          <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend in2="shape" result="effect3_innerShadow_17281_66819" />
        </filter>
        <filter
          id="filter1_dd_17281_66819"
          width={57.53}
          height={51.78}
          x={125.85}
          y={-6.22}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1.8} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.207357 0 0 0 0 0.462705 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_17281_66819" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={7.19} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.270588 0 0 0 0 0.223529 0 0 0 0.4 0" />
          <feBlend in2="effect1_dropShadow_17281_66819" result="effect2_dropShadow_17281_66819" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_17281_66819" result="shape" />
        </filter>
        <filter
          id="filter2_dd_17281_66819"
          width={56.09}
          height={48.18}
          x={97.8}
          y={-2.62}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1.8} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.207357 0 0 0 0 0.462705 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_17281_66819" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={7.19} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.270588 0 0 0 0 0.223529 0 0 0 0.4 0" />
          <feBlend in2="effect1_dropShadow_17281_66819" result="effect2_dropShadow_17281_66819" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_17281_66819" result="shape" />
        </filter>
        <clipPath id="clip0_17281_66819">
          <path fill="#fff" d="M0 0h173v40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});
const BeamLogoPlainIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default BeamLogoPlainIcon;
