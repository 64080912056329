import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ScholarshipGameFieldsFragment = { __typename: 'Game', id: string, slug: string, title: string, scholarshipApplicationCount: number, logo: { __typename: 'Media', title?: string | null, url?: string | null, width?: number | null, height?: number | null }, mainImage: { __typename: 'Media', title?: string | null, width?: number | null, height?: number | null, url?: string | null } };

export type UserScholarshipQueryVariables = Types.Exact<{
  gameId: Types.Scalars['String'];
  status: Array<Types.ScholarshipStatus>;
}>;


export type UserScholarshipQuery = { __typename: 'Query', userScholarship: { __typename: 'Scholarship', id: string, status: Types.ScholarshipStatus, queuePosition: number, game: { __typename: 'Game', id: string, slug: string, title: string, scholarshipApplicationCount: number, logo: { __typename: 'Media', title?: string | null, url?: string | null, width?: number | null, height?: number | null }, mainImage: { __typename: 'Media', title?: string | null, width?: number | null, height?: number | null, url?: string | null } } } };

export type UserScholarshipsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ScholarshipFilterInput>;
  sort?: Types.InputMaybe<Types.ScholarshipSortInput>;
}>;


export type UserScholarshipsQuery = { __typename: 'Query', userScholarships: Array<{ __typename: 'Scholarship', id: string, status: Types.ScholarshipStatus, queuePosition: number, game: { __typename: 'Game', id: string, slug: string, title: string, scholarshipApplicationCount: number, logo: { __typename: 'Media', title?: string | null, url?: string | null, width?: number | null, height?: number | null }, mainImage: { __typename: 'Media', title?: string | null, width?: number | null, height?: number | null, url?: string | null } } }> };

export type CreateScholarshipMutationVariables = Types.Exact<{
  input: Types.CreateScholarshipInput;
}>;


export type CreateScholarshipMutation = { __typename: 'Mutation', createScholarship: { __typename: 'Scholarship', id: string, status: Types.ScholarshipStatus, queuePosition: number, game: { __typename: 'Game', id: string, slug: string, title: string, scholarshipApplicationCount: number, logo: { __typename: 'Media', title?: string | null, url?: string | null, width?: number | null, height?: number | null }, mainImage: { __typename: 'Media', title?: string | null, width?: number | null, height?: number | null, url?: string | null } }, user: { __typename: 'User', discordId?: string | null, dateOfBirth?: string | null } } };

export const ScholarshipGameFieldsFragmentDoc = gql`
    fragment ScholarshipGameFields on Game {
  id
  slug
  title
  logo {
    title
    url
    width
    height
  }
  mainImage {
    title
    width
    height
    url
  }
  scholarshipApplicationCount
}
    `;
export const UserScholarshipDocument = gql`
    query UserScholarship($gameId: String!, $status: [ScholarshipStatus!]!) {
  userScholarship(gameId: $gameId, filter: {status: $status}) {
    id
    status
    queuePosition
    game {
      ...ScholarshipGameFields
    }
  }
}
    ${ScholarshipGameFieldsFragmentDoc}`;

export function useUserScholarshipQuery(options: Omit<Urql.UseQueryArgs<UserScholarshipQueryVariables>, 'query'>) {
  return Urql.useQuery<UserScholarshipQuery>({ query: UserScholarshipDocument, ...options });
};
export const UserScholarshipsDocument = gql`
    query UserScholarships($filter: ScholarshipFilterInput, $sort: ScholarshipSortInput) {
  userScholarships(filter: $filter, sort: $sort) {
    id
    status
    queuePosition
    game {
      ...ScholarshipGameFields
    }
  }
}
    ${ScholarshipGameFieldsFragmentDoc}`;

export function useUserScholarshipsQuery(options?: Omit<Urql.UseQueryArgs<UserScholarshipsQueryVariables>, 'query'>) {
  return Urql.useQuery<UserScholarshipsQuery>({ query: UserScholarshipsDocument, ...options });
};
export const CreateScholarshipDocument = gql`
    mutation CreateScholarship($input: CreateScholarshipInput!) {
  createScholarship(input: $input) {
    id
    status
    queuePosition
    game {
      ...ScholarshipGameFields
    }
    user {
      discordId
      dateOfBirth
    }
  }
}
    ${ScholarshipGameFieldsFragmentDoc}`;

export function useCreateScholarshipMutation() {
  return Urql.useMutation<CreateScholarshipMutation, CreateScholarshipMutationVariables>(CreateScholarshipDocument);
};