import { CSSObject } from '@chakra-ui/react';

export const globalStyle: CSSObject = {
  'html, body': {
    backgroundColor: 'black',
    color: 'white',
    height: '100%',
    '#__next': {
      height: '100%',
    },
  },
  body: {
    minWidth: '320px',
  },
  '*': {
    boxSizing: 'border-box',
  },
  ul: {
    'li::marker': {
      fontSize: 'lg',
      color: 'lightPurple',
    },
  },
  '@supports (height: 100dvh)': {
    ':root': {
      '--chakra-vh': '100dvh',
    },
  },
};
