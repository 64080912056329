import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={316}
      height={245}
      fill="none"
      viewBox="0 0 316 245"
      ref={svgRef}
      {...props}>
      <path
        fill="url(#paint0_linear_17028_71852)"
        d="M-231.531 386.668L447.066-5.12l14.853 25.726-663.024 382.797-30.426-16.735z"
      />
      <path
        fill="url(#paint1_linear_17028_71852)"
        d="M-303 361.777L418.421-54.736l14.853 25.727-704.037 406.476-32.237-15.69z"
      />
      <path
        fill="url(#paint2_linear_17028_71852)"
        d="M-200.207 402.885L461.919 20.607l12.731 22.051-662.126 382.279-12.731-22.052z"
      />
      <path
        fill="url(#paint3_linear_17028_71852)"
        d="M-258.498 370.386L433.275-29.01l13.793 23.889-691.774 399.396-13.792-23.889z"
      />
      <path
        fill="url(#paint4_linear_17028_71852)"
        d="M-258.498 370.386L433.275-29.01l13.793 23.889-691.774 399.396-13.792-23.889z"
      />
      <path
        fill="url(#paint5_linear_17028_71852)"
        d="M229.652 120.404l678.597-391.788 14.853 25.726-663.024 382.797-30.426-16.735z"
      />
      <path
        fill="url(#paint6_linear_17028_71852)"
        d="M158.184 95.513L879.605-321l14.853 25.727-704.037 406.476-32.237-15.69z"
      />
      <path
        fill="url(#paint7_linear_17028_71852)"
        d="M260.977 136.622l662.126-382.279 12.731 22.051-662.126 382.279-12.731-22.051z"
      />
      <path
        fill="url(#paint8_linear_17028_71852)"
        d="M202.683 104.122l691.773-399.396 13.792 23.889-691.773 399.396-13.792-23.889z"
      />
      <path
        fill="url(#paint9_linear_17028_71852)"
        d="M202.683 104.122l691.773-399.396 13.792 23.889-691.773 399.396-13.792-23.889z"
      />
      <defs>
        <linearGradient
          id="paint0_linear_17028_71852"
          x1={95.79}
          x2={112.36}
          y1={215.69}
          y2={244.4}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F1E869" />
          <stop offset={0.33} stopColor="#FEA514" />
          <stop offset={0.6} stopColor="#FF4539" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17028_71852"
          x1={68.1}
          x2={84.67}
          y1={165.53}
          y2={194.23}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BBDBFF" />
          <stop offset={0.33} stopColor="#139EDD" />
          <stop offset={0.6} stopColor="#B9F7EA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_17028_71852"
          x1={109.07}
          x2={123.27}
          y1={239.76}
          y2={264.36}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BBFFCA" />
          <stop offset={0.33} stopColor="#48DD13" />
          <stop offset={0.6} stopColor="#008805" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_17028_71852"
          x1={75.78}
          x2={88.42}
          y1={181.32}
          y2={203.22}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF6A9A" />
          <stop offset={0.53} stopColor="#F54" />
          <stop offset={0.96} stopColor="#E63E33" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_17028_71852"
          x1={74.48}
          x2={89.86}
          y1={194.86}
          y2={221.52}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF6B6B" />
          <stop offset={0.33} stopColor="#FE1414" />
          <stop offset={0.6} stopColor="#8E0900" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_17028_71852"
          x1={556.98}
          x2={573.55}
          y1={-50.57}
          y2={-21.87}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F1E869" />
          <stop offset={0.33} stopColor="#FEA514" />
          <stop offset={0.6} stopColor="#FF4539" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_17028_71852"
          x1={529.28}
          x2={545.85}
          y1={-100.73}
          y2={-72.03}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BBDBFF" />
          <stop offset={0.33} stopColor="#139EDD" />
          <stop offset={0.6} stopColor="#B9F7EA" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_17028_71852"
          x1={570.26}
          x2={584.46}
          y1={-26.5}
          y2={-1.9}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BBFFCA" />
          <stop offset={0.33} stopColor="#48DD13" />
          <stop offset={0.6} stopColor="#008805" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_17028_71852"
          x1={536.96}
          x2={549.6}
          y1={-84.94}
          y2={-63.04}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF6A9A" />
          <stop offset={0.53} stopColor="#F54" />
          <stop offset={0.96} stopColor="#E63E33" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_17028_71852"
          x1={535.66}
          x2={551.04}
          y1={-71.4}
          y2={-44.75}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF6B6B" />
          <stop offset={0.33} stopColor="#FE1414" />
          <stop offset={0.6} stopColor="#8E0900" />
        </linearGradient>
      </defs>
    </svg>
  );
});
const BeamRainbowIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default BeamRainbowIcon;
