import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10}
      height={13}
      fill="none"
      viewBox="0 0 10 13"
      ref={svgRef}
      {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.541 4.15h.563c.618 0 1.125.515 1.125 1.143v5.715c0 .628-.507 1.142-1.125 1.142h-6.75a1.137 1.137 0 01-1.125-1.143V5.293c0-.628.506-1.143 1.125-1.143h.562V3.008C1.916 1.43 3.176.15 4.729.15 6.28.15 7.54 1.43 7.54 3.008V4.15zM2.985 3.008V4.15h3.487V3.008c0-.978-.782-1.772-1.743-1.772-.962 0-1.744.794-1.744 1.772z"
        clipRule="evenodd"
      />
    </svg>
  );
});
const LockIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default LockIcon;
