import { ComponentStyleConfig } from '@chakra-ui/react';

export const select: ComponentStyleConfig = {
  baseStyle: {
    field: {
      '> option, > optgroup': {
        // Because we define the base text color on `html, body` in `global-style.ts` to white, we need to
        // contrast the background color of options to a darker shade.
        bg: 'gray.800',
      },
    },
  },
};
