import { Badge, Flex, Heading, Text } from '@chakra-ui/react';
import { LinkBox } from './link-box';
import { LazyImage } from '../lazy-image/lazy-image';
import { ImageLinkBoxProps } from './link-box.types';

export const ImageLinkBox = ({ label, title, image, badges, ...props }: ImageLinkBoxProps) => (
  <LinkBox
    leading={!!image && <LazyImage objectFit="cover" width={100} {...image} />}
    contentBoxProps={{ display: 'flex', minHeight: '133px' }}
    verticalAlignArrow="center"
    {...props}>
    <Flex height="100%" direction="column" justify="center" gap={2}>
      {label && (
        <Text size="xs" textTransform="uppercase" color="orange">
          {label}
        </Text>
      )}
      <Heading size="h4" noOfLines={1}>
        {title}
      </Heading>
      {!!badges?.length && (
        <Flex gap={1} flexWrap="wrap">
          {badges.map((badge, index) => (
            <Badge {...badge} key={index} />
          ))}
        </Flex>
      )}
    </Flex>
  </LinkBox>
);
