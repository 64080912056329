import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      ref={svgRef}
      {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.888 12.7c-.028-2.732 2.227-4.042 2.328-4.108-1.267-1.853-3.241-2.108-3.944-2.136-1.679-.171-3.278.989-4.13.989-.85 0-2.166-.964-3.558-.94-1.832.027-3.52 1.066-4.462 2.705-1.902 3.3-.487 8.19 1.367 10.868.906 1.309 1.986 2.782 3.404 2.728 1.366-.054 1.882-.884 3.534-.884 1.651 0 2.116.884 3.56.856 1.47-.027 2.402-1.334 3.3-2.648 1.04-1.52 1.469-2.993 1.494-3.068-.031-.015-2.864-1.1-2.893-4.362zm-2.716-8.017c.752-.912 1.26-2.18 1.123-3.443-1.086.044-2.399.721-3.178 1.633-.698.81-1.308 2.097-1.144 3.337 1.21.094 2.444-.616 3.199-1.527z"
        clipRule="evenodd"
      />
    </svg>
  );
});
const AppStoreIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default AppStoreIcon;
