import { ChakraTheme } from '@chakra-ui/react';

export const formTextStyles: ChakraTheme['textStyles'] = {
  /**
   * Styles a regular Text component just like an FormErrorMesssage
   */
  'form-error-message': {
    fontWeight: 'bold',
    fontSize: 'xs',
    color: 'red.500',
  },
};
