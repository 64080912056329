import { ChakraTheme } from '@chakra-ui/react';

export const gradients = {
  blueStroke: 'linear-gradient(280deg, #2060D7, #87B0FD)',
  lightOrangeFill: 'linear-gradient(95.65deg, #EC8741 4.5%, #F2AC4B 95.5%)',
  orangeFill: 'linear-gradient(0deg, #E47A28 0%, #DF6122 104.25%)',
  orangeFillTransparent:
    'linear-gradient(98.23deg, rgba(236, 135, 65, 0.2) 18.18%, rgba(242, 172, 75, 0.2) 76.55%)',
  orangeStroke: 'linear-gradient(111.07deg, #F3AD4C 20.74%, #ED8842 85.27%)',
  duskFill:
    'linear-gradient(110.81deg, #8F9EA3 -6.92%, #9CAAB0 1.42%, #B0B9BB 10.23%, #BEB5B0 17.67%, #C3B1A0 25.91%, #C3A485 34.73%, #BC8B68 42.74%, #AF745F 54.27%, #7D4E55 74.04%, #593855 86.2%, #3E2B50 98.42%, #100D1B 110.12%, #100D12 122.53%)',
  duskStroke:
    'linear-gradient(149.96deg, #8F9EA3 -1.72%, #9CAAB0 4.98%, #B0B9BB 12.06%, #BEB5B0 18.03%, #C3B1A0 24.65%, #C3A485 31.74%, #BC8B68 38.17%, #AF745F 47.44%, #7D4E55 63.32%, #593855 73.09%, #3E2B50 82.91%, #100D1B 92.31%, #100D12 102.27%)',
  arcticBlueFill: 'linear-gradient(rgba(22, 128, 255) 0%, rgba(22, 128, 255) 100%)',
  lightBlueFill:
    'linear-gradient(108.74deg, rgba(255, 255, 255, 0.136) 0%, rgba(255, 255, 255, 0.034) 100%)',
  lightBlueStroke:
    'linear-gradient(108.74deg, rgba(105, 120, 255, 0.094) 0%, rgba(255, 255, 255, 0.18) 36.46%, rgba(255, 255, 255, 0.1) 73.96%, rgba(13, 220, 170, 0.082) 100%)',
  orangeLeftRightFill: 'linear-gradient(270deg, #EEA141 0%, #E8872F 100%)',
  orangeTopBottomFill: 'linear-gradient(#F3AD4C 0%, #ED8842 100%)',
  dusk: 'linear-gradient(111deg, #8F9EA3 -6.92%, #9CAAB0 1.42%, #B0B9BB 10.23%, #BEB5B0 17.67%, #C3B1A0 25.91%, #C3A485 34.73%, #BC8B68 42.74%, #AF745F 54.27%, #7D4E55 74.04%, #593855 86.2%, #3E2B50 98.42%, #100D1B 110.12%, #100D12 122.53%)',
  darkRadial:
    'radial-gradient(65.95% 64.95% at 47.01% 8.36%, rgba(255, 255, 255, 0.29) 0%, rgba(255, 255, 255, 0.65) 4%, rgba(255, 255, 255, 0) 44%, rgba(255, 255, 255, 0) 100%)',
  lightPurpleFill: 'linear-gradient(98deg, #E9BFFB 0%, #E8BBFB 100%)',
};

export const effects = {
  textGlow: '0px 0px 5px #FF3576, 0px 0px 20px rgba(255, 69, 57, 0.40)',
  buttonGlow:
    '0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset, 0px 0px 5px 0px #FF3576, 0px 0px 20px 0px rgba(255, 69, 57, 0.40);',
};

export const colors: ChakraTheme['colors'] = {
  // Base Colors
  black: '#000000',
  white: '#ffffff',
  transparent: 'transparent',
  current: 'currentColor',
  gray: {
    50: '#F8F9FB', // Polar Bear
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0A2AC', // Melting Snow
    500: '#8D8D8D',
    600: '#3A3A3A',
    700: '#2B2B2B',
    800: '#1D1B20',
    900: '#121114',
  },
  red: {
    500: '#E53E3E',
  },
  // Borders
  border: '#2D2C30',
  borderLight: '#383838',

  // Tints & Shades
  muted: '#A0A2AC', // Melting Snow

  // Named
  arcticBlue: '#1680FF',
  // polarBear: '#F8F9FB',
  // desiredDawn: '#D8D8D8',

  // New Colors
  lightPurple: '#E8BBFB',
  buttonFontColor: '#710036',
  articBlue: '#6978FF',
  accentBlue: '#BCBBFB',
  snowWhite: '#FFFFFF',
  polarBear: '#F8F9FB', // check gray
  desiredDawn: '#D8D8D8', // check gray
  meltingSnow: '#8D8D8D', // check gray
  lightGrey: '#242424', // check gray
  mediumGrey: '#2D2C30',
  darkGrey: '#1B1B1B', // check gray
};
