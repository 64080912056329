declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer?: Record<string, any>[];
  }
}

export enum GtmEvent {
  init = 'init',
  gameImpression = 'gameImpression',
  gameClick = 'gameClick',
  gameDetail = 'gameDetail',
  accountFlow = 'accountFlow',
  menuUsed = 'menuUsed',
  requestScholarship = 'requestScholarship',
  playGame = 'playGame',
}

export enum GtmEventCategory {
  accountCreate = 'accountCreate',
  accountLogin = 'accountLogin',
  forgotPassword = 'forgotPassword',
  header = 'header',
  scholarship = 'scholarship',
  game = 'game',
}

export enum GtmEventAction {
  createAccountLinkClick = 'create_account_link_click',
  createAccount = 'create_account',
  completeProfile = 'complete_profile',
  successfulLogin = 'successful_login',
  forgotMyPasswordLinkClick = 'forgot_my_password_link_click',
  resetPassword = 'reset_password',
  requestButtonClick = 'request_button_click',
  scholarshipRequested = 'scholarship_requested',
  howToGetStarted = 'how_to_get_started',
}

export enum GtmStatus {
  notStarted = 'not started',
  inProgress = 'in progress',
  closed = 'closed',
}

interface GtmUserData {
  id: string;
  email: string;
  loggedIn: '0' | '1';
}

export interface GtmRewards {
  name: string;
  rewardLevel: number;
  entryRequirement: number;
  status: GtmStatus | string;
}

interface GtmPageData {
  type: string;
  language: string;
  country: string;
  rewards?: GtmRewards[];
}

interface GtmScholarshipData {
  requestedScholarships: string[];
  gamesToPlay: string[];
  rewardLevel: number;
  xpTotal: number;
}

export interface GtmGameData {
  id: string;
  name: string;
  price: number;
  tokens: string;
  category: string;
  platform: string;
  list?: string;
  position?: number;
}
interface DataLayerPage {
  event: GtmEvent.init;
  user: GtmUserData;
  page: GtmPageData;
  game: GtmScholarshipData;
}

interface DataLayerGames {
  event: GtmEvent.gameDetail | GtmEvent.gameImpression | GtmEvent.gameClick | GtmEvent.playGame;
  games: GtmGameData[];
}

interface DataLayerEvent {
  event:
    | GtmEvent.menuUsed
    | GtmEvent.requestScholarship
    | GtmEvent.gameClick
    | GtmEvent.gameImpression
    | GtmEvent.accountFlow
    | GtmEvent.playGame;
  eventCategory: GtmEventCategory;
  eventAction: GtmEventAction | string;
  eventLabel: string | undefined;
}

export const pushDataLayer = (dataLayer: DataLayerPage | DataLayerGames | DataLayerEvent) => {
  if (typeof window === 'undefined') return;

  window.dataLayer = window.dataLayer || [];

  const reversedDataLayer = [...window.dataLayer].reverse();
  const lastInitEvent = reversedDataLayer.find(({ event }) => event === GtmEvent.init);

  // This if statement is needed to prevent the init event firing twice
  if (dataLayer.event === GtmEvent.init && lastInitEvent?.page?.type === dataLayer.page.type)
    return;

  window.dataLayer.push(dataLayer);
};

export const handlePlayButtonClick = (
  eventLabel: string,
  eventAction:
    | 'installation guide'
    | 'launch'
    | 'download and play'
    | 'app store'
    | 'google play'
    | 'how to get started',
) =>
  pushDataLayer({
    event: GtmEvent.playGame,
    eventCategory: GtmEventCategory.game,
    eventAction,
    eventLabel,
  });
