import { HTMLChakraProps } from '@chakra-ui/react';

export enum IconName {
  arrowRight = 'ARROW_RIGHT',
  arrowRightFill = 'ARROW_RIGHT_FILL',
  arrowDown = 'ARROW_DOWN',
  close = 'CLOSE',
  discord = 'DISCORD',
  exclamationMark = 'EXCLAMATION_MARK',
  menu = 'MENU',
  meritCircleIcon = 'MERIT_CIRCLE_ICON',
  meritCircleLogo = 'MERIT_CIRCLE_LOGO',
  play = 'PLAY',
  search = 'SEARCH',
  appStore = 'APP_STORE',
  googlePlay = 'GOOGLE_PLAY',
  toastIcon = 'TOAST_ICON',
  checkMark = 'CHECK_MARK',
  chevronRight = 'CHEVRON_RIGHT',
  chevronLeft = 'CHEVRON_LEFT',
  lock = 'LOCK',
  youtube = 'YOUTUBE',
  twitter = 'TWITTER',
  telegram = 'TELEGRAM',
  web = 'WEB',
  link = 'LINK',
  starEmpty = 'STAR_EMPTY',
  starFilled = 'STAR_FILLED',
  gradients = 'GRADIENTS',
  beamLogo = 'BEAM_LOGO',
  beamLogoPlain = 'BEAM_LOGO_PLAIN',
  beamLogoPlainSmall = 'BEAM_LOGO_PLAIN_SMALL',
  beamAppIcon = 'BEAM_APP_ICON',
}

export interface IconProps extends HTMLChakraProps<'svg'> {
  icon: IconName;
  size?: number;
}
