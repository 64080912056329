import { findGraphqlError } from '@/utils/find-graphql-error';
import { ApplicationError } from '@meritcircle/types';
import { CombinedError } from 'urql';
import { REDIRECT_PATHS } from './redirect-paths';
import { BACKEND_URLS } from './urls';
import { NextUrqlPageContext } from 'next-urql';
import { getIsomorphicUrl, isRouteProtected } from '@/utils/urls';
import { FeatureFlags, mapFeatureFlags } from '@/utils/features';

/**
 * Returns URL to redirect to for a specific error.
 * Order is important, since first url will be redirected to, meaning for now unauthorized
 * should be on top.
 *
 * Even though this is a function, this is stored in the config directory, because
 * it contains configuration on where to redirect to if an error occurs.
 */
export const getRedirectUrlForError = (errors: CombinedError, ctx: NextUrqlPageContext) => {
  const hasUnverifiedEmail = findGraphqlError(errors, ApplicationError.UNVERIFIED_EMAIL);
  const isUnauthorized = findGraphqlError(errors, ApplicationError.UNAUTHORIZED);
  const hasMissingRequiredFields = findGraphqlError(
    errors,
    ApplicationError.MISSING_REQUIRED_FIELDS,
  );
  const url = getIsomorphicUrl(ctx?.req);
  const shouldAuthorize = mapFeatureFlags(
    FeatureFlags.AUTH,
    {
      OPTIONAL: () => isRouteProtected(url.pathname),
      REQUIRED: () => true,
    },
    'REQUIRED',
  );

  if (isUnauthorized && shouldAuthorize) {
    return BACKEND_URLS.LOGIN;
  }

  if (!isUnauthorized && hasUnverifiedEmail) {
    return REDIRECT_PATHS.VERIFY_EMAIL;
  }

  if (!isUnauthorized && hasMissingRequiredFields) {
    return REDIRECT_PATHS.ONBOARDING;
  }

  return null;
};
