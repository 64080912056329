/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { ToastsContextState } from './toasts.types';

export const defaultToastsContext: ToastsContextState = {
  toasts: [],
  addToast: () => {},
  removeToast: () => {},
};

export const ToastsContext = createContext<ToastsContextState>(defaultToastsContext);
