import { ComponentStyleConfig } from '@chakra-ui/react';
import { avatarAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleObject } from '@chakra-ui/theme-tools';

/**
 * Returns avatar size props based on a given size
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/avatar.ts
 * @param size
 * @returns
 */
const getSize = (size: string): PartsStyleObject<typeof parts> => ({
  container: {
    width: size,
    height: size,
    fontSize: `calc(${size} / 2.5)`,
  },
  excessLabel: {
    width: size,
    height: size,
  },
  label: {
    fontSize: `calc(${size} / 2.5)`,
    lineHeight: size !== '100%' ? size : undefined,
  },
});

export const avatar: ComponentStyleConfig = {
  baseStyle: {
    container: {
      color: 'black',
      background: 'lightPurple',
    },
  },
  sizes: {
    // The regular 'md' size is too large, so modified to the value below
    md: getSize('2.5rem'),
    // custom size for review avatar
    'size.48': getSize('3rem'),
  },
};
