import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={1000}
      height={16}
      fill="none"
      viewBox="0 0 1000 16"
      ref={svgRef}
      {...props}>
      <path fill="url(#paint0_linear_17526_12781)" d="M0 4.999h1000v3H0v-3z" />
      <path fill="url(#paint1_linear_17526_12781)" d="M0 2h999.992v3H0V2z" />
      <path fill="url(#paint2_linear_17526_12781)" d="M0 11h1000v3H0v-3z" />
      <path fill="url(#paint3_linear_17526_12781)" d="M0 8.002h1000v3H0v-3z" />
      <defs>
        <linearGradient
          id="paint0_linear_17526_12781"
          x1={497}
          x2={497}
          y1={6.57}
          y2={9.92}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF6B6B" />
          <stop offset={0.33} stopColor="#FE1414" />
          <stop offset={0.6} stopColor="#8E0900" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17526_12781"
          x1={547.52}
          x2={547.52}
          y1={3.57}
          y2={6.92}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BBDBFF" />
          <stop offset={0.33} stopColor="#139EDD" />
          <stop offset={0.6} stopColor="#B9F7EA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_17526_12781"
          x1={500.02}
          x2={500.02}
          y1={12.57}
          y2={15.92}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BBFFCA" />
          <stop offset={0.33} stopColor="#48DD13" />
          <stop offset={0.6} stopColor="#008805" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_17526_12781"
          x1={500.02}
          x2={500.02}
          y1={9.58}
          y2={12.92}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F1E869" />
          <stop offset={0.33} stopColor="#FEA514" />
          <stop offset={0.6} stopColor="#FF4539" />
        </linearGradient>
      </defs>
    </svg>
  );
});
const BeamLogoRainbowSmallIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default BeamLogoRainbowSmallIcon;
