import { Heading, Badge, Box } from '@chakra-ui/react';
import { ProfileSectionProps } from './profile-section.types';

export const ProfileSection = ({ badge, title, children }: ProfileSectionProps) => {
  return (
    <Box color="muted" bg="darkGrey" borderRadius="2xl">
      <Box p="xl">
        {badge && (
          <Badge bg="gray.700" color="muted" px="xs" textTransform="none" mb="md">
            {badge}
          </Badge>
        )}
        <Heading color="white" as="h3" size="md">
          {title}
        </Heading>
      </Box>
      {children}
    </Box>
  );
};
