import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={89}
      height={16}
      fill="none"
      viewBox="0 0 89 16"
      ref={svgRef}
      {...props}>
      <path
        fill="#F1F1F5"
        d="M0 13.715h1.878v-1.06C2.386 13.24 3.109 14 4.756 14c3.125 0 4.248-2.96 4.248-4.718 0-2.327-1.693-4.654-4.294-4.654-.954 0-1.955.364-2.663 1.092V2H0v11.715zm1.924-4.512c0-1.282.97-2.644 2.54-2.644 1.292 0 2.493 1.03 2.493 2.74 0 1.709-1.124 2.77-2.463 2.77-1.2 0-2.57-.919-2.57-2.866zm15.962 1.077c.06-.285.122-.647.122-1.057 0-2.207-1.603-4.556-4.38-4.556-2.687 0-4.457 2.223-4.457 4.698 0 2.554 1.892 4.635 4.472 4.635 2 0 3.602-1.466 4.09-3.043h-2.137c-.381.725-1.053 1.12-1.953 1.12-1.45 0-2.244-1.23-2.336-1.798h6.579zm-6.64-1.688c.382-1.624 1.572-2.002 2.397-2.002 1.083 0 2.06.6 2.29 2.002h-4.687zm16.099-3.626h-1.874V6.07h-.031c-.062-.126-1.091-1.403-2.828-1.403-2.366 0-4.271 1.939-4.271 4.619 0 2.917 1.951 4.714 4.148 4.714 1.076 0 2.336-.584 2.982-1.372v1.088h1.874v-8.75zM25.41 9.317c0 1.624-1.075 2.76-2.49 2.76-1.459 0-2.534-1.214-2.534-2.696 0-1.309.86-2.791 2.535-2.791 1.214 0 2.489.914 2.489 2.727zm2.769 4.349h2.023V9.323c0-.925 0-2.743 1.613-2.743 1.247 0 1.689 1.066 1.689 2.257v4.83h2.023V9.322c0-.548-.015-1.41.304-1.928a1.637 1.637 0 011.4-.815c1.597 0 1.597 1.756 1.597 2.257v4.83h2.023v-5.08c0-1.286-.304-2.117-.638-2.572-.305-.439-1.126-1.348-2.784-1.348-.594 0-1.841.062-2.724 1.364a2.819 2.819 0 00-2.434-1.364c-1.46 0-2.038.752-2.205 1.034h-.03v-.736h-1.857v8.701z"
      />
      <g filter="url(#filter0_ddi_17526_12783)">
        <path
          fill="#E8BBFB"
          fillRule="evenodd"
          d="M41.945 2.73L44.134 2v5.44l8.357-2.611a2.553 2.553 0 013.315 2.436v6.402h-2.189V6.9a.365.365 0 00-.473-.348l-9.01 2.815v4.3h-2.189V2.729z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#filter1_dd_17526_12783)">
        <path
          fill="#E8BBFB"
          fillRule="evenodd"
          d="M71.854 10.02V2.73L74.042 2v5.44L82.4 4.828a2.553 2.553 0 013.314 2.436v3.85a2.553 2.553 0 01-2.553 2.552H71.124l.73-1.823H83.16a.365.365 0 00.365-.365V6.9a.365.365 0 00-.474-.348l-8.83 2.759-2.368.71z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#filter2_dd_17526_12783)">
        <path
          fill="#E8BBFB"
          fillRule="evenodd"
          d="M68.206 11.844a.365.365 0 00.365-.365V3.823l2.189.73v6.562a2.553 2.553 0 01-2.554 2.552h-8.754a2.553 2.553 0 01-2.553-2.552V4.552l2.188-.729v7.656c0 .202.164.365.365.365h8.754z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <filter
          id="filter0_ddi_17526_12783"
          width={21.86}
          height={19.67}
          x={37.95}
          y={-2}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.207357 0 0 0 0 0.462705 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_17526_12783" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.270588 0 0 0 0 0.223529 0 0 0 0.4 0" />
          <feBlend in2="effect1_dropShadow_17526_12783" result="effect2_dropShadow_17526_12783" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_17526_12783" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend in2="shape" result="effect3_innerShadow_17526_12783" />
        </filter>
        <filter
          id="filter1_dd_17526_12783"
          width={22.59}
          height={19.67}
          x={67.12}
          y={-2}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.207357 0 0 0 0 0.462705 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_17526_12783" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.270588 0 0 0 0 0.223529 0 0 0 0.4 0" />
          <feBlend in2="effect1_dropShadow_17526_12783" result="effect2_dropShadow_17526_12783" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_17526_12783" result="shape" />
        </filter>
        <filter
          id="filter2_dd_17526_12783"
          width={21.86}
          height={17.84}
          x={52.9}
          y={-0.18}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.207357 0 0 0 0 0.462705 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_17526_12783" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.270588 0 0 0 0 0.223529 0 0 0 0.4 0" />
          <feBlend in2="effect1_dropShadow_17526_12783" result="effect2_dropShadow_17526_12783" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_17526_12783" result="shape" />
        </filter>
      </defs>
    </svg>
  );
});
const BeamLogoPlainSmallIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default BeamLogoPlainSmallIcon;
