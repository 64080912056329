import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={250}
      height={40}
      fill="none"
      viewBox="0 0 250 40"
      ref={svgRef}
      {...props}>
      <g clipPath="url(#clip0_17039_18742)">
        <path fill="url(#paint0_linear_17039_18742)" d="M-230 13.941H82.348v5.781H-230v-5.78z" />
        <path fill="url(#paint1_linear_17039_18742)" d="M-230 8.164H82.345v5.78H-230v-5.78z" />
        <path fill="url(#paint2_linear_17039_18742)" d="M-230 25.503H82.348v5.781H-230v-5.78z" />
        <path fill="url(#paint3_linear_17039_18742)" d="M-230 19.727H82.348v5.78H-230v-5.78z" />
        <path
          fill="#F1F1F5"
          d="M81 31.274h3.703v-2.092c1.001 1.155 2.427 2.654 5.675 2.654 6.16 0 8.375-5.84 8.375-9.306 0-4.59-3.338-9.18-8.466-9.18-1.882 0-3.855.718-5.25 2.154V8.166H81v23.108zm3.794-8.9c0-2.53 1.912-5.215 5.007-5.215 2.55 0 4.916 2.03 4.916 5.402 0 3.373-2.215 5.465-4.855 5.465-2.367 0-5.068-1.81-5.068-5.652zm31.471 2.123c.12-.56.241-1.275.241-2.084 0-4.354-3.16-8.987-8.637-8.987-5.296 0-8.787 4.385-8.787 9.267 0 5.038 3.731 9.143 8.817 9.143 3.942 0 7.102-2.892 8.065-6.002h-4.213c-.752 1.43-2.077 2.208-3.852 2.208-2.859 0-4.424-2.426-4.604-3.545h12.97zm-13.091-3.328c.753-3.203 3.1-3.95 4.725-3.95 2.137 0 4.063 1.182 4.514 3.95h-9.239zm31.742-7.152h-3.696v2.176h-.061c-.121-.248-2.151-2.767-5.574-2.767-4.665 0-8.422 3.825-8.422 9.112 0 5.753 3.847 9.298 8.179 9.298 2.121 0 4.605-1.15 5.878-2.706v2.146h3.696v-17.26zm-3.818 8.583c0 3.203-2.12 5.442-4.907 5.442-2.878 0-4.999-2.395-4.999-5.318 0-2.581 1.697-5.504 4.999-5.504 2.393 0 4.907 1.803 4.907 5.38zm5.462 8.578h3.99v-8.567c0-1.824 0-5.412 3.179-5.412 2.46 0 3.33 2.103 3.33 4.453v9.526h3.989v-8.567c0-1.082-.031-2.783.6-3.804a3.226 3.226 0 012.759-1.608c3.149 0 3.149 3.464 3.149 4.453v9.526h3.99v-10.02c0-2.536-.6-4.176-1.26-5.073-.6-.866-2.22-2.66-5.489-2.66-1.17 0-3.629.124-5.369 2.691-1.56-2.474-3.899-2.69-4.799-2.69-2.88 0-4.019 1.484-4.349 2.04h-.06v-1.453h-3.66v17.165z"
        />
        <g filter="url(#filter0_ddi_17039_18742)">
          <path
            fill="#E8BBFB"
            fillRule="evenodd"
            d="M163.703 9.604l4.315-1.438v10.729l16.477-5.15c3.241-1.012 6.535 1.41 6.535 4.806v12.627h-4.315V17.832a.719.719 0 00-.933-.687l-17.764 5.552v8.481h-4.315V9.604z"
            clipRule="evenodd"
          />
        </g>
        <g filter="url(#filter1_dd_17039_18742)">
          <path
            fill="#E8BBFB"
            fillRule="evenodd"
            d="M222.673 23.987V9.604l4.315-1.438v10.729l16.477-5.15c3.242-1.012 6.536 1.41 6.536 4.806v7.593a5.034 5.034 0 01-5.034 5.034h-23.732l1.438-3.595h22.294a.72.72 0 00.719-.72v-9.031a.72.72 0 00-.934-.687l-17.411 5.441-4.668 1.4z"
            clipRule="evenodd"
          />
        </g>
        <g filter="url(#filter2_dd_17039_18742)">
          <path
            fill="#E8BBFB"
            fillRule="evenodd"
            d="M215.481 27.583a.719.719 0 00.719-.72V11.762l4.315 1.439v12.944a5.034 5.034 0 01-5.034 5.035h-17.259a5.034 5.034 0 01-5.034-5.035V13.2l4.315-1.439v15.103a.72.72 0 00.719.719h17.259z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_17039_18742"
          x1={-74.76}
          x2={-74.76}
          y1={16.97}
          y2={23.42}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF6B6B" />
          <stop offset={0.33} stopColor="#FE1414" />
          <stop offset={0.6} stopColor="#8E0900" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17039_18742"
          x1={-58.98}
          x2={-58.98}
          y1={11.2}
          y2={17.64}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BBDBFF" />
          <stop offset={0.33} stopColor="#139EDD" />
          <stop offset={0.6} stopColor="#B9F7EA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_17039_18742"
          x1={-73.82}
          x2={-73.82}
          y1={28.54}
          y2={34.98}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BBFFCA" />
          <stop offset={0.33} stopColor="#48DD13" />
          <stop offset={0.6} stopColor="#008805" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_17039_18742"
          x1={-73.82}
          x2={-73.82}
          y1={22.76}
          y2={29.21}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F1E869" />
          <stop offset={0.33} stopColor="#FEA514" />
          <stop offset={0.6} stopColor="#FF4539" />
        </linearGradient>
        <filter
          id="filter0_ddi_17039_18742"
          width={56.09}
          height={51.78}
          x={149.32}
          y={-6.22}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1.8} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.207357 0 0 0 0 0.462705 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_17039_18742" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={7.19} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.270588 0 0 0 0 0.223529 0 0 0 0.4 0" />
          <feBlend in2="effect1_dropShadow_17039_18742" result="effect2_dropShadow_17039_18742" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_17039_18742" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1.44} />
          <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend in2="shape" result="effect3_innerShadow_17039_18742" />
        </filter>
        <filter
          id="filter1_dd_17039_18742"
          width={57.53}
          height={51.78}
          x={206.85}
          y={-6.22}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1.8} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.207357 0 0 0 0 0.462705 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_17039_18742" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={7.19} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.270588 0 0 0 0 0.223529 0 0 0 0.4 0" />
          <feBlend in2="effect1_dropShadow_17039_18742" result="effect2_dropShadow_17039_18742" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_17039_18742" result="shape" />
        </filter>
        <filter
          id="filter2_dd_17039_18742"
          width={56.09}
          height={48.18}
          x={178.81}
          y={-2.62}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1.8} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.207357 0 0 0 0 0.462705 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_17039_18742" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={7.19} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.270588 0 0 0 0 0.223529 0 0 0 0.4 0" />
          <feBlend in2="effect1_dropShadow_17039_18742" result="effect2_dropShadow_17039_18742" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_17039_18742" result="shape" />
        </filter>
        <clipPath id="clip0_17039_18742">
          <path fill="#fff" d="M0 0h250v40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});
const BeamLogoIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default BeamLogoIcon;
