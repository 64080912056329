import { ComponentStyleConfig } from '@chakra-ui/react';
export const text: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: '400',
  },
  sizes: {
    // 18x
    base: { fontSize: '1.125rem', lineHeight: 1.4 },
    // 16x
    md: { fontSize: '1rem', lineHeight: 1.4 },
    // 14px
    sm: { fontSize: '0.875rem', lineHeight: 1.4 },
    // 12px
    xs: { fontSize: '0.75rem', lineHeight: 1.2 },
  },
  variants: {
    muted: {
      color: 'desiredDawn',
    },
    white: {
      color: 'white',
    },
  },
};
