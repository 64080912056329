import { Box, Flex, FlexProps } from '@chakra-ui/react';
import { gradients } from '../../theme';

export const BlurFlex = ({ children, borderRadius = 4, _before, ...props }: FlexProps) => (
  <Flex
    px={{ base: 2, md: 4 }}
    py={2}
    backdropFilter="blur(32px)"
    background={gradients.lightBlueFill}
    borderRadius={borderRadius}
    position="relative"
    gap="sm"
    alignItems="center"
    _before={{
      content: '""',
      position: 'absolute',
      left: 0,
      width: '100%',
      height: '100%',
      inset: 0,
      border: '1px solid transparent',
      borderRadius: 4,
      background: gradients.lightBlueStroke,
      WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
      zIndex: '-1',
      ..._before,
    }}
    {...props}>
    {children}
  </Flex>
);
