import { CombinedError } from 'urql';
import * as Sentry from '@sentry/nextjs';

export interface GraphqlErrorResponse {
  message?: string | string[];
  statusCode?: number;
  error?: string;
}

export const findGraphqlError = (
  errors: CombinedError,
  errorMessage: string,
): GraphqlErrorResponse | undefined => {
  Sentry.captureException(errors);
  return errors.graphQLErrors?.find(
    error => (error.extensions?.response as GraphqlErrorResponse)?.message === errorMessage,
  )?.extensions?.response as GraphqlErrorResponse | undefined;
};
