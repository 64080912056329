import { ComponentStyleConfig } from '@chakra-ui/react';

export const link: ComponentStyleConfig = {
  baseStyle: {
    textDecoration: 'underline',
  },
  variants: {
    none: {
      textDecoration: 'none',
      _hover: {
        textDecoration: 'none',
      },
    },
  },
};
