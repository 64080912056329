import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
      ref={svgRef}
      {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.997 13.12a.773.773 0 01-.685.848l-.302.029a.764.764 0 01-.825-.704l-.029-.308c0-.01.002-.02 0-.031-.405-3.606-3.514-6.242-7.046-5.96l1.422 1.53c.292.303.29.79-.005 1.09l-.212.216a.737.737 0 01-1.062-.005l-3.008-3.24-.028-.03A.773.773 0 012 6.009a.784.784 0 01.222-.546l.03-.03 3.036-3.21a.737.737 0 011.062.005l.21.218c.292.303.29.79-.005 1.09L5.063 5.112c4.5-.318 8.449 3.084 8.91 7.703l-.004.002.028.304z"
        clipRule="evenodd"
      />
    </svg>
  );
});
const UndoIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default UndoIcon;
