import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={17}
      fill="none"
      viewBox="0 0 24 17"
      ref={svgRef}
      {...props}>
      <path
        fill="currentColor"
        d="M17.712 17s-.74-.87-1.356-1.64c2.692-.748 3.72-2.408 3.72-2.408-.843.547-1.645.931-2.364 1.194a13.678 13.678 0 01-2.98.87c-1.972.365-3.78.264-5.321-.02a17.447 17.447 0 01-3.02-.87 12.126 12.126 0 01-1.5-.688c-.062-.04-.124-.06-.186-.101a.281.281 0 01-.082-.06c-.37-.203-.575-.345-.575-.345s.986 1.62 3.596 2.388C7.027 16.09 6.267 17 6.267 17 1.726 16.858 0 13.924 0 13.924 0 7.407 2.959 2.125 2.959 2.125 5.918-.06 8.733 0 8.733 0l.205.243C5.24 1.295 3.534 2.894 3.534 2.894s.452-.243 1.213-.587c2.198-.95 3.945-1.214 4.664-1.275.123-.02.226-.04.35-.04a17.65 17.65 0 014.15-.04 16.93 16.93 0 016.185 1.942s-1.623-1.518-5.117-2.57L15.267 0s2.815-.06 5.774 2.125c0 0 2.959 5.282 2.959 11.799 0 0-1.747 2.934-6.288 3.076zM8.158 7.549c-1.172 0-2.096 1.012-2.096 2.246 0 1.235.945 2.247 2.096 2.247 1.17 0 2.095-1.012 2.095-2.247.021-1.234-.924-2.246-2.095-2.246zm7.5 0c-1.172 0-2.096 1.012-2.096 2.246 0 1.235.945 2.247 2.096 2.247 1.17 0 2.095-1.012 2.095-2.247 0-1.234-.924-2.246-2.095-2.246z"
      />
    </svg>
  );
});
const DiscordIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default DiscordIcon;
