import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg width={0} height={0} ref={svgRef} {...props}>
      <defs>
        <linearGradient
          id="paint0_linear_10070_94053"
          x1={10.5}
          x2={11.24}
          y1={23.82}
          y2={47.64}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EF9146" />
          <stop offset={1} stopColor="#E0592E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10070_94053"
          x1={24}
          x2={24}
          y1={23.82}
          y2={47.64}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E57722" />
          <stop offset={1} stopColor="#F2B24C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10070_94053"
          x1={35.23}
          x2={35.23}
          y1={23.82}
          y2={-1.01}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E57722" />
          <stop offset={1} stopColor="#DD4A33" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_10070_94053"
          x1={9.75}
          x2={9.75}
          y1={23.82}
          y2={0}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EF9046" />
          <stop offset={1} stopColor="#F3C44F" />
        </linearGradient>
        <linearGradient
          id="paint0_linear_10688_141981"
          x1={5}
          x2={5}
          y1={0.5}
          y2={10.5}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F2B24C" />
          <stop offset={1} stopColor="#E57722" />
        </linearGradient>
        <linearGradient
          id="a"
          x1={8.75}
          x2={9.35}
          y1={20.01}
          y2={39.57}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EF9146" />
          <stop offset={1} stopColor="#E0592E" />
        </linearGradient>
        <linearGradient id="b" x1={20} x2={20} y1={20.01} y2={39.57} gradientUnits="userSpaceOnUse">
          <stop stopColor="#E57722" />
          <stop offset={1} stopColor="#F2B24C" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={29.36}
          x2={29.36}
          y1={20.01}
          y2={-0.38}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E57722" />
          <stop offset={1} stopColor="#DD4A33" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={8.13}
          x2={8.13}
          y1={20.01}
          y2={0.45}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EF9046" />
          <stop offset={1} stopColor="#F3C44F" />
        </linearGradient>
        <linearGradient
          id="paint0_linear_11723_169634"
          x1={3.33}
          x2={13.8}
          y1={0}
          y2={1.51}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EC8741" />
          <stop offset={1} stopColor="#F2AC4B" />
        </linearGradient>
        <linearGradient
          id="paint0_linear_12559_47533"
          x1={31.53}
          x2={31.53}
          y1={20}
          y2={39.78}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EF9046" />
          <stop offset={1} stopColor="#F3C44F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12559_47533"
          x1={37.76}
          x2={123.24}
          y1={21.02}
          y2={77.84}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDC80C" />
          <stop offset={0.12} stopColor="#F90" />
          <stop offset={0.24} stopColor="#FF682D" />
          <stop offset={0.42} stopColor="#F5DF15" />
          <stop offset={0.59} stopColor="#FF8521" />
          <stop offset={0.74} stopColor="#FFD8C1" />
          <stop offset={0.87} stopColor="#FFF7DC" />
          <stop offset={1} stopColor="#FDC80C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12559_47533"
          x1={37.76}
          x2={123.24}
          y1={21.02}
          y2={77.84}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDC80C" />
          <stop offset={0.12} stopColor="#F90" />
          <stop offset={0.24} stopColor="#FF682D" />
          <stop offset={0.42} stopColor="#F5DF15" />
          <stop offset={0.59} stopColor="#FF8521" />
          <stop offset={0.74} stopColor="#FFD8C1" />
          <stop offset={0.87} stopColor="#FFF7DC" />
          <stop offset={1} stopColor="#FDC80C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_12559_47533"
          x1={37.76}
          x2={123.24}
          y1={21.02}
          y2={77.84}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDC80C" />
          <stop offset={0.12} stopColor="#F90" />
          <stop offset={0.24} stopColor="#FF682D" />
          <stop offset={0.42} stopColor="#F5DF15" />
          <stop offset={0.59} stopColor="#FF8521" />
          <stop offset={0.74} stopColor="#FFD8C1" />
          <stop offset={0.87} stopColor="#FFF7DC" />
          <stop offset={1} stopColor="#FDC80C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_12559_47533"
          x1={37.76}
          x2={123.24}
          y1={21.02}
          y2={77.84}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDC80C" />
          <stop offset={0.12} stopColor="#F90" />
          <stop offset={0.24} stopColor="#FF682D" />
          <stop offset={0.42} stopColor="#F5DF15" />
          <stop offset={0.59} stopColor="#FF8521" />
          <stop offset={0.74} stopColor="#FFD8C1" />
          <stop offset={0.87} stopColor="#FFF7DC" />
          <stop offset={1} stopColor="#FDC80C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_12559_47533"
          x1={37.76}
          x2={123.24}
          y1={21.02}
          y2={77.84}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDC80C" />
          <stop offset={0.12} stopColor="#F90" />
          <stop offset={0.24} stopColor="#FF682D" />
          <stop offset={0.42} stopColor="#F5DF15" />
          <stop offset={0.59} stopColor="#FF8521" />
          <stop offset={0.74} stopColor="#FFD8C1" />
          <stop offset={0.87} stopColor="#FFF7DC" />
          <stop offset={1} stopColor="#FDC80C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_12559_47533"
          x1={37.76}
          x2={123.24}
          y1={21.02}
          y2={77.84}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDC80C" />
          <stop offset={0.12} stopColor="#F90" />
          <stop offset={0.24} stopColor="#FF682D" />
          <stop offset={0.42} stopColor="#F5DF15" />
          <stop offset={0.59} stopColor="#FF8521" />
          <stop offset={0.74} stopColor="#FFD8C1" />
          <stop offset={0.87} stopColor="#FFF7DC" />
          <stop offset={1} stopColor="#FDC80C" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_12559_47533"
          x1={8.66}
          x2={9.27}
          y1={19.78}
          y2={39.57}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EF9146" />
          <stop offset={1} stopColor="#E0592E" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_12559_47533"
          x1={19.78}
          x2={19.78}
          y1={19.78}
          y2={39.57}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E57722" />
          <stop offset={1} stopColor="#F2B24C" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_12559_47533"
          x1={29.04}
          x2={29.04}
          y1={19.78}
          y2={-0.84}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E57722" />
          <stop offset={1} stopColor="#DD4A33" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_12559_47533"
          x1={8.04}
          x2={8.04}
          y1={19.78}
          y2={0}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EF9046" />
          <stop offset={1} stopColor="#F3C44F" />
        </linearGradient>
        <linearGradient
          id="paint0_linear_16119_54178"
          x1={6.9}
          x2={21.25}
          y1={0.66}
          y2={3.83}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9BFFB" />
          <stop offset={1} stopColor="#E8BBFB" />
        </linearGradient>
        <linearGradient
          id="paint0_linear_16119_54163"
          x1={8.18}
          x2={18.94}
          y1={3.49}
          y2={5.87}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9BFFB" />
          <stop offset={1} stopColor="#E8BBFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16119_54163"
          x1={6.9}
          x2={21.25}
          y1={0.66}
          y2={3.83}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9BFFB" />
          <stop offset={1} stopColor="#E8BBFB" />
        </linearGradient>
        <linearGradient
          id="paint0_linear_16119_54178"
          x1={6.9}
          x2={21.25}
          y1={0.66}
          y2={3.83}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9BFFB" />
          <stop offset={1} stopColor="#E8BBFB" />
        </linearGradient>
        <linearGradient
          id="paint0_linear_16119_62435"
          x1={6.9}
          x2={21.25}
          y1={0.66}
          y2={3.83}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9BFFB" />
          <stop offset={1} stopColor="#E8BBFB" />
        </linearGradient>
        <linearGradient
          id="paint0_linear_16046_98636"
          x1={8.18}
          x2={18.94}
          y1={3.49}
          y2={5.87}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9BFFB" />
          <stop offset={1} stopColor="#E8BBFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16046_98636"
          x1={6.9}
          x2={21.25}
          y1={0.66}
          y2={3.83}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9BFFB" />
          <stop offset={1} stopColor="#E8BBFB" />
        </linearGradient>
        <linearGradient
          id="paint0_linear_13734_90670"
          x1={9.21}
          x2={28.33}
          y1={0.88}
          y2={5.11}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9BFFB" />
          <stop offset={1} stopColor="#E8BBFB" />
        </linearGradient>
      </defs>
    </svg>
  );
});
const GradientsIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default GradientsIcon;
