import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Lazy, A11y, FreeMode } from 'swiper';
import { useWindowSize, useElementSize } from '@reactuses/core';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Box, Container, Flex } from '@chakra-ui/react';
import React from 'react';
import 'swiper/css';
import { CarouselProps } from './carousel.types';
import { CarouselButtons } from './carousel-buttons';
import { CarouselIndicator } from './carousel-indicator';
import { TitleSection } from '../title-section/title-section';
import { TextLink } from '../text-link/text-link';
import SwiperClass from 'swiper/types/swiper-class';

export const Carousel = React.forwardRef<HTMLDivElement, PropsWithChildren<CarouselProps>>(
  (
    {
      children,
      title,
      description,
      textLink,
      headingProps,
      slidesPerView,
      slidesPerGroup,
      footerSlot,
      wrapperProps,
    },
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [swiper, setSwiper] = useState<SwiperClass | null>(null);
    const [indicatorRef, setIndicatorRef] = useState<HTMLDivElement | null>(null);

    return (
      <Box overflow="hidden" ref={ref} {...wrapperProps}>
        <TitleSection
          title={title}
          headingSize="h3"
          headingVariant="white"
          description={description}
          actionSlot={
            <Flex alignItems="center">
              <CarouselIndicator
                ref={setIndicatorRef}
                mr={textLink ? 'lg' : undefined}
                display={{ base: 'none', md: 'inline-flex' }}
              />
              {textLink && <TextLink {...textLink} />}
            </Flex>
          }
          headingProps={headingProps}
        />
        <Box position="relative">
          <Container ref={containerRef}>
            <Box sx={{ '.swiper': { overflow: 'visible', position: 'unset' } }}>
              <Swiper
                slidesPerView={slidesPerView}
                slidesPerGroup={slidesPerGroup}
                spaceBetween={15}
                lazy
                preloadImages={false}
                modules={[Pagination, Lazy, A11y, FreeMode]}
                navigation
                onSwiper={setSwiper}
                pagination={{
                  el: indicatorRef,
                  clickable: true,
                  bulletClass: 'indicator__bullet',
                  bulletActiveClass: 'indicator__bullet--active',
                }}
                breakpoints={{
                  0: {
                    freeMode: { enabled: true, sticky: false },
                  },
                  [768]: {
                    freeMode: false,
                  },
                }}>
                {swiper && <CarouselButtons swiper={swiper} />}
                {React.Children.map(children, (child, index) => (
                  <SwiperSlide key={index}>{child}</SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Container>
          {footerSlot}
        </Box>
      </Box>
    );
  },
);
