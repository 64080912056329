import { UserScholarshipsQuery } from '@/client/graphql/scholarships.generated';
import { CurrentUserQuery } from '@/client/graphql/users.generated';
import { Dispatch } from 'react';

interface UserRank {
  level: number;
  minXp: number;
  maxXp: number;
}

export interface CurrentUser extends Omit<CurrentUserQuery['currentUser'], '__typename'> {
  scholarships?: UserScholarshipsQuery['userScholarships'];
  addedXp?: number;
  isLoggedIn: boolean;
  userLoggedInBefore?: boolean;
  fetchingUserData?: boolean;
  rank: UserRank;
  updateTasks?: () => void;
  updateState?: Dispatch<SetUserDataAction>;
}

export enum ActionTypes {
  SET_USER_DATA,
}

interface UserDataPayload {
  xp?: CurrentUser['xp'];
  addedXp?: CurrentUser['addedXp'];
  scholarships?: CurrentUser['scholarships'];
  isLoggedIn?: CurrentUser['isLoggedIn'];
  userLoggedInBefore?: CurrentUser['userLoggedInBefore'];
  fetchingUserData?: CurrentUser['fetchingUserData'];
  rank?: CurrentUser['rank'];
  level?: CurrentUser['level'];
}

export interface SetUserDataAction {
  type: ActionTypes.SET_USER_DATA;
  payload: UserDataPayload;
}
