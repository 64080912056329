import { Container, Flex, Box } from '@chakra-ui/react';
import { Icon, IconName } from '@meritcircle/ui/components';
import { PropsWithChildren } from 'react';

export const BasePageLayout = ({ children }: PropsWithChildren) => (
  <Box position="relative" minH="100vh" bgColor="gray.900">
    <Container position="relative">
      <Flex as="header" py={25}>
        <Icon icon={IconName.beamLogo} />
      </Flex>
      {children}
    </Container>
  </Box>
);
