import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      fill="none"
      viewBox="0 0 32 32"
      ref={svgRef}
      {...props}>
      <path
        stroke="#D8D8D8"
        d="M10.387 9.76l.3-.029.115-.279 3.083-7.492C14.254 1.066 15.091.5 16 .5c.91 0 1.746.566 2.115 1.46l3.083 7.492.115.28.3.028 7.79.74h0c.908.087 1.693.73 1.982 1.659h0c.29.93.019 1.947-.678 2.582h0l-5.886 5.371-.213.195.062.281 1.732 7.95s0 0 0 0c.208.956-.153 1.941-.9 2.504v.001a2.21 2.21 0 01-2.517.12h0l-6.721-4.173-.264-.164-.264.164-6.72 4.174h0a2.21 2.21 0 01-2.518-.12v-.002c-.747-.563-1.108-1.548-.9-2.505l1.732-7.949.061-.281-.212-.195-5.885-5.371h0c-.698-.635-.97-1.652-.679-2.582h0c.289-.929 1.074-1.572 1.982-1.658h0l7.79-.741z"
      />
    </svg>
  );
});
const StarEmptyIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default StarEmptyIcon;
