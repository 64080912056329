import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      ref={svgRef}
      {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 7.3C4 6.582 4.448 6 5 6h14c.552 0 1 .582 1 1.3 0 .718-.448 1.3-1 1.3H5c-.552 0-1-.582-1-1.3zm2.286 5.2c0-.718.448-1.3 1-1.3h9.429c.552 0 1 .582 1 1.3 0 .718-.448 1.3-1 1.3h-9.43c-.551 0-1-.582-1-1.3zm2.285 5.2c0-.718.448-1.3 1-1.3h4.857c.553 0 1 .582 1 1.3 0 .718-.447 1.3-1 1.3H9.572c-.552 0-1-.582-1-1.3z"
        clipRule="evenodd"
      />
    </svg>
  );
});
const BarsFilerIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default BarsFilerIcon;
