export const isExternalUrl = (url: string): boolean => {
  if (
    !/^http/.test(url) ||
    !process.env.SITE_URL ||
    (process.env.NEXT_PUBLIC_BACKEND_BASE && url.includes(process.env.NEXT_PUBLIC_BACKEND_BASE))
  ) {
    return false;
  }

  const requestedUrl = new URL(url);
  const siteUrl = new URL(process.env.SITE_URL);
  const isExternal = requestedUrl.hostname !== siteUrl.hostname;

  return isExternal;
};

export const getPlaceholderUrl = (url: string): string => {
  const fileExtension = url.split('.').pop();

  if (!fileExtension) return url;

  const imagePath = new URL(url);

  return `${imagePath.origin}${imagePath.pathname}?w=30`;
};
