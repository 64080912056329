import { ComponentStyleConfig, keyframes, theme, withDefaultSize } from '@chakra-ui/react';
import { colors, effects, gradients } from '../colors';
import { keyboardFocused } from '../tools/keyboard-focused';
import { link } from './link';

const primaryBackgroundStyle = 'lightPurple';

const secondaryBackgroundStyle = 'lightGrey';

const backgroundSpinAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const backgroundAnimationProps = (background: string) => ({
  background,
  // The background needs to be oversized for the spin animation to work
  backgroundSize: '200% 200%',
  // Animation is applied to both background gradient images (border and background)
  animation: `${backgroundSpinAnimation} 5s ease infinite`,
  // Animation is cancelled on disabled buttons
  _disabled: {
    background,
    animation: 'none',
    boxShadow: 'none',
  },
  _hover: {
    background: gradients.arcticBlueFill,
  },
  _active: {
    opacity: 0.6,
  },
});

// TODO: follow component library to update all states

export const button: ComponentStyleConfig = {
  baseStyle: {
    color: 'white',
    fontWeight: 'normal',
    borderRadius: 'full',
    ...keyboardFocused({
      boxShadow: 'outline',
    }),
    textDecoration: 'none',
    _hover: {
      textDecoration: 'none',
    },
  },
  defaultProps: {
    variant: 'secondary',
    size: 'md',
  },
  variants: {
    primary: {
      ...backgroundAnimationProps(primaryBackgroundStyle),
      boxShadow: effects.buttonGlow,
      color: 'buttonFontColor',
      _hover: {
        background: 'polarBear',
        _disabled: {
          background: 'desiredDawn',
        },
      },
      _disabled: {
        background: 'desiredDawn',
        color: 'white',
      },
    },
    secondary: {
      ...backgroundAnimationProps(secondaryBackgroundStyle),
      boxShadow: '0px -2px 2px 0px rgba(255, 255, 255, 0.07) inset',
      _hover: {
        color: 'lightPurple',
        _disabled: {
          color: 'desiredDawn',
        },
      },
    },
    outline: {
      borderWidth: '1px',
      borderColor: 'white',
      boxShadow: effects.buttonGlow,
      _hover: {
        borderColor: 'lightPurple',
        color: 'lightPurple',
        backgroundColor: 'transparent',
      },
      _active: {
        backgroundColor: 'transparent',
        opacity: 0.6,
      },
      _disabled: {
        _hover: {
          borderColor: 'white',
        },
      },
    },
    glass: {
      position: 'relative',
      background: 'rgba(216, 216, 216, 0.08)',
      _before: {
        content: '""',
        inset: 0,
        position: 'absolute',
        padding: '1px',
        borderRadius: 'inherit',
        background: gradients.lightBlueStroke,
        mask: `linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)`,
        maskComposite: 'exclude',
        WebkitMaskComposite: 'xor',
      },
    },
    link: {
      // Imports next base styles
      padding: 0,
      ...theme.components.Link.baseStyle,
      // Overwrites with our own changes
      ...link.baseStyle,
      _active: {
        color: 'white',
      },
    },
  },
  sizes: {
    xs: {
      fontSize: '0.75rem',
      padding: '14px 16px',
      height: '30px',
    },
    sm: {
      fontSize: 'sm',
      padding: '0 16px',
      height: '36px',
    },
    rounded: {
      fontSize: '1rem',
      height: '40px',
      width: '40px',
    },
    md: {
      fontSize: '1rem',
      padding: '14px 24px',
      height: '52px',
    },
    lg: {
      fontSize: '1rem',
      padding: '50px 50px',
    },
  },
};
