import { AcademyArticleOverviewFieldsFragment } from '@cms/graphql/articleOverviewPage.generated';
import {
  PageSearchAsset,
  PageSearchData,
  PageSearchDataImage,
  PageSearchFields,
} from './search.types';
import { Entry } from 'contentful';
import { contenfulSearchClient } from '@cms/client';
import { BaseGameFieldsFragment } from '@cms/graphql/gamesOverviewPage.generated';
import { getArticleUrl } from '@/utils/academy';
import { getGamesUrl } from '@/utils/games';
import { PageUrls } from '@/config/urls';
import { SearchContenfulQuery } from '@/client/graphql/search.generated';

const getPageDataImage = (image: PageSearchAsset): PageSearchDataImage | null => {
  if (!image) return null;
  return {
    title: image.fields.title,
    url: image.fields.file.url,
  };
};

const getPageGameSearchData = (
  item: PageSearchFields<Omit<BaseGameFieldsFragment, 'sys'>>,
): PageSearchData => ({
  title: item.fields.title,
  description: item.fields.summary,
  image: getPageDataImage(item.fields.mainImage),
  translationNamespace: 'games',
  rootPath: PageUrls.GAMES,
  fullPath: getGamesUrl(item.fields.urlSlug),
});

const getPageAcademyArticleSearchData = (
  item: PageSearchFields<AcademyArticleOverviewFieldsFragment>,
): PageSearchData => ({
  title: item.fields.title,
  description: item.fields.overviewDescription,
  image: getPageDataImage(item.fields.overviewImage),
  translationNamespace: 'academy',
  rootPath: PageUrls.ACADEMY,
  fullPath: getArticleUrl(item.fields.urlSlug),
});

export const filterPageData = (entryCollection: Entry<unknown>[]): PageSearchData[] =>
  entryCollection
    .map(item => {
      switch (item.sys?.contentType.sys.id) {
        case 'pageGame':
          return getPageGameSearchData(item);
        case 'pageAcademyArticle':
          return getPageAcademyArticleSearchData(item);
      }
    })
    .filter(Boolean);

export const getResults = async (search: string | string[]) => {
  const searchResults = await contenfulSearchClient.getEntries({
    locale: 'en-US',
    query: search,
  });

  const filterOnPage = (item: Entry<unknown>) => item.sys.contentType.sys.id.startsWith('page');
  const filterOnComponent = (item: Entry<unknown>) =>
    item.sys.contentType.sys.id.startsWith('component');

  const pages = searchResults.items.filter(filterOnPage);
  const components = searchResults.items.filter(filterOnComponent);

  const promises = components.map(element =>
    contenfulSearchClient.getEntries({
      links_to_entry: element.sys.id,
    }),
  );

  const pagesWithLinkedContent = await Promise.all(promises);
  const flatPagesWithLinkedContent = pagesWithLinkedContent
    .map(({ items }) => items.filter(filterOnPage))
    .flat();

  const allPages = [...pages, ...flatPagesWithLinkedContent];

  const uniqueIds = Array.from(new Set(allPages.map(page => page.sys.id)));

  const uniqueResults = allPages.reduce((accumulator, page) => {
    const findIndex = uniqueIds.findIndex(id => id === page.sys.id);
    if (findIndex !== -1) {
      uniqueIds.splice(findIndex, 1);
      return [...accumulator, page];
    }
    return accumulator;
  }, []);

  return uniqueResults;
};

// used for search bar in header
export const transformSearchResults = (
  results: SearchContenfulQuery['searchContenful'],
): PageSearchData[] => {
  return results?.map(item => {
    return {
      ...item,
      image: getPageDataImage(item.image),
      translationNamespace: item.pageType === 'pageGame' ? 'games' : 'academy',
      rootPath: item.pageType === 'pageGame' ? PageUrls.GAMES : PageUrls.ACADEMY,
      fullPath:
        item.pageType === 'pageGame' ? getGamesUrl(item.urlSlug) : getArticleUrl(item.urlSlug),
    };
  });
};
