import { SystemStyleInterpolation } from '@chakra-ui/theme-tools';

export const keyboardFocused = (css: SystemStyleInterpolation) => ({
  '&:focus': {
    outline: 'none',
  },
  '@media (pointer: fine)': {
    [`.has--keyboard-focus &:focus`]: css,
  },
});
