import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={20}
      fill="none"
      viewBox="0 0 28 20"
      ref={svgRef}
      {...props}>
      <path
        fill="#fff"
        d="M27.419 3.267A3.512 3.512 0 0024.95.797C22.76.2 13.996.2 13.996.2S5.235.2 3.04.776a3.588 3.588 0 00-2.469 2.49C0 5.457 0 10 0 10s0 4.566.577 6.733a3.512 3.512 0 002.469 2.47C5.26 19.8 14 19.8 14 19.8s8.76 0 10.954-.577a3.511 3.511 0 002.47-2.469C28 14.566 28 10.022 28 10.022s.022-4.566-.577-6.755h-.004z"
        color="currentColor"
      />
      <path fill="#2D2C30" d="M11.207 5.8v8.394L18.495 10l-7.288-4.2z" color="currentColor" />
    </svg>
  );
});
const YoutubeIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default YoutubeIcon;
