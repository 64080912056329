import { Text, Flex, Button, Box } from '@chakra-ui/react';
import { ProfileSectionListItemProps } from './profile-section-list-item.types';

export const ProfileSectionListItem = ({
  content,
  label,
  buttonLabel = 'Edit',
  key,
  onButtonClick,
  fetching = false,
}: ProfileSectionListItemProps) => {
  return (
    <Flex w="100%" direction="row" alignItems="center" justifyContent="space-between" key={key}>
      <Box minW={0}>
        {content ? (
          <>
            <Text fontSize="sm" color="white">
              {label}
            </Text>
            <Text fontSize="md" color="muted">
              {content}
            </Text>
          </>
        ) : (
          <Text fontSize="md" color="white">
            {label}
          </Text>
        )}
      </Box>
      {onButtonClick && (
        <Button
          ml="auto"
          size="sm"
          flexShrink={0}
          onClick={onButtonClick}
          isLoading={fetching}
          disabled={fetching}>
          {buttonLabel}
        </Button>
      )}
    </Flex>
  );
};
