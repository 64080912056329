/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { ReviewsPagination } from './reviews-pagination.types';

export const defaultReviewsPaginationContext: ReviewsPagination = {
  page: 0,
  sortOption: 'voteDiff-DESC',
  setPage: () => ({}),
  setSortOption: () => ({}),
};

export const ReviewsPaginationContext = createContext<ReviewsPagination>(
  defaultReviewsPaginationContext,
);
