import { AspectRatio, Grid, GridItem } from '@chakra-ui/react';
import { Tile } from '../tile/tile';
import { TileListProps } from './tile-list.types';

export const TileList = ({ tiles, ...props }: TileListProps) =>
  !!tiles.length ? (
    <Grid
      as="ul"
      templateColumns={{
        base: `repeat(2, 1fr)`,
        lg: `repeat(4, 1fr)`,
      }}
      gap={1}
      sx={{ listStyle: 'none', ':hover a': { opacity: 0.4 } }}
      {...props}>
      {tiles.map((tile, index) => (
        <GridItem key={`${index}`} data-testid="grid-game-item">
          <AspectRatio ratio={424 / 650} w="100%">
            <li>
              <Tile
                {...tile}
                image={{ ...tile.image, src: `${tile.image.src}?w=1920` }}
                h="100%"
                sx={{ '&&:hover': { opacity: 1 } }}
              />
            </li>
          </AspectRatio>
        </GridItem>
      ))}
    </Grid>
  ) : null;
