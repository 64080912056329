import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      fill="none"
      viewBox="0 0 48 48"
      ref={svgRef}
      {...props}>
      <circle cx={24} cy={24} r={21.9} fill="url(#paint0_linear_17190_47203)" />
      <circle
        cx={24}
        cy={24}
        r={20.31}
        fill="url(#paint1_linear_17190_47203)"
        transform="rotate(-10 24.002 24)"
      />
      <circle
        cx={24}
        cy={24}
        r={18.72}
        fill="url(#paint2_linear_17190_47203)"
        transform="rotate(-20 24 24)"
      />
      <circle
        cx={24}
        cy={24}
        r={17.14}
        fill="url(#paint3_linear_17190_47203)"
        transform="rotate(-30 24 24)"
      />
      <circle
        cx={24}
        cy={24}
        r={15.55}
        fill="url(#paint4_linear_17190_47203)"
        transform="rotate(-40 24 24)"
      />
      <circle
        cx={24}
        cy={24}
        r={13.96}
        fill="url(#paint5_linear_17190_47203)"
        transform="rotate(-50 23.998 24)"
      />
      <circle
        cx={24}
        cy={24}
        r={12.37}
        fill="url(#paint6_linear_17190_47203)"
        transform="rotate(-60 24 24)"
      />
      <circle
        cx={24}
        cy={24}
        r={10.78}
        fill="url(#paint7_linear_17190_47203)"
        transform="rotate(-70 24.003 24)"
      />
      <circle
        cx={24}
        cy={24}
        r={9.19}
        fill="url(#paint8_linear_17190_47203)"
        transform="rotate(-80 24.003 24)"
      />
      <circle
        cx={24}
        cy={24}
        r={7.6}
        fill="url(#paint9_linear_17190_47203)"
        transform="rotate(-90 23.998 24)"
      />
      <circle
        cx={24}
        cy={24}
        r={6.01}
        fill="url(#paint10_linear_17190_47203)"
        transform="rotate(-100 23.999 24)"
      />
      <circle
        cx={24}
        cy={24}
        r={4.42}
        fill="url(#paint11_linear_17190_47203)"
        transform="rotate(-110 23.998 24)"
      />
      <circle
        cx={24}
        cy={24}
        r={2.83}
        fill="url(#paint12_linear_17190_47203)"
        transform="rotate(-120 23.998 24)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_17190_47203"
          x1={24}
          x2={24}
          y1={2.1}
          y2={45.9}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BCDCFF" />
          <stop offset={0.08} stopColor="#2EA8E3" />
          <stop offset={0.29} stopColor="#FF6C6C" />
          <stop offset={0.33} stopColor="#F01312" />
          <stop offset={0.58} stopColor="#F2E86A" />
          <stop offset={0.6} stopColor="#FF981A" />
          <stop offset={0.94} stopColor="#BCFFCA" />
          <stop offset={1} stopColor="#3DD011" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17190_47203"
          x1={24}
          x2={24}
          y1={3.69}
          y2={44.31}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BCDCFF" />
          <stop offset={0.08} stopColor="#2EA8E3" />
          <stop offset={0.29} stopColor="#FF6C6C" />
          <stop offset={0.33} stopColor="#F01312" />
          <stop offset={0.58} stopColor="#F2E86A" />
          <stop offset={0.6} stopColor="#FF981A" />
          <stop offset={0.94} stopColor="#BCFFCA" />
          <stop offset={1} stopColor="#3DD011" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_17190_47203"
          x1={24}
          x2={24}
          y1={5.28}
          y2={42.72}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BCDCFF" />
          <stop offset={0.08} stopColor="#2EA8E3" />
          <stop offset={0.29} stopColor="#FF6C6C" />
          <stop offset={0.33} stopColor="#F01312" />
          <stop offset={0.58} stopColor="#F2E86A" />
          <stop offset={0.6} stopColor="#FF981A" />
          <stop offset={0.94} stopColor="#BCFFCA" />
          <stop offset={1} stopColor="#3DD011" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_17190_47203"
          x1={24}
          x2={24}
          y1={6.86}
          y2={41.14}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BCDCFF" />
          <stop offset={0.08} stopColor="#2EA8E3" />
          <stop offset={0.29} stopColor="#FF6C6C" />
          <stop offset={0.33} stopColor="#F01312" />
          <stop offset={0.58} stopColor="#F2E86A" />
          <stop offset={0.6} stopColor="#FF981A" />
          <stop offset={0.94} stopColor="#BCFFCA" />
          <stop offset={1} stopColor="#3DD011" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_17190_47203"
          x1={24}
          x2={24}
          y1={8.45}
          y2={39.55}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BCDCFF" />
          <stop offset={0.08} stopColor="#2EA8E3" />
          <stop offset={0.29} stopColor="#FF6C6C" />
          <stop offset={0.33} stopColor="#F01312" />
          <stop offset={0.58} stopColor="#F2E86A" />
          <stop offset={0.6} stopColor="#FF981A" />
          <stop offset={0.94} stopColor="#BCFFCA" />
          <stop offset={1} stopColor="#3DD011" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_17190_47203"
          x1={24}
          x2={24}
          y1={10.04}
          y2={37.96}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BCDCFF" />
          <stop offset={0.08} stopColor="#2EA8E3" />
          <stop offset={0.29} stopColor="#FF6C6C" />
          <stop offset={0.33} stopColor="#F01312" />
          <stop offset={0.58} stopColor="#F2E86A" />
          <stop offset={0.6} stopColor="#FF981A" />
          <stop offset={0.94} stopColor="#BCFFCA" />
          <stop offset={1} stopColor="#3DD011" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_17190_47203"
          x1={24}
          x2={24}
          y1={11.63}
          y2={36.37}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BCDCFF" />
          <stop offset={0.08} stopColor="#2EA8E3" />
          <stop offset={0.29} stopColor="#FF6C6C" />
          <stop offset={0.33} stopColor="#F01312" />
          <stop offset={0.58} stopColor="#F2E86A" />
          <stop offset={0.6} stopColor="#FF981A" />
          <stop offset={0.94} stopColor="#BCFFCA" />
          <stop offset={1} stopColor="#3DD011" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_17190_47203"
          x1={24}
          x2={24}
          y1={13.22}
          y2={34.78}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BCDCFF" />
          <stop offset={0.08} stopColor="#2EA8E3" />
          <stop offset={0.29} stopColor="#FF6C6C" />
          <stop offset={0.33} stopColor="#F01312" />
          <stop offset={0.58} stopColor="#F2E86A" />
          <stop offset={0.6} stopColor="#FF981A" />
          <stop offset={0.94} stopColor="#BCFFCA" />
          <stop offset={1} stopColor="#3DD011" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_17190_47203"
          x1={24}
          x2={24}
          y1={14.81}
          y2={33.19}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BCDCFF" />
          <stop offset={0.08} stopColor="#2EA8E3" />
          <stop offset={0.29} stopColor="#FF6C6C" />
          <stop offset={0.33} stopColor="#F01312" />
          <stop offset={0.58} stopColor="#F2E86A" />
          <stop offset={0.6} stopColor="#FF981A" />
          <stop offset={0.94} stopColor="#BCFFCA" />
          <stop offset={1} stopColor="#3DD011" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_17190_47203"
          x1={24}
          x2={24}
          y1={16.4}
          y2={31.6}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BCDCFF" />
          <stop offset={0.08} stopColor="#2EA8E3" />
          <stop offset={0.29} stopColor="#FF6C6C" />
          <stop offset={0.33} stopColor="#F01312" />
          <stop offset={0.58} stopColor="#F2E86A" />
          <stop offset={0.6} stopColor="#FF981A" />
          <stop offset={0.94} stopColor="#BCFFCA" />
          <stop offset={1} stopColor="#3DD011" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_17190_47203"
          x1={24}
          x2={24}
          y1={17.99}
          y2={30.01}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BCDCFF" />
          <stop offset={0.08} stopColor="#2EA8E3" />
          <stop offset={0.29} stopColor="#FF6C6C" />
          <stop offset={0.33} stopColor="#F01312" />
          <stop offset={0.58} stopColor="#F2E86A" />
          <stop offset={0.6} stopColor="#FF981A" />
          <stop offset={0.94} stopColor="#BCFFCA" />
          <stop offset={1} stopColor="#3DD011" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_17190_47203"
          x1={24}
          x2={24}
          y1={19.58}
          y2={28.42}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BCDCFF" />
          <stop offset={0.08} stopColor="#2EA8E3" />
          <stop offset={0.29} stopColor="#FF6C6C" />
          <stop offset={0.33} stopColor="#F01312" />
          <stop offset={0.58} stopColor="#F2E86A" />
          <stop offset={0.6} stopColor="#FF981A" />
          <stop offset={0.94} stopColor="#BCFFCA" />
          <stop offset={1} stopColor="#3DD011" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_17190_47203"
          x1={24}
          x2={24}
          y1={21.17}
          y2={26.83}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BCDCFF" />
          <stop offset={0.08} stopColor="#2EA8E3" />
          <stop offset={0.29} stopColor="#FF6C6C" />
          <stop offset={0.33} stopColor="#F01312" />
          <stop offset={0.58} stopColor="#F2E86A" />
          <stop offset={0.6} stopColor="#FF981A" />
          <stop offset={0.94} stopColor="#BCFFCA" />
          <stop offset={1} stopColor="#3DD011" />
        </linearGradient>
      </defs>
    </svg>
  );
});
const LoadingIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default LoadingIcon;
