import {
  Box,
  Text,
  Heading,
  OrderedList,
  UnorderedList,
  ListItem,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { Node, RichTextProps } from './rich-text.types';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { ReactNode } from 'react';

const getOptions = (options: Options): Options => ({
  renderText: text => {
    // rendering line break in rich text
    return text ? <>{text}</> : <br />;
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node: Node, children: ReactNode) => (
      <Heading as="h1" size="2xl" variant="glowing" color="lightPurple">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_2]: (node: Node, children: ReactNode) => <Heading>{children}</Heading>,
    [BLOCKS.HEADING_3]: (node: Node, children: ReactNode) => (
      <Heading as="h3" size="lg">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_4]: (node: Node, children: ReactNode) => (
      <Heading as="h4" size="md" fontSize="1.5rem" lineHeight={2}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_5]: (node: Node, children: ReactNode) => (
      <Heading as="h5" size="sm" lineHeight={2}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_6]: (node: Node, children: ReactNode) => (
      <Heading as="h6" size="xs">
        {children}
      </Heading>
    ),
    [BLOCKS.PARAGRAPH]: (node: Node, children: ReactNode) => (
      <Text whiteSpace="pre-wrap">{children}</Text>
    ),
    [BLOCKS.OL_LIST]: (node: Node, children: ReactNode) => <OrderedList>{children}</OrderedList>,
    [BLOCKS.UL_LIST]: (node: Node, children: ReactNode) => (
      <UnorderedList>{children}</UnorderedList>
    ),
    [BLOCKS.LIST_ITEM]: (node: Node, children: ReactNode) => <ListItem>{children}</ListItem>,
    [BLOCKS.TABLE]: (node: Node, children: ReactNode) => (
      <TableContainer>
        <Table>{children}</Table>
      </TableContainer>
    ),
    [BLOCKS.TABLE_ROW]: (node: Node, children: ReactNode) => {
      if (node.content.every(node => node.nodeType === BLOCKS.TABLE_HEADER_CELL)) {
        return (
          <Thead>
            <Tr>{children}</Tr>
          </Thead>
        );
      }

      return <Tr>{children}</Tr>;
    },

    [BLOCKS.TABLE_HEADER_CELL]: (node: Node, children: ReactNode) => <Th>{children}</Th>,
    [BLOCKS.TABLE_CELL]: (node: Node, children: ReactNode) => <Td>{children}</Td>,
    [INLINES.HYPERLINK]: (node: Node, children: ReactNode) => (
      // override chakra theme style
      <a href={node.data.uri} style={{ textDecoration: 'underline' }}>
        {children}
      </a>
    ),
    ...options.renderNode,
  },
  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => (
      <Text as="strong" fontWeight="bold">
        {text}
      </Text>
    ),
    [MARKS.ITALIC]: (text: ReactNode) => (
      <Text as="span" fontStyle="italic">
        {text}
      </Text>
    ),
    [MARKS.UNDERLINE]: (text: ReactNode) => (
      <Text as="span" textDecoration="underline">
        {text}
      </Text>
    ),
    ...options.renderMark,
  },
});

export const RichText = ({ document, options = {}, ...props }: RichTextProps) => {
  const combinedOptions = getOptions(options);

  return <Box {...props}>{documentToReactComponents(document, combinedOptions)}</Box>;
};
