import { Flex, Box, Container } from '@chakra-ui/react';
import { FullWidthImageProps } from './full-width-image.types';

export const FullWidthImage = ({
  backgroundImage,
  wrapperProps,
  children,
}: FullWidthImageProps) => (
  <Flex
    as="section"
    minH={{ base: '36.25rem', lg: '44.94rem' }}
    width="100%"
    bg={`linear-gradient(270deg, rgba(0, 0, 0, 0) 45.48%, rgba(0, 0, 0, 0.6) 77.85%), url(${backgroundImage})`}
    bgSize="cover"
    bgPosition="center"
    m="auto"
    paddingY={{ base: '10', lg: '0' }}
    position="relative"
    alignItems={{ base: 'flex-end', lg: 'center' }}>
    <Container>
      <Box maxW={{ base: '16.75rem', lg: '25rem' }} {...wrapperProps}>
        {children}
      </Box>
    </Container>
  </Flex>
);
