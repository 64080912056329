import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

interface UseLazyImageSrcProps {
  url: string;
  placeholderUrl: string;
}

export enum LoadingStates {
  initial,
  loading,
  loaded,
}

type UseLazyImageSrcReturnType = [(node?: Element | null) => void, string, LoadingStates];

/**
 * Small helper function that adds a suffix if url is not undefined.
 * Otherwise you would need to check for undefined on setting up useLazyImageSrc.
 */
export const appendIfNotUndefined = (url: string | undefined, suffix: string) =>
  url ? `${url}${suffix}` : undefined;

export const useLazyImageSrc = ({
  url,
  placeholderUrl,
}: UseLazyImageSrcProps): UseLazyImageSrcReturnType => {
  const { ref, inView } = useInView();

  const [loadingState, setLoadingState] = useState<LoadingStates>(LoadingStates.initial);
  const [imageUrl, setImageUrl] = useState(placeholderUrl);

  // By creating a new image and tapping into the onload
  // event, we only show the full image as soon as the image has been
  // successfully fetched.
  useEffect(() => {
    if (inView && loadingState === LoadingStates.initial) {
      setLoadingState(LoadingStates.loading);

      const image = new Image();
      image.onload = () => {
        setLoadingState(LoadingStates.loaded);
        setImageUrl(url);
      };
      image.src = url;
    }
    if (placeholderUrl && imageUrl !== url) {
      setLoadingState(LoadingStates.initial);
    }
  }, [loadingState, inView, url]);

  return [ref, imageUrl, loadingState];
};
