import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      ref={svgRef}
      {...props}>
      <path
        fill="#fff"
        d="M14 0c7.732 0 14 6.268 14 14s-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0zm4.83 19.706c.257-.79 1.463-8.664 1.612-10.215.046-.47-.103-.782-.394-.922-.352-.169-.873-.084-1.477.134-.83.298-11.428 4.799-12.04 5.059-.581.246-1.13.516-1.13.905 0 .274.162.428.61.588.467.166 1.64.522 2.334.713.668.185 1.428.025 1.854-.24.452-.28 5.664-3.768 6.039-4.074.373-.306.672.086.366.392-.306.306-3.883 3.778-4.355 4.26-.573.583-.166 1.188.218 1.43.439.276 3.595 2.394 4.07 2.733.475.34.957.494 1.399.494.441 0 .674-.581.893-1.257z"
        color="currentColor"
      />
    </svg>
  );
});
const TelegramIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default TelegramIcon;
