import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={12}
      fill="none"
      viewBox="0 0 18 12"
      ref={svgRef}
      {...props}>
      <path
        fill="currentColor"
        d="M6.45 11.85l-5.6-5.6 1.075-1.075L6.45 9.7l9.6-9.6 1.075 1.075L6.45 11.85z"
      />
    </svg>
  );
});
const CheckMarkIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default CheckMarkIcon;
