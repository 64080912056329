import { Container, Heading, Text } from '@chakra-ui/react';
import { Story } from '@storybook/react';
import { GlassBoxProps } from './glass-box.types';
import { GlassBox } from './glass-box';

export default {
  title: 'Components/Glass Box',
  subComponent: { GlassBox },
};

const TemplateGlassProps: Story<GlassBoxProps> = args => (
  <Container>
    <GlassBox {...args}>
      <Heading>Axie Infinity</Heading>
      <Text>
        Journey across a vast and varied landscape on your quest to hunt and capture deity-like
        creatures called Illuvials.
      </Text>
    </GlassBox>
  </Container>
);

export const glassBox = TemplateGlassProps.bind({});
