import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      fill="currentColor"
      viewBox="0 0 24 25"
      ref={svgRef}
      {...props}>
      <path
        fillRule="evenodd"
        d="M18.419 17.672a9.967 9.967 0 002.81-6.95c0-5.523-4.478-10-10-10-5.523 0-10 4.477-10 10s4.477 10 10 10a9.954 9.954 0 005.664-1.758l4.572 5.404 1.527-1.292-4.573-5.404zm-7.19 1.05a8 8 0 110-16 8 8 0 010 16z"
        clipRule="evenodd"
      />
    </svg>
  );
});
const SearchIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default SearchIcon;
