import { useRouter } from 'next/router';
import { PageUrls } from '@/config/urls';
import { NextRouter } from 'next/dist/shared/lib/router/router';
import { isExternalUrl } from '@meritcircle/ui';

export function useRedirect() {
  const router = useRouter();

  const redirectionKey = 'redirect';
  /**
   * this routes should not redirect user to another page since they will ask user to fill their information
   */
  const disabledRoutes = [PageUrls.ONBOARDING, PageUrls.VERIFY_EMAIL];

  /**
   * validates url and checks if it's an external url or XSS attack
   * @param url
   */
  const validateRedirectUrl = (url: string) => {
    if (url.match('http|javascript')) return PageUrls.DASHBOARD;
    return url;
  };

  const setRedirectUrl = (url: string) => {
    const validatedRedirectBackUrl = validateRedirectUrl(url);

    if (validatedRedirectBackUrl) {
      localStorage.setItem(redirectionKey, validatedRedirectBackUrl);
    }
  };

  const redirect = (url: string, redirectUrl?: string) => {
    if (isExternalUrl(url)) {
      window.open(url, '_blank');

      return;
    }

    setRedirectUrl(redirectUrl);

    router.replace(url);
  };

  const checkRedirection = (currentLocation: NextRouter) => {
    if (disabledRoutes.some(route => currentLocation.pathname.startsWith(route))) {
      return;
    }

    const redirectUrl = localStorage.getItem(redirectionKey);

    if (redirectUrl) {
      localStorage.removeItem(redirectionKey);
      router.replace(redirectUrl);
    }
  };

  return {
    redirect,
    checkRedirection,
    setRedirectUrl,
  };
}
