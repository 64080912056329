import { Box, Container, Heading, Text } from '@chakra-ui/react';

import { PropsWithChildren } from 'react';
import { gradients } from '../../theme/colors';
import { useComponentSlots } from '../../utils/hooks/use-component-slots';
import { Breadcrumbs } from '../breadcrumbs/breadcrumbs';
import { PageHeaderProps } from './page-header.types';

export const PageHeader = ({
  title,
  subtitle,
  notification,
  children,
  breadcrumbs,
  backgroundColor = 'black',
  containerProps = {},
}: PropsWithChildren<PageHeaderProps>) => {
  const [Slot] = useComponentSlots(children);

  return (
    <Box as="section" bg={backgroundColor} pt="xl" pb={{ base: 'xl', md: '3xl' }}>
      <Container {...containerProps}>
        <Slot name="top" />
        <Breadcrumbs breadcrumbs={breadcrumbs} mt={{ base: 'md', md: 'lg' }} />
        <Slot name="middle" />
        <Heading
          as="h1"
          size="2xl"
          variant="glowing"
          color="lightPurple"
          mt={{ base: 'xl', md: '3xl' }}>
          {title}
        </Heading>
        {!!notification && (
          <Text display="inline-block" bgGradient={gradients.orangeFill} bgClip="text" mt="sm">
            {notification}
          </Text>
        )}
        <Slot name="notification" />
        <Text variant="muted" mt={{ base: 'md', md: 'lg' }}>
          {subtitle}
        </Text>
        <Slot name="bottom" />
      </Container>
    </Box>
  );
};
