import { Box } from '@chakra-ui/react';
import { gradients } from '../../theme';
import { GlassBoxProps } from './glass-box.types';

export const GlassBox = ({ children, contentBoxProps, ...props }: GlassBoxProps) => (
  <Box
    borderRadius={4}
    py="xl"
    px={{ base: 'lg', md: 'xl' }}
    bg="rgba(208, 208, 208, 0.02)"
    position="relative"
    overflow="hidden"
    border={`1px solid transparent`}
    style={{
      borderImage: `${gradients.lightBlueStroke} 1`,
    }}
    _before={{
      content: '""',
      inset: 0,
      position: 'absolute',
      padding: '1px',
      borderRadius: 'inherit',
      mask: `linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)`,
      maskComposite: 'exclude',
      WebkitMaskComposite: 'xor',
    }}
    {...props}>
    <Box position="relative" {...contentBoxProps}>
      {children}
    </Box>
  </Box>
);
