import React from 'react';

type Props = {
  name?: string;
};

/**
 * Utilizes the html `slot` tag to render children on predefined component slots
 * @example
 * // First, use the hook in a component
 * const ExampleComponent = ({children}) => {
 *  const [Slot, hasSlot] = useComponentSlots(children)
 *
 *  return (
 *    <Box>
 *      {hasSlot('header') && <Slot name="header" />}
 *      <Container>
 *        <Heading>A title</Heading>
 *        <Slot name="footer" />
 *      </Container>
 *    </Box>
 *  );
 * }
 *
 * // Then, reference the slot name in your markup
 * <ExampleComponent>
 *  <Box slot="header">Some header content</Box>
 *  <Box slot="footer">Some footer content</Box>
 * </ExampleComponent>
 *
 * @param componentChildren
 * @returns
 */
export const useComponentSlots = (
  componentChildren: React.ReactNode,
): [(props: Props) => JSX.Element, (slot: string) => boolean] => {
  const childrenArray = React.Children.toArray(
    componentChildren,
  ) as unknown as React.ReactElement[];

  const defaultSlotName = 'default';

  const slots = childrenArray.reduce<Record<string, React.ReactElement[]>>(
    (acc, child) => {
      const slotName = child.props.slot || defaultSlotName;

      if (!acc.hasOwnProperty(slotName)) {
        acc[slotName] = [];
      }

      acc[slotName] = [child];
      return acc;
    },
    { [defaultSlotName]: [] },
  );

  return [
    ({ name, children: defaultChildren }: { name?: string; children?: React.ReactNode }) => {
      const children = !name
        ? slots[defaultSlotName]
        : slots.hasOwnProperty(name)
        ? slots[name]
        : defaultChildren;

      return React.createElement(React.Fragment, undefined, children);
    },
    (slot: string) => !!(slots.hasOwnProperty(slot) && slots[slot].length),
  ];
};
