import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { ComponentStyleConfig } from '@chakra-ui/react';
import type { PartsStyleFunction, SystemStyleFunction } from '@chakra-ui/theme-tools';

/**
 * At the time of writing, `100vh` still poses a problem in mobile browsers, where the browsers' address bar
 * will not respect the viewport height and overlap content that sits at the bottom of a page. Instead of using
 * chakra's default '100vh', the following snippet will use the global CSS variable '--vh' that is added by the
 * `useViewportHeightVar` hook.
 */
const viewportHeightFix = { h: 'calc(100 * var(--vh))' };

const baseStyleDialog: SystemStyleFunction = props => {
  const { isFullHeight } = props;

  return {
    ...(isFullHeight && viewportHeightFix),
  };
};

const getFullSize = () => ({
  dialog: {
    ...viewportHeightFix,
  },
});

const baseStyle: PartsStyleFunction<typeof parts> = props => ({
  dialog: baseStyleDialog(props),
});

export const drawer: ComponentStyleConfig = {
  baseStyle,

  sizes: {
    full: getFullSize(),
  },
};
