import { defineStyleConfig } from '@chakra-ui/react';

export const textLink = defineStyleConfig({
  defaultProps: {
    variant: 'default',
  },
  variants: {
    default: {
      fontWeight: 'normal',
      textDecoration: 'none',
      color: 'lightPurple',
      _hover: {
        color: 'white',
      },
      _active: {
        color: 'white',
        opacity: 0.6,
      },
      _disabled: {
        opacity: 0.3,
      },
    },
    white: {
      fontWeight: 'normal',
      textDecoration: 'none',
      color: 'white',
      _hover: {
        color: 'white',
      },
      _active: {
        color: 'white',
        opacity: 0.6,
      },
      _disabled: {
        opacity: 0.3,
      },
    },
  },
  sizes: {
    current: { fontSize: '1em' },
    sm: { fontSize: 'sm' },
    md: { fontSize: 'md' },
    lg: { fontSize: 'lg' },
  },
});
