import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { gradients } from '../../theme';
import { LazyImage } from '../lazy-image/lazy-image';

import { HeroProps } from './hero.types';

export const Hero = ({
  title,
  text,
  label,
  heroImage,
  aspectRatio = {},
  heroImagePlaceholder,
}: PropsWithChildren<HeroProps>) => {
  const offsetY = { base: '-7rem', xl: '-15rem' };
  let imageRatio;

  if (typeof aspectRatio === 'string') {
    imageRatio = aspectRatio;
  }

  if (typeof aspectRatio === 'object') {
    imageRatio = { base: '150%', sm: '93.75%', lg: '56.25%', ...aspectRatio };
  }

  return (
    <Box
      as="section"
      maxWidth={'100rem'}
      display="grid"
      mx="auto"
      textAlign={{ base: 'left', lg: 'center' }}
      mb={offsetY}>
      <Box gridArea="1/1">
        <Box
          mx="auto"
          pb={imageRatio}
          position="relative"
          _after={{
            content: '" "',
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            bottom: 0,
            background:
              'linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0.62) 7%,rgba(0,0,0,0.29) 15%,rgba(0,0,0,0.08) 30%,rgba(0,0,0,0) 40%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.08) 65%,rgba(0,0,0,0.49) 82%,rgba(0,0,0,1) 100%)',
          }}>
          {heroImage && (
            <LazyImage
              src={heroImage}
              placeholderUrl={heroImagePlaceholder}
              alt={'Hero image'}
              position="absolute"
              objectFit="cover"
              width="100%"
              height={'100%'}
              maxWidth="none"
            />
          )}
        </Box>
      </Box>

      <Box gridArea="1/1" my={'auto'}>
        <Container position="relative" mt={offsetY} mx={{ base: 0, lg: 'auto' }}>
          {label && (
            <Text
              display="inline-block"
              background={gradients.orangeStroke}
              backgroundClip="text"
              fontWeight="700">
              {label}
            </Text>
          )}
          {title && (
            <Heading
              as="h1"
              size="2xl"
              fontSize={{ base: '4xl', md: '5xl', lg: '6xl' }}
              mb="lg"
              letterSpacing="tight">
              {title}
            </Heading>
          )}
          {text && (
            <Text as="h2" fontSize={{ md: 'xl' }} fontWeight="700">
              {text}
            </Text>
          )}
        </Container>
      </Box>
    </Box>
  );
};
