import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      ref={svgRef}
      {...props}>
      <path
        fill="#fff"
        d="M20.206 3h-5.511a.98.98 0 00-.977.974c0 .535.44.975.977.975l3.138-.001-9.129 9.128a.967.967 0 000 1.375.973.973 0 001.379 0l9.147-9.109v3.132c0 .534.44.974.977.974a.98.98 0 00.976-.974v-5.5A.98.98 0 0020.205 3z"
        color="currentColor"
      />
      <path
        fill="#fff"
        d="M17.679 12.034a.98.98 0 00-.976.974v3.915a2.28 2.28 0 01-2.278 2.272H7.248a2.28 2.28 0 01-2.277-2.272V9.76a2.28 2.28 0 012.277-2.272h3.924a.98.98 0 00.976-.975.98.98 0 00-.976-.974H7.248A4.246 4.246 0 003 9.78v7.162a4.246 4.246 0 004.248 4.239h7.177a4.246 4.246 0 004.249-4.24v-3.934a1.008 1.008 0 00-.995-.973z"
        color="currentColor"
      />
    </svg>
  );
});
const LinkIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default LinkIcon;
