import { List } from '@chakra-ui/react';
import { ChecklistItem } from './checklist-item';
import { ChecklistProps } from './checklist.types';

export const Checklist = ({ items }: ChecklistProps) => (
  <List spacing={1}>
    {items.map((item, index) => (
      <ChecklistItem {...item} key={`checklist-item-${index}`} />
    ))}
  </List>
);
