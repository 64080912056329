import { createContext } from 'react';
import { MetaData } from './meta-data.types';

export const MetaDataContext = createContext<MetaData>({
  market: {
    language: 'en',
    country: 'US',
    timeZone: 'Europe/Amsterdam',
  },
});
