import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Text,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { AsYouType } from 'libphonenumber-js';
import { ChangeEvent, KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import {
  CountriesListDropdownProps,
  Country,
  PhoneNumberInputProps,
} from './phone-number-input.types';

export const PhoneNumberInput = ({
  initialValue,
  onChange,
  error,
  label,
  countryLabel,
  countrySearchPlaceholder,
  isRequired = false,
  ...boxProps
}: PhoneNumberInputProps) => {
  const ref = useRef(null);
  const [number, setNumber] = useState('');
  const [country, setCountry] = useState('');
  const [countryFlag, setCountryFlag] = useState('');
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [countries, setCountries] = useState<Country[] | null>(null);
  const phoneNumberInputRef = useRef<HTMLInputElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  });

  /**
   * Fetches countries asynchronously.
   * DidCancel boolean checks whether cleanup function has been called
   * before promise resolves. This prevents any setState from being called while
   * the componenet is unmounted.
   */
  useEffect(() => {
    const fetchCountries = async () => {
      const { default: countries } = await import('../../../public/data/countries.json');

      if (!!countries?.length) {
        setCountries(countries);
      }
    };

    fetchCountries();
  }, []);

  /**
   * When an initialValue is provided, this effect parses the value and attempts
   * to set the countries dropdown value. If it cannot find a country, it will just
   * set the entire value to the input.
   */
  useEffect(() => {
    if (!initialValue || !countries) return;

    const item = countries.find(c => initialValue.startsWith(c.dial_code));

    if (item) {
      const [, numberWithoutDialCode] = initialValue.split(item.dial_code);

      setCountry(item.dial_code);
      setCountryFlag(`${item?.flag} ${item?.dial_code}`);
      setNumber(numberWithoutDialCode);
    } else {
      setNumber(initialValue);
    }

    onChange(initialValue);
  }, [initialValue, countries]);

  /**
   * Calls the provided `onChange` handler whenever either the country of number changes
   */
  useEffect(() => {
    if (country !== '' || number !== '') {
      // If the user already added the dial_code to the number input, it will not be prepended again
      const parsedNumber = new AsYouType().input(
        !number.startsWith(country) ? `${country}${number}` : `${number}`,
      );

      onChange(parsedNumber);
    }
  }, [country, number, onChange]);

  /**
   * Sets the selected country and flag, and closes the country list dropdown
   */
  const onCountryChange = useCallback(
    (item: Country) => {
      setCountry(item.dial_code);
      setCountryFlag(`${item.flag} ${item.dial_code}`);

      onClose();

      if (phoneNumberInputRef.current) {
        phoneNumberInputRef.current.focus();
      }
    },
    [phoneNumberInputRef],
  );

  /**
   * Sets the number input value
   * @param event
   */
  const onPhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNumber(value);
  };

  // Automatically set focus to countries search input when the dropdown is opened
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  // Provided a null check to prevent the component from rendering while the countries list is loading
  if (!countries) return null;

  return (
    <Box as="section" ref={ref} {...boxProps}>
      <Box display="flex">
        {countries === null && <Spinner alignSelf="center" mr="lg" />}
        {/* If no countries are fetched (an empty array is also set on fetch error),
        the component won't render itself. The input it still usable and also accepts the
        country code. */}
        {countries !== null && countries.length > 0 && (
          <FormControl variant="floating" onFocus={onToggle} mr="lg" w="10rem">
            <InputGroup>
              <Input value={countryFlag} readOnly placeholder=" " />
              <FormLabel>{countryLabel}</FormLabel>
              <InputRightElement cursor="pointer" onClick={onToggle} top="1.125rem">
                {isOpen ? (
                  <ChevronUpIcon boxSize={6} color="gray.500" />
                ) : (
                  <ChevronDownIcon boxSize={6} color="gray.500" />
                )}
              </InputRightElement>
            </InputGroup>
          </FormControl>
        )}

        <FormControl variant="floating" isRequired={isRequired} isInvalid={!!error}>
          <Input
            value={number}
            type="tel"
            placeholder=" "
            onChange={onPhoneNumberChange}
            ref={phoneNumberInputRef}
          />
          <FormLabel>{label}</FormLabel>
          <FormErrorMessage data-testid="phone-number-error-message">{error}</FormErrorMessage>
        </FormControl>
      </Box>

      {isOpen ? (
        <CountriesListDropdown
          data={countries}
          onChange={onCountryChange}
          searchPlaceholder={countrySearchPlaceholder}
          searchInputRef={searchInputRef}
        />
      ) : null}
    </Box>
  );
};

const CountriesListDropdown = ({
  data,
  searchPlaceholder,
  searchInputRef,
  onChange,
}: CountriesListDropdownProps) => {
  const [filteredList, setFilteredList] = useState(data);
  const [selectedItem, setSelectedItem] = useState<Country | undefined>(undefined);

  const handleSearch = (event: any) => {
    const value = event.target.value.toLowerCase();
    const result: Country[] =
      data?.filter(
        (item: Country) =>
          item.name.toLowerCase().includes(value) || item.dial_code.includes(value),
      ) || [];
    setFilteredList(result);
  };

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();

        if (!filteredList.length) {
          return;
        }

        if (!selectedItem) {
          setSelectedItem(filteredList[0]);
          return;
        }

        const index = filteredList.findIndex(item => item.dial_code === selectedItem.dial_code);
        const nextIndex = (index + 1) % filteredList.length;
        setSelectedItem(filteredList[nextIndex]);
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();

        if (!filteredList.length) {
          return;
        }

        if (!selectedItem) {
          setSelectedItem(filteredList[filteredList.length - 1]);

          return;
        }

        const index = filteredList.findIndex(item => item.dial_code === selectedItem.dial_code);
        const prevIndex = !index ? filteredList.length - 1 : (index - 1) % filteredList.length;
        setSelectedItem(filteredList[prevIndex]);
      }

      if (event.key === 'Enter') {
        event.preventDefault();

        if (onChange && selectedItem) {
          onChange(selectedItem);
        }
      }
    },
    [filteredList, selectedItem, setSelectedItem, onChange],
  );

  return (
    <Box
      mt="lg"
      maxH="xs"
      bg="gray.800"
      width="full"
      height="auto"
      overflow="auto"
      borderRadius="lg"
      position="relative"
      onKeyDown={onKeyDown}>
      <Box p={4} position="sticky" top={0} bg="gray.800">
        <Input
          bg="gray.900"
          type="search"
          borderColor="transparent"
          borderRadius="md"
          autoComplete="off"
          placeholder={searchPlaceholder}
          onChange={event => handleSearch(event)}
          ref={searchInputRef}
          _hover={{ borderColor: 'transparent' }}
          _focusWithin={{ borderColor: 'transparent' }}
          _invalid={{ bg: 'white', borderColor: 'gray.50' }}
        />
      </Box>
      <List>
        {filteredList?.map((item: Country, index: number) => (
          <ListItem
            key={index}
            paddingY={2}
            color="muted"
            cursor="pointer"
            fontWeight="500"
            textTransform="capitalize"
            onClick={() => {
              setSelectedItem(item);
              onChange(item);
            }}
            style={{ transition: 'all .125s ease' }}
            _hover={{ bg: 'gray.700', color: 'gray.200' }}
            sx={
              item?.dial_code === selectedItem?.dial_code
                ? { backgroundColor: 'gray.700', color: 'gray.200' }
                : {}
            }>
            <Text as="span" ml={4}>
              {item?.flag}
            </Text>
            <Text as="span" ml={4}>
              {item?.name} ({item?.dial_code})
            </Text>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
