import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SearchContenfulQueryVariables = Types.Exact<{
  searchTerm: Types.Scalars['String'];
}>;


export type SearchContenfulQuery = { __typename: 'Query', searchContenful: Array<{ __typename: 'SearchResultItem', pageType: string, title: string, description: string, urlSlug: string, image?: { __typename: 'ContenfulAssetEntry', fields?: { __typename: 'ContenfulAssetEntryFields', title?: string | null, file?: { __typename: 'ContenfulAssetEntryFile', url: string } | null } | null } | null }> };


export const SearchContenfulDocument = gql`
    query SearchContenful($searchTerm: String!) {
  searchContenful(searchTerm: $searchTerm) {
    pageType
    title
    description
    urlSlug
    image {
      fields {
        title
        file {
          url
        }
      }
    }
  }
}
    `;

export function useSearchContenfulQuery(options: Omit<Urql.UseQueryArgs<SearchContenfulQueryVariables>, 'query'>) {
  return Urql.useQuery<SearchContenfulQuery>({ query: SearchContenfulDocument, ...options });
};