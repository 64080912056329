import { useToasts } from '@/utils/hooks/useToasts';
import { Container, Flex, Text, List } from '@chakra-ui/react';
import { Toast } from '@meritcircle/ui/components';
import { secondsToMilliseconds } from 'date-fns';
import { motion } from 'framer-motion';

enum AnimationValues {
  duration = 5,
  delay = 2,
}

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: AnimationValues.delay,
    },
  },
  exit: {
    opacity: 0,
  },
};

export const Toasts = () => {
  const { toasts, removeToast } = useToasts();

  return (
    <Container
      display="flex"
      position="sticky"
      top="calc(var(--chakra-space-12) + 40px)"
      zIndex="3">
      <Flex position="relative" flex={1} justifyContent="end">
        <List
          position="absolute"
          top="25px"
          as={motion.li}
          initial="hidden"
          animate="visible"
          exit="exit"
          right="0"
          width={{ base: '100%', md: 'auto' }}
          variants={containerVariants}
          maxWidth={{ base: '17.5rem', md: '20rem' }}
          spacing={1}>
          {toasts.map((toast, index) => (
            <Toast
              key={index}
              duration={(index + 1) * secondsToMilliseconds(AnimationValues.duration)}
              delay={(index + 1) * secondsToMilliseconds(AnimationValues.delay)}
              onClose={() => removeToast(toast.id)}>
              <Text
                fontSize={{ base: '.9rem', lg: '1rem' }}
                dangerouslySetInnerHTML={{ __html: toast.value }}
              />
            </Toast>
          ))}
        </List>
      </Flex>
    </Container>
  );
};
