import {
  Button,
  forwardRef,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useState } from 'react';

/**
 * Password Input
 * This component is not wrapped in an InputGroup on purpose, as that would break support for the 'floating'
 * FormControl variant. Instead, this component expects it to be a descendent of a FormControl, which provides
 * the `position:relative` needed to position the InputRightElement component.
 */
export const PasswordInput = forwardRef<InputProps, 'input'>((props, ref) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup>
      <Input pr="3xl" type={show ? 'text' : 'password'} ref={ref} {...props} />
      <InputRightElement p={0} mr="md" height="100%">
        <Button variant="link" color="gray.500" minWidth={0} onClick={handleClick}>
          {show ? <ViewIcon boxSize={5} /> : <ViewOffIcon boxSize={5} />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
});
