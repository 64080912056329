import { Box, ListItem, useMediaQuery, useTheme } from '@chakra-ui/react';
import { ToastProps } from './toast.types';
import { motion, Variants, AnimatePresence } from 'framer-motion';
import { useState, useRef, useEffect } from 'react';
import { Icon } from '../icon/icon';
import { IconName } from '../icon/icon.types';
import { BlurFlex } from '../blur-flex/blur-flex';
import { rem } from 'polished';

enum AnimationDuration {
  in = 0.4,
  out = 0.2,
}

const secondsToMilliseconds = (number: number): number => number * 1000;

const motionVariants: Variants = {
  initial: {
    opacity: 0,
    scale: 0.85,
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: AnimationDuration.in,
      ease: [0.4, 0, 0.2, 1],
    },
  },
  exit: {
    opacity: 0,
    scale: 0.85,
    transition: {
      duration: AnimationDuration.out,
      ease: [0.4, 0, 1, 1],
    },
  },
};

export const Toast = ({ children, delay, onOpen, onClose, duration = 3000 }: ToastProps) => {
  const [isActive, setIsActive] = useState(false);
  const delayTimeout = useRef<NodeJS.Timeout>();
  const durationTimeout = useRef<NodeJS.Timeout>();
  const { breakpoints } = useTheme();
  const [isSmallerThanMd] = useMediaQuery(`(max-width: ${breakpoints.md})`);

  useEffect(() => {
    clearTimeout(delayTimeout.current);
    delayTimeout.current = setTimeout(() => {
      setIsActive(true);
      onOpen?.();
    }, delay);

    return () => {
      clearTimeout(delayTimeout.current);
    };
  }, [delay, duration]);

  useEffect(() => {
    if (!isActive) return;

    clearTimeout(durationTimeout.current);
    durationTimeout.current = setTimeout(() => {
      setIsActive(false);
    }, duration + secondsToMilliseconds(AnimationDuration.in + AnimationDuration.out));

    return () => {
      clearTimeout(durationTimeout.current);
    };
  }, [isActive]);

  return (
    <AnimatePresence>
      {isActive && (
        <ListItem
          as={motion.li}
          variants={motionVariants}
          initial="initial"
          animate="animate"
          onAnimationComplete={(definition: string) => {
            if (definition === 'exit') {
              onClose?.();
            }
          }}
          exit="exit"
          role="status">
          <BlurFlex py={4}>
            <Box
              bg="lightGrey"
              boxShadow="-1px -3px 2px 0px rgba(255, 255, 255, 0.07) inset, 0px 2px 2px 0px rgba(255, 255, 255, 0.07) inset"
              borderRadius="md"
              w={isSmallerThanMd ? rem(24) : rem(32)}
              h={isSmallerThanMd ? rem(24) : rem(32)}
              overflow="hidden"
              flexShrink={0}>
              <Icon ml="space.4" icon={IconName.beamAppIcon} size={isSmallerThanMd ? 24 : 32} />
            </Box>
            {children}
          </BlurFlex>
        </ListItem>
      )}
    </AnimatePresence>
  );
};
