import { GraphCacheConfig } from '@/client/graphql/types.generated';
import { isBrowser } from '@/utils/isBrowser';
import { dedupExchange, errorExchange, fetchExchange } from '@urql/core';
import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange } from '@urql/exchange-graphcache';
import { NextUrqlPageContext, SSRExchange, withUrqlClient } from 'next-urql';
import { cacheExchangeConfig } from './cache-exchange';
import { getRedirectUrlForError } from './redirect-on-error';

const withoutQueryString = (url?: string) => url?.split('?')[0];

/**
 * Small helper method that server side redirects via a 302 header,
 * and client side redirects via window.location.href.
 */
export const redirectServerSideOrClientSide = (to: string, ctx: NextUrqlPageContext) => {
  // If we currently are on the url we are trying to redirect to, don't redirect again,
  // since this would cause an infinite loop.
  if (
    withoutQueryString(ctx?.req?.url) === to ||
    (isBrowser && withoutQueryString(window.location.pathname) === to)
  )
    return;

  if (ctx?.res) {
    ctx.res.setHeader('location', to);
    ctx.res.statusCode = 302;
    ctx.res.end();
    return;
  }

  window.location.assign(to);
  return;
};

export const withUrql = withUrqlClient(
  (ssrExchange: SSRExchange, ctx: NextUrqlPageContext) => {
    return {
      // @TODO: Default this to production in case of missing variable, once we have this url?
      url: `${process.env.NEXT_PUBLIC_BACKEND_BASE}/graphql`,
      fetchOptions: {
        // Makes sure on client side the cookie is forwarded to the graphql endpoint.
        credentials: 'include',
        headers: {
          // Makes sure server side the cookie is forwarded to the graphql endpoint.
          Cookie: ctx?.req?.headers.cookie || '',
        },
      },
      exchanges: [
        devtoolsExchange,
        dedupExchange,
        cacheExchange<GraphCacheConfig>(cacheExchangeConfig),
        errorExchange({
          onError: errors => {
            const redirectUrl = getRedirectUrlForError(errors, ctx);

            // If a redirect url exists for this error, handle redirect.
            if (redirectUrl) {
              redirectServerSideOrClientSide(redirectUrl, ctx);
              return;
            }

            if (ctx?.req) {
              console.log('::: Graphql error:');
              console.error(errors);
            }
          },
        }),
        ssrExchange,
        fetchExchange,
      ],
    };
  },
  {
    ssr: false,
  },
);
