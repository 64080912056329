import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      ref={svgRef}
      {...props}>
      <rect width={18} height={18} x={3} y={3} fill="url(#paint0_linear_16119_54163)" rx={1} />
      <path
        fill="#710036"
        fillRule="evenodd"
        d="M18 7.333l.939.946-8.06 8a.667.667 0 01-.877.055l-.063-.055-4.606-4.571.94-.946 4.135 4.105L18 7.333z"
        clipRule="evenodd"
      />
      <rect
        width={23}
        height={23}
        x={0.5}
        y={0.5}
        stroke="url(#paint1_linear_16119_54163)"
        rx={3.5}
      />
      <defs>
        <linearGradient
          id="paint0_linear_16119_54163"
          x1={8.18}
          x2={18.94}
          y1={3.49}
          y2={5.87}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9BFFB" />
          <stop offset={1} stopColor="#E8BBFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16119_54163"
          x1={6.9}
          x2={21.25}
          y1={0.66}
          y2={3.83}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9BFFB" />
          <stop offset={1} stopColor="#E8BBFB" />
        </linearGradient>
      </defs>
    </svg>
  );
});
const CheckboxInputCheckedIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default CheckboxInputCheckedIcon;
