import { ButtonProps, chakra } from '@chakra-ui/react';
import { Icon } from '@meritcircle/ui/components/icon/icon';
import { IconName } from '@meritcircle/ui/components/icon/icon.types';
import { useEffect, useState } from 'react';
import { useSwiper } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

export const CarouselButtons = ({
  loop,
  swiper,
  leftButtonProps,
  rightButtonProps,
}: {
  loop?: boolean;
  swiper: SwiperClass;
  leftButtonProps?: ButtonProps;
  rightButtonProps?: ButtonProps;
}) => {
  const [[isBeginning, isEnd], setButtonState] = useState([!loop, false]);

  useEffect(() => {
    const updateState = () => {
      setButtonState([swiper.isBeginning, swiper.isEnd]);
    };

    if (!loop) {
      swiper.on('progress', updateState);
      updateState();

      return () => {
        swiper.off('progress', updateState);
      };
    }
  }, [swiper, loop]);

  if (isBeginning && isEnd) {
    return null;
  }

  return (
    <>
      <chakra.button
        as="button"
        display="flex"
        onClick={() => {
          swiper.slidePrev();
        }}
        position="absolute"
        alignItems="center"
        justifyContent="center"
        left={0}
        top="50%"
        disabled={!!isBeginning}
        transform="translateY(-50%)"
        aria-label="previous"
        width={{ base: '44px', md: '80px' }}
        height={{ base: '66px', md: '120px' }}
        zIndex={1}
        _before={{
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          bg: 'black',
          position: 'absolute',
          top: 0,
          opacity: 0.6,
          zIndex: -1,
        }}
        _disabled={{
          display: 'none',
        }}
        {...leftButtonProps}>
        <Icon icon={IconName.chevronLeft} />
      </chakra.button>
      <chakra.button
        as="button"
        display="flex"
        onClick={() => {
          swiper.slideNext();
        }}
        disabled={!!isEnd}
        position="absolute"
        alignItems="center"
        justifyContent="center"
        right={0}
        top="50%"
        transform="translateY(-50%)"
        aria-label="next"
        width={{ base: '44px', md: '80px' }}
        height={{ base: '66px', md: '120px' }}
        zIndex={1}
        _before={{
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          bg: 'black',
          position: 'absolute',
          top: 0,
          opacity: 0.6,
          zIndex: -1,
        }}
        _disabled={{
          display: 'none',
        }}
        {...rightButtonProps}>
        <Icon icon={IconName.chevronRight} />
      </chakra.button>
    </>
  );
};
