import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={8}
      fill="none"
      viewBox="0 0 13 8"
      ref={svgRef}
      {...props}>
      <rect
        width={9}
        height={2}
        x={6.36}
        y={7.78}
        fill="currentColor"
        rx={1}
        transform="rotate(-135 6.363 7.778)"
      />
      <rect
        width={9}
        height={2}
        x={5}
        y={6.36}
        fill="currentColor"
        rx={1}
        transform="rotate(-45 5 6.364)"
      />
    </svg>
  );
});
const ArrowDownIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default ArrowDownIcon;
