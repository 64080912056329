import { ComponentStyleConfig } from '@chakra-ui/react';
import { colors } from '../colors';

// Base styles for the label and error message which vertically centers them in the input
// and prepares for animation
const labelAndFeedbackStyles = {
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: `translateY(-50%)`,
  zIndex: 2,
  fontSize: 'sm',
  lineHeight: 'normal',
  backgroundColor: 'transparent',
  pointerEvents: 'none',
  transition: 'all .2s ease',
  m: 0,
  px: 4, // Equal to the regular input padding
  py: 0,
};

// When the input is active (focused e.d.), the given elements animate up
const activeLabelAndFeedbackStyles = {
  transform: 'translate3d(0, -1.5rem, 0)',
  fontSize: 'xs',
};

export const form: ComponentStyleConfig = {
  variants: {
    /**
     * Floating Label Variant
     * Please note that this variant depends on a specific ordering of child components e.g. the <FormLabel> and <FormErrorMessage>
     * should come after the <Input>. It is also important to use an empty space for the Input placeholder, e.g. 'placeholder=" "'.
     * This variant only supports the default Input size (md). For other sizes, support will be added when the need arises
     *
     * @example
     * <FormControl variant="floating" id="discordId" isRequired>
     *   <Input placeholder=" " />
     *   <FormLabel>Some label</FormLabel>
     *   <FormErrorMessage>Some error message</FormErrorMessage>
     * </FormControl>
     */
    floating: props => ({
      container: {
        bg: 'gray.800',
        borderRadius: 'lg',
        borderColor: 'transparent',

        _focusWithin: {
          label: {
            ...activeLabelAndFeedbackStyles,
          },

          '.chakra-form__error-message': {
            ...activeLabelAndFeedbackStyles,
          },
        },

        'input:not(:placeholder-shown) ~ label, .chakra-select__wrapper ~ label': {
          ...activeLabelAndFeedbackStyles,
        },

        // In case the form control value is invalid the label should be hidden as it is replaced with the error message
        'label[data-invalid]': {
          display: 'none',
        },

        // Apply the same acitve styles as the label in case the input is invalid
        'label[data-invalid] ~ .chakra-form__error-message': {
          ...activeLabelAndFeedbackStyles,
        },

        // Base layout for inputs and selects, specific styles are added below
        'input, select, textarea': {
          fontSize: 'md',
          backgroundColor: 'mediumGrey',
          color: 'white',
          boxShadow: 'none',
          border: '1px solid transparent',

          _hover: {
            bg: 'mediumGrey',
            borderColor: 'transparent',
            backgroundOrigin: 'border-box',
          },

          _focus: {
            bg: 'mediumGrey',
            boxShadow: 'none',
            borderColor: 'lightPurple',
            border: '1px solid transparent',
            backgroundImage: `linear-gradient(${colors.mediumGrey}, ${colors.mediumGrey}), linear-gradient(125deg, rgba(232, 187, 251) 0%, rgba(232, 187, 251) 100%)`,
            backgroundOrigin: 'border-box',
            backgroundClip: 'padding-box, border-box',
            outline: 'none',
          },

          _invalid: {
            backgroundImage: `linear-gradient(${colors.mediumGrey}, ${colors.mediumGrey})`,
            borderColor: 'transparent',
            boxShadow: `0 0 0 1px ${props.theme.colors.red[500]}`,
          },
        },

        // The input has more top than bottom padding as the actual input value is not vertically centered in the design
        input: {
          pt: 12,
          pb: 6,

          // Along with the rules below ensures autocompleted input values to be rendered
          // as white instead of black, and not have a blue-ish background color
          colorScheme: 'dark',
          ':-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus': {
            boxShadow: `inset 0 0 0px 1000px ${props.theme.colors.gray[800]}`,
            WebkitBackgroundClip: 'content-box',
            WebkitTextFillColor: 'white',
            color: 'white',
          },
        },

        // The height of a select cannot be altered with padding alone, as we did at the input above. It needs a specific height,
        // and the lineHeight needs to be larger to offset the selected option
        select: {
          height: '4.625rem',
          lineHeight: '6rem',
        },

        label: {
          ...labelAndFeedbackStyles,
          color: 'gray.500',
        },

        '.chakra-form__error-message': {
          ...labelAndFeedbackStyles,
          fontWeight: 'bold',
        },
      },
    }),
    review: props => ({
      container: {
        textarea: {
          background: 'mediumGrey',
          px: 'space.28',
          pt: 'space.36',
          pb: 'space.28',
          border: '1px solid transparent',

          _hover: {
            bg: 'mediumGrey',
            borderColor: 'transparent',
            backgroundOrigin: 'border-box',
          },

          _focus: {
            bg: 'mediumGrey',
            boxShadow: 'none',
            borderColor: 'lightPurple',
            border: '1px solid transparent',
            backgroundImage: `linear-gradient(${colors.mediumGrey}, ${colors.mediumGrey}), linear-gradient(125deg, rgba(232, 187, 251) 0%, rgba(232, 187, 251) 100%)`,
            backgroundOrigin: 'border-box',
            backgroundClip: 'padding-box, border-box',
            outline: 'none',
          },

          _invalid: {
            backgroundImage: `linear-gradient(${colors.mediumGrey}, ${colors.mediumGrey})`,
            borderColor: 'transparent',
            boxShadow: `0 0 0 1px ${props.theme.colors.red[500]}`,
          },
        },
        label: {
          color: 'gray.500',
          position: 'absolute',
          left: 'space.28',
          top: 'space.12',
          zIndex: 2,
          fontSize: 'sm',
          lineHeight: 'normal',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
        },

        // In case the form control value is invalid the label should be hidden as it is replaced with the error message
        'label[data-invalid]': {
          display: 'none',
        },

        // Apply the same acitve styles as the label in case the input is invalid
        'label[data-invalid] ~ .chakra-form__error-message': {
          color: 'red.500',
          position: 'absolute',
          left: 'space.28',
          top: 'space.12',
          mt: '0',
          zIndex: 2,
          fontSize: 'sm',
          lineHeight: 'normal',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
        },
      },
    }),
  },
};
