import { ComponentStyleConfig, flexbox } from '@chakra-ui/react';

export const checkbox: ComponentStyleConfig = {
  baseStyle: {
    control: {
      alignSelf: 'self-start',
      marginTop: 1,
      _checked: {
        bg: 'lightPurple',
        borderColor: 'lightPurple',
      },
    },
  },
};
