import { Box, Collapse } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useSearchContenfulQuery } from '@/client/graphql/search.generated';
import { SearchBarInput } from './search-bar-input';
import { SearchBarDropdown } from './search-bar-dropdown';
import { transformSearchResults } from '@/components/features/search/search.helpers';
import {
  DEBOUNCE_SEARCH_MILLISECONDS,
  MIN_CHAR_TO_START_SEARCH,
  SearchBarProps,
} from './search-bar.types';

export const SearchBar = ({ isOpen, onClose }: SearchBarProps) => {
  const timeout = useRef(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [searchTerm, setSearchTerm] = useState('');
  // used to delay search until user stops typing
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      timeout.current = null;
    }, DEBOUNCE_SEARCH_MILLISECONDS);
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [searchTerm]);

  const [{ data, fetching, error }] = useSearchContenfulQuery({
    variables: { searchTerm: debouncedSearchTerm },
    pause: !debouncedSearchTerm || searchTerm.length < MIN_CHAR_TO_START_SEARCH,
  });

  const resetSearch = () => setSearchTerm('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const onCloseHandler = () => {
    setSearchTerm('');
    onClose();
  };

  const searchResults = data?.searchContenful ? transformSearchResults(data?.searchContenful) : [];

  return (
    <Collapse
      in={isOpen}
      style={{ width: '100%', gridArea: '1/1', overflow: 'visible' }}
      animateOpacity={true}
      startingHeight={0}
      onAnimationStart={() => setSearchTerm('')}
      onAnimationComplete={() => {
        if (isOpen) {
          // if focus in not delayed, it causes scroll to jump few px
          setTimeout(() => {
            searchInputRef.current.focus();
          }, 100);
        }
      }}>
      <Box position="absolute" top="0" bottom="0" left="0" right="0" bg="black" />
      <SearchBarInput
        searchTerm={searchTerm}
        handleInputChange={handleInputChange}
        resetSearch={resetSearch}
        isBarOpen={isOpen}
        onBarClose={onCloseHandler}
        ref={searchInputRef}
      />
      {isOpen && (
        <SearchBarDropdown
          searchTerm={debouncedSearchTerm}
          searchResults={searchResults}
          isFetching={fetching}
          onClose={onCloseHandler}
          hasError={!!error}
        />
      )}
    </Collapse>
  );
};
