import { chakra, Flex } from '@chakra-ui/react';
import { rem } from 'polished';
import RadioInputIcon from '../../../icons/generated/RadioInput';
import RadioInputCheckedIcon from '../../../icons/generated/RadioInputChecked';
import { RadioProps } from './radio-input.types';

export const Radio = ({ isChecked, label, value, name, onChange }: RadioProps) => {
  return (
    <Flex gap="space.24">
      <chakra.label
        cursor="pointer"
        display="flex"
        gap="space.8"
        alignItems="center"
        w="100%"
        fontSize={rem(12)}
        fontWeight={400}
        color="desiredDawn"
        transition="0.2s ease-in"
        _hover={{
          color: 'white',
        }}
        data-testid={`${label}-radio-button`}>
        <chakra.input
          type="checkbox"
          clipPath="inset(50%)"
          height="1px"
          width="1px"
          overflow="hidden"
          whiteSpace="nowrap"
          position="absolute"
          sx={{
            clip: 'rect(0 0 0 0)',
          }}
          name={name}
          value={value}
          onChange={e => onChange && onChange(e.currentTarget.value)}
        />
        {isChecked ? <RadioInputCheckedIcon /> : <RadioInputIcon />}
        <chakra.span mt='1px'>{label}</chakra.span>
      </chakra.label>
    </Flex>
  );
};
