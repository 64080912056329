import { CurrentUserDocument } from '@/client/graphql/users.generated';
import {
  UserScholarshipDocument,
  UserScholarshipsDocument,
} from '@/client/graphql/scholarships.generated';
import { GraphCacheConfig, ScholarshipStatus } from '@/client/graphql/types.generated';
import {
  GetReviewsDocument,
  GetReviewsQuery,
  GetReviewsSummaryDocument,
  GetReviewsSummaryQuery,
} from '@/client/graphql/reviews.generated';

export const updates: GraphCacheConfig['updates'] = {
  Mutation: {
    // When the user applies for a scholarship, refetch both the user's scholarship and the list of scholarships. Please note that
    // for updating cached queries, the variables must match those used in the component that implemented the query.
    createScholarship: (result, _, cache) => {
      try {
        // Updating the user's scholarship query for the specific game results in the 'Select scholarship' to not be shown anymore
        cache.updateQuery(
          {
            query: UserScholarshipDocument,
            variables: {
              gameId: result.createScholarship.game.id,
              status: [ScholarshipStatus.Applied],
            },
          },
          data => data,
        );

        cache.updateQuery(
          {
            query: UserScholarshipsDocument,
            variables: {
              filter: {
                status: [ScholarshipStatus.Applied],
              },
            },
          },
          data => {
            // The created scholarship is directly added at the beginning of the cached list of scholarships
            if (Array.isArray(data.userScholarships)) {
              data.userScholarships = [result.createScholarship, ...data.userScholarships];
            }

            return data;
          },
        );

        // Also update the currentUser query as creating a scholarship can potentially update the users profile
        cache.updateQuery(
          {
            query: CurrentUserDocument,
          },
          data => {
            data.currentUser = Object.assign({}, data.currentUser, result.createScholarship.user);
            return data;
          },
        );
      } catch {
        // Fail silently if this doesn't succeed. User will have seen the success toast
      }
    },
    createReview: (result, args, cache) => {
      try {
        cache.updateQuery(
          {
            query: GetReviewsSummaryDocument,
            variables: {
              gameSlug: args.createReviewInput.gameSlug,
            },
          },
          data => {
            // Calculate new average and number of ratings after review is added
            const query = data as GetReviewsSummaryQuery;
            const { count, averageRating, summary } = query.getReviewsSummary;

            const ratingCountToUpdate = summary.find(
              item => item.rating === result.createReview.rating,
            );

            const updatedSummary = ratingCountToUpdate
              ? summary.map(item =>
                  item.rating === result.createReview.rating
                    ? { ...item, count: item.count + 1 }
                    : item,
                )
              : [
                  ...summary,
                  { rating: result.createReview.rating, count: 1, __typename: 'ReviewSummary' },
                ];

            return {
              ...data,
              getReviewsSummary: {
                ...query.getReviewsSummary,
                averageRating: (count * averageRating + result.createReview.rating) / (count + 1),
                count: count + 1,
                summary: updatedSummary,
                userReviewed: true,
              },
            };
          },
        );
        // after creating review update highlighted reviews
        cache.updateQuery(
          {
            query: GetReviewsDocument,
            variables: {
              getReviewsInput: {
                gameSlug: args.createReviewInput.gameSlug,
                sort: {
                  voteDiff: 'ASC',
                },
                take: 3,
              },
            },
          },
          data => {
            const query = data as GetReviewsQuery;
            const { reviews, count } = query.getReviews;

            const updatedReviews =
              query.getReviews.reviews?.length > 2 ? reviews : [...reviews, result.createReview];

            return {
              ...data,
              count: count + 1,
              getReviews: {
                ...query.getReviews,
                reviews: updatedReviews,
              },
            };
          },
        );
      } catch {
        // Fail silently if this doesn't succeed
      }
    },
    deleteReview: (result, args, cache) => {
      cache.updateQuery(
        {
          query: GetReviewsSummaryDocument,
          variables: {
            gameSlug: args.gameSlug,
          },
        },
        data => {
          // Calculate new average and number of ratings after review is added
          const query = data as GetReviewsSummaryQuery;
          const { count, averageRating, summary } = query.getReviewsSummary;

          const updatedSummary = summary.map(item =>
            item.rating === result.deleteReview.rating ? { ...item, count: item.count - 1 } : item,
          );

          const updatedCount = count - 1;

          return {
            ...data,
            getReviewsSummary: {
              ...query.getReviewsSummary,
              averageRating:
                updatedCount && (count * averageRating - result.deleteReview.rating) / updatedCount,
              count: count - 1,
              summary: updatedSummary,
              userReviewed: false,
            },
          };
        },
      );
    },
  },
};
