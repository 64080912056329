import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      ref={svgRef}
      {...props}>
      <path
        fill="currentColor"
        d="M6.076 2.25L16.87 8.012 13.577 11.3 4.38 2.126a2.068 2.068 0 011.696.123zm11.711 6.253l3.11 1.66c1.47.785 1.47 2.889 0 3.674l-3.11 1.66L14.28 12l3.507-3.497zM3.538 2.69C3.208 3.05 3 3.532 3 4.085v15.827c0 .553.208 1.036.538 1.398L12.874 12 3.538 2.69zm2.538 19.06c-.561.3-1.17.315-1.696.124l9.197-9.172 3.294 3.284L6.076 21.75z"
      />
    </svg>
  );
});
const GooglePlayIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default GooglePlayIcon;
