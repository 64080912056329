import React from 'react';
import { List, ListItem } from '@chakra-ui/react';
import { LinkBoxListProps } from './link-box-list.types';

export const LinkBoxList = ({ children, ...props }: LinkBoxListProps) => (
  <List {...props} spacing={0.5} sx={{ ':hover > li': { opacity: 0.4 } }}>
    {React.Children.map(children, child => (
      <ListItem transition="opacity 0.2s ease" sx={{ '&&:hover': { opacity: 1 } }}>
        {child}
      </ListItem>
    ))}
  </List>
);
