import { chakra } from '@chakra-ui/react';
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
const SVGIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
  if (size) {
    props.width = size;
    props.height = size;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      viewBox="0 0 40 40"
      ref={svgRef}
      {...props}>
      <path
        fill="url(#a)"
        fillRule="evenodd"
        d="M28 31.748c6.56 0 11.892-5.15 11.998-11.543l.002-.194c0 10.803-8.954 19.56-20 19.56S0 30.815 0 20.012h16c0 6.482 5.372 11.737 12 11.737z"
        clipRule="evenodd"
      />
      <path
        fill="url(#b)"
        fillRule="evenodd"
        d="M12 31.747c-6.56 0-11.892-5.15-11.998-11.542L0 20.011c0 10.803 8.955 19.56 20 19.56 11.046 0 20-8.757 20-19.56H24c0 6.482-5.372 11.736-12 11.736z"
        clipRule="evenodd"
      />
      <path
        fill="url(#c)"
        fillRule="evenodd"
        d="M12 8.275C5.44 8.275.108 13.425.002 19.817L0 20.011C0 9.208 8.955.451 20 .451c11.046 0 20 8.757 20 19.56H24c0-6.482-5.372-11.736-12-11.736z"
        clipRule="evenodd"
      />
      <path
        fill="url(#d)"
        fillRule="evenodd"
        d="M28 8.275c6.56 0 11.892 5.15 11.998 11.542l.002.194C40 9.208 31.046.451 20 .451S0 9.208 0 20.011h16c0-6.481 5.372-11.736 12-11.736z"
        clipRule="evenodd"
      />
      <path fill="#EF9146" d="M15.8 19.815H.2v.391h15.6v-.39z" />
      <path fill="#E57622" d="M39.8 19.815H24.2v.391h15.6v-.39z" />
    </svg>
  );
});
const MeritCircleIconIcon = chakra(SVGIcon, {
  shouldForwardProp: prop => ['size', 'width', 'height', 'viewBox'].includes(prop),
});
export default MeritCircleIconIcon;
