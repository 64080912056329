import { addLeadingZero } from '@meritcircle/ui/utils';

export interface CountdownDiff {
  milliseconds: number;
  seconds: string;
  minutes: string;
  hours: string;
  days: string;
}

/** Formats countdown to object with days, hours, minutes, seconds
 * @param milliseconds
 * @returns CountdownDiff
 */

export const formatCountdown = (milliseconds: number): CountdownDiff => {
  const seconds = addLeadingZero(Math.max(Math.floor(milliseconds / 1000) % 60, 0));
  const minutes = addLeadingZero(Math.max(Math.floor(milliseconds / (1000 * 60)) % 60, 0));
  const hours = addLeadingZero(Math.max(Math.floor(milliseconds / (1000 * 60 * 60)) % 24, 0));
  const days = addLeadingZero(Math.max(Math.floor(milliseconds / (1000 * 60 * 60 * 24)), 0));

  return { milliseconds, seconds, minutes, hours, days };
};

export const convertToTimeZone = (date: string | Date, timeZone: string): Date => {
  return new Date(
    new Date(date).toLocaleString('en-US', {
      timeZone,
    }),
  );
};
