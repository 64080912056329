import { debounce } from './../debounce';
import { useEffect } from 'react';

/**
 * Sets the global CSS variable '--vh' that is exactly equal to 1% of the viewport height. Used in conjunction
 * with components where '100vh' is not usable in mobile browsers. Instead of `100vh`, use `calc(100 * var(--vh))`
 * for proper support of mobile browsers.
 */
export function useViewportHeightVar() {
  useEffect(() => {
    function setter() {
      (document.querySelector(':root') as HTMLElement).style.setProperty(
        '--vh',
        window.innerHeight / 100 + 'px',
      );
    }

    const debouncedSetter = debounce(setter, 300);

    window.addEventListener('resize', debouncedSetter);
    setter();

    return () => window.removeEventListener('resize', debouncedSetter);
  }, []);
}
