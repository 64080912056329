import { ComponentStyleConfig, space } from '@chakra-ui/react';

export const badge: ComponentStyleConfig = {
  baseStyle: {
    fontSize: ['0.625rem', null, '0.875rem'],
    fontWeight: '400',
    paddingBlock: 'space.6',
    paddingInline: 'space.8',
    lineHeight: 1,
    borderRadius: 4,
    textTransform: 'none',
    color: 'white',
    whiteSpace: 'normal',
  },
  defaultProps: {
    variant: 'default',
  },
  sizes: {
    xs: {
      fontSize: '0.625rem',
    },
  },
  variants: {
    default: {
      bg: 'rgba(208, 208, 208, 0.10)',
    },
    accentBlue: {
      bgColor: 'accentBlue',
      color: 'black',
    },
    arcticBlue: {
      bgColor: 'arcticBlue',
      color: 'black',
    },
    lightPurple: {
      bgColor: 'lightPurple',
      color: 'black',
    },
  },
};
