import { ComponentStyleConfig } from '@chakra-ui/react';

const baseFontStyle = {
  textTransform: 'none',
  fontWeight: 'normal',
  fontSize: { base: 'sm', md: 'md' },
  letterSpacing: '-0.01em',
  p: {
    whiteSpace: 'normal',
  }
}

export const table: ComponentStyleConfig = {
  variants: {
    simple: {
      table: {
        tableLayout: 'fixed',
      },
      th: {
        ...baseFontStyle,
        width: { base: '14rem', lg: 'auto' },
        backgroundColor: 'gray.800',
        color: 'gray.400',
        borderBottom: 'none',
        px: 'md',
        py: { base: 'sm', md: "md" },
        ':first-of-type': {
          borderRadius: '8px 0 0 8px'
        },
        ':last-of-type': {
          borderRadius: '0 8px 8px 0'
        }
      },
      td: {
        ...baseFontStyle,
        px: 'md',
        py: 'xl',
        color: 'white',
        borderBottomColor: 'gray.600'
      }
    },
  },
};

